import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { UniversitylistService } from '../../services/universitylist.service'
import { UniversityList } from '../../interfaces/universitylist'
import { MessageService } from 'src/app/core/services/message.service';
@Component({
  selector: 'app-university-list',
  templateUrl: './university-list.component.html',
  styleUrls: ['./university-list.component.scss']
})
export class UniversityListComponent implements OnInit {
  country: number;
  data: Object;
  currentPage: string;
  univertyList: UniversityList[] = [];
  constructor(private messageService: MessageService, private router: Router, private route: ActivatedRoute, private universitylistService: UniversitylistService) {
    this.route.params.subscribe((params: Params) => {
      console.log(params.country);
      this.country = params.country
    });
    this.currentPage = this.router.url.split('/')[1]

  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.messageService.sendMessage({ country: params.country.toLowerCase() })
    })
    this.getUniversityList()
  }
  getUniversityList() {
    if (this.currentPage == 'top-rank-university') {
      this.data = {
        cat: 'top',
        country: this.country
      }
    }
    else if (this.currentPage == 'economical-university') {
      this.data = {
        cat: 'economical',
        country: this.country
      }
    }
    else if (this.currentPage == 'friendly-university') {
      this.data = {
        cat: 'friendly',
        country: this.country
      }
    }

    this.universitylistService
      .getUniversityList(this.data)
      .subscribe(async (data: any) => {
        this.univertyList = data
      });
  }
  navigateToUniversityDetail(item: any) {
    this.router.navigateByUrl(`/university/` + item.code + `/` + item.name)
  }
  ngOnDestroy() {
    localStorage.removeItem('faqCountry');
  }
}
