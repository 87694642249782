<div class="nav-container nav-container--sidebar">
    <div class="nav-sidebar-column bg--light">
        <div class="text-center text-block">
            <a >
                <img class="logo-light" alt="logo" src="assets/img/logo-dark.webp" />
            </a>
        </div>
        <hr>
        <div class="text-block">
            <ul class="menu-vertical">
                <li class="nav">
                    <a routerLink='preferences' href="#">
                        Preferences
                    </a>
                </li>
                <li class="nav">
                    <a routerLink='profile' href="#">
                        Profile
                    </a>
                </li>
                <li class="nav">
                    <a routerLink='education-detail'>
                        Education Details
                    </a>
                </li>
                <li class="nav">
                    <a   routerLink='upload-doc'>
                        Doc Upload
                    </a>
                </li>
                <li class="nav">
                    <a routerLink='shortlisted-university'>
                        Shortlisted Universities
                    </a>
                </li>
                
                
            </ul>
        </div>
       <!--- <div class="text-block">
            <a class="btn block type--uppercase" href="#">
                <span class="btn__text">Try Builder</span>
            </a>
            <a class="btn block btn--primary type--uppercase" href="#">
                <span class="btn__text">Buy Now</span>
            </a>
        </div>-->
        
        <div class="footermenu">
            <hr>
            <ul class="social-list list-inline list--hover">
                <li>
                    <a href="#">
                        <i class="socicon socicon-google icon icon--xs"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="socicon socicon-twitter icon icon--xs"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="socicon socicon-facebook icon icon--xs"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="socicon socicon-instagram icon icon--xs"></i>
                    </a>
                </li>
            </ul>
            <div>
                <span class="type--fine-print type--fade">
                    &copy; Copyright
                    <span class="update-year"></span> Meet University
                </span>
            </div>
        </div>
    </div>
    <div class="nav-sidebar-column-toggle visible-xs visible-sm" data-toggle-class=".nav-sidebar-column;active">
        <i class="stack-menu"></i>
    </div>
</div>