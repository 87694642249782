<section class="faqsection">
    <div class="container">
        <h2>Frequently Asked Questions</h2>
        <div class="row">
            <div class="col-12" *ngFor="let faq of faqDatas; let i=index ">
                <button class="accordions">{{faq.slug}}</button>
                <div class="panel">
                    <p class="lead">
                        {{faq.body}}
                    </p>
                </div>
                <!-- <div class="text-block" >
                    <h5>{{faq.slug}}</h5>
                    <p>
                        {{faq.body}}
                    </p>
                </div> -->
            </div>
        </div>
        <!--end of row-->
    </div>
    <!--end of container-->
</section>