import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services/api.service';
import { apiUrl } from '../../../config/config';

@Injectable({
  providedIn: 'root',
})
export class DisciplinesService {
  constructor(private ApiService: ApiService) {}
  DisciplineUrl = apiUrl.get_disciplines;

  getDiscipline() {
    return this.ApiService.get(this.DisciplineUrl);
  }
}
