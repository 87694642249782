import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CourseListService {

  data: any;

  constructor(private http: HttpClient) { }

  getData(country: string): Observable<any> {
    const apiUrl = `https://search.meetuniversity.com:8000/v1/search/new?q=MBA&country=${country}&level=1&ielts=4.0&toefl=60`;
    return this.http.get<any>(apiUrl);
  }

  fetchData(country: string): void {
    this.getData(country).subscribe(response => {
      this.data = response;
    });
  }

}