<section class="cover imagebg height-60 text-center" data-overlay="3">
    <div class="background-image-holder" style="background: url(&quot;assets/img/avatar-group.jpg&quot;); opacity: 1;">
        <img height="auto" width="auto" alt="image" src="assets/img/avatar-group.jpg">
    </div>
    <div class="container pos-vertical-center">
        <div class="row">
            <div class="col-md-10 col-lg-8">
                <h1>Course Knowledgebase</h1>
                <p class="lead">
                    1.5 million courses outlined from 3500 institutions worldwide
                </p>
                <div class="boxed boxed--lg bg--white text-left">
                    <form  action="/search" class="form--horizontal row m-0" >
                        <div class="col-md-8">
                            <input type="text" name="q" placeholder="Type search keywords here" autocomplete="off" class="">
                        </div>
                        <div class="col-md-4">
                            <button type="submit" class="btn btn--primary type--uppercase">Search</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!--end of row-->
    </div>
    <!--end of container-->
</section>


<section class="text-center space--xs">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2>Browse Courses by Disciplines</h2>
            </div>
        </div>
        <!--end of row-->
    </div>
    <!--end of container-->
</section>


<section>
    <div class="container">
        <div class="row">
                <div class="col-md-3" *ngFor="let list of DisciplineList">
                    <a rel="noopener" href="javascript:void(0)" class="block" (click)="reachedInnerPage(list.name)">
                        <div class="feature boxed boxed--border border--round text-center">
                            <i class="icon--lg" [class]="list.icon"></i>
                            <span class="h5 color--primary"> {{list.name}} </span>
                        </div>
                    </a>
                </div>
        </div>
        <!--end of row-->
    </div>
    <!--end of container-->
</section>
