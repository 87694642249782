import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { apiUrl } from '../../../config/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GuideService {
  
  PostFormDataUrl = apiUrl.validate_data;
  constructor(private ApiService: ApiService) { }
  postFormData(body: any): Observable<any> {
    return this.ApiService.create(body, this.PostFormDataUrl);
  }
}
