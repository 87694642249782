<section class="imagebg height-100 pt-4" data-overlay="2">
  <div class="background-image-holder" style="background: url(&quot;assets/img/application.jpg&quot;); opacity: 1;">
    <img alt="background" src="assets/img/application.jpg">
  </div>
  <div class="container backgroundtestimonial p-5">
    <div class="row">
      <div class="col-md-6 align-self-center">
        <div class="heading-block">
          <h1 class="text-center">FREE Student Guide for <span class="type--uppercase">{{countryName}}</span></h1>
          <p class="text-center lead">
            Fill the form to get the Guide download Link!
          </p>
        </div>
        <h3>Here's What You'll Learn</h3>
        <ul>
          <li>
            <p>
              <strong class="text-white">World wide Recognition:</strong>
              Degrees and qualifications in the UK are known around the world
              as high quality and world class.
            </p>
          </li>
          <li>
            <p>
              <strong class="text-white"> Education Costs are Lower:</strong>
              The cost of education for international students are lower
              compared to the USA and other countries
            </p>
          </li>
          <li>
            <p>
              <strong class="text-white">Work Opportunities:</strong>
              Student are typically allowed to work up to 20 hours a week
              during school term, and up to full-time when school is out of
              term.
            </p>
          </li>
          <li>
            <p>
              <strong class="text-white">Scholarships/ Loans:</strong>
              A variety of scholarships and loans available.
            </p>
          </li>
          <li>
            <p>
              <strong class="text-white">Gateway to Europe:</strong>
              With the addition of the Channel Tunnel and low cost airlines
              such as EasyJet and Ryan Air, Europe is easier to access than
              ever.
            </p>
          </li>
        </ul>
      </div>
      <div class="col-md-6">
        <div class="boxed boxed--lg bg--white boxed--border boxed-form">
          <p class="lead text-center">
            Connect with Universities & Study Abroad Experts Instantly !
          </p>
          <form   [formGroup]="Form" (ngSubmit)="onSubmit()" class="form-email row">
            <div class="col-md-12 col-12">
              <input [ngClass]="{
                'is-invalid field-error':
                submitted && f.full_name.errors
              }"  formControlName="full_name" type="text" placeholder="Your Name" name="name" />
              <!-- <div *ngIf="submitted && f.full_name.errors" class="invalid-feedback">
                <div *ngIf="f.full_name.errors.required">
                    Fullname is required.
                </div> -->
                <div *ngIf="submitted && f.full_name.errors" class="invalid-feedback">
                  <div *ngIf="f.full_name.errors.required">Fullname is required.</div>
              </div>
            <!-- </div> -->
            </div>
            <div class="col-md-12 col-12">
              <input [ngClass]="{
                'is-invalid field-error':
                submitted && f.email_address.errors
              }"  formControlName="email_address" type="email" name="email" placeholder="Email Address"  />
              <div *ngIf="submitted && f.email_address.errors" class="invalid-feedback">
                <div *ngIf="f.email_address.errors.required">Email is required.</div>
            </div>
            </div>

            <div class="col-md-6">
              <input [ngClass]="{
                'is-invalid field-error':
                submitted && f.phone_number.errors
              }"  formControlName="phone_number" placeholder="Phone Number" type="number" name="email"/>
              <div *ngIf="submitted && f.phone_number.errors" class="invalid-feedback">
                <div *ngIf="f.phone_number.errors.required">Phone Number is required.</div>
            </div>
            </div>
            <div class="col-md-6">
              <input [ngClass]="{
                'is-invalid field-error':
                submitted && f.last_course.errors
              }"  formControlName="last_course"   type="text" placeholder="Last Course" name="email" />
              <div *ngIf="submitted && f.last_course.errors" class="invalid-feedback">
                <div *ngIf="f.last_course.errors.required">Last Course is required.</div>
            </div>
            </div>
            <div class="col-md-12 col-12">
              <input [ngClass]="{
                'is-invalid field-error':
                submitted && f.last_score.errors
              }"  formControlName="last_score"  type="email" placeholder="Last Percentage" name="email"/>
          <div *ngIf="submitted && f.last_score.errors" class="invalid-feedback">
                <div *ngIf="f.last_score.errors.required">Last Percentage is required.</div>
            </div>
            </div>
            <div class="col-md-12">
              <div class="input-select">
                <select [ngClass]="{
                  'is-invalid field-error':
                  submitted && f.course.errors
                }"   formControlName="course"  name="course" id="course">
                  <option value="" selected="true">Interested Course</option>
                  <option value="Accountancy">Accountancy</option>
                  <option value="Accounting &amp; Finance">
                    Accounting &amp; Finance
                  </option>
                  <option value="Agricultural Sciences">
                    Agricultural Sciences
                  </option>
                  <option value="Animation">Animation</option>
                  <option value="Applied Sciences">Applied Sciences</option>
                  <option value="Architecture">Architecture</option>
                  <option value="BBA">BBA</option>
                  <option value="Biology">Biology</option>
                  <option value="Biomedical Science">Biomedical Science</option>
                  <option value="Biotechnology">Biotechnology</option>
                  <option value="Business and Economics">
                    Business and Economics
                  </option>
                  <option value="Business and Entrepreneurship">
                    Business and Entrepreneurship
                  </option>
                  <option value="Business and Finance">
                    Business and Finance
                  </option>
                  <option value="Business Management">
                    Business Management
                  </option>
                  <option value="Chemical Engineering">
                    Chemical Engineering
                  </option>
                  <option value="Civil Engineering">Civil Engineering</option>
                  <option value="Computer Science">Computer Science</option>
                  <option value="Computing">Computing</option>
                  <option value="Criminology">Criminology</option>
                  <option value="Cyber Security">Cyber Security</option>
                  <option value="Data Science &amp; Analytics">
                    Data Science &amp; Analytics
                  </option>
                  <option value="Digital Marketing">Digital Marketing</option>
                  <option value="Earth Sciences">Earth Sciences</option>
                  <option value="Economics">Economics</option>
                  <option value="Electrical Engineering">
                    Electrical Engineering
                  </option>
                  <option value="Electrical-Electronics">
                    Electrical-Electronics
                  </option>
                  <option value="Electronics &amp; Communication">
                    Electronics &amp; Communication
                  </option>
                  <option value="Energy Engineering">Energy Engineering</option>
                  <option value="Engineering Management">
                    Engineering Management
                  </option>
                  <option value="Entrepreneurship">Entrepreneurship</option>
                  <option value="Fashion Design">Fashion Design</option>
                  <option value="Finance">Finance</option>
                  <option value="Geology">Geology</option>
                  <option value="Health Sciences">Health Sciences</option>
                  <option value="Hospitality Management">
                    Hospitality Management
                  </option>
                  <option value="Information Technology">
                    Information Technology
                  </option>
                  <option value="Interior Design">Interior Design</option>
                  <option value="International Business">
                    International Business
                  </option>
                  <option value="Journalism &amp; Mass Communication">
                    Journalism &amp; Mass Communication
                  </option>
                  <option value="LLB">LLB</option>
                  <option value="LLM">LLM</option>
                  <option value="MBA">MBA</option>
                  <option value="MBBS">MBBS</option>
                  <option value="Media Science">Media Science</option>
                  <option value="Oil &amp; Gas / Petroleum Engineering">
                    Oil &amp; Gas / Petroleum Engineering
                  </option>
                  <option value="Pharmacy">Pharmacy</option>
                  <option value="Renewable Energy">Renewable Energy</option>
                  <option value="Software Engineering">
                    Software Engineering
                  </option>
                  <option value="Sustainable Development">
                    Sustainable Development
                  </option>
                  <option value="Textile Engineering">
                    Textile Engineering
                  </option>
                  <option value="Others">Others</option>
                </select>
                <div *ngIf="submitted && f.course.errors" class="invalid-feedback">
                  <div *ngIf="f.course.errors.required">Interested Course is required.</div>
              </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-select">
                <select  [ngClass]="{
                  'is-invalid field-error':
                  submitted && f.city.errors
                }"   formControlName="city"  name="city" id="city">
                  <option value="city" selected="true" disabled="disabled">
                    City
                  </option>
                  <option value="Delhi">Delhi</option>
                  <option value="Mumbai - Bandra">Mumbai - Bandra</option>
                  <option value="Mumbai - Thane">Mumbai - Thane</option>
                  <option value="Mumbai - Vashi">Mumbai - Vashi</option>
                  <option value="Mumbai">Mumbai</option>
                  <option value="Bangalore">Bangalore</option>
                  <option value="Chennai">Chennai</option>
                  <option value="Hyderabad">Hyderabad</option>
                  <option value="Pune">Pune</option>
                  <option value="Coimbatore">Coimbatore</option>
                  <option value="Kochi">Kochi</option>
                  <option value="Chandigarh">Chandigarh</option>
                  <option value="Ludhiana">Ludhiana</option>
                  <option value="Amritsar">Amritsar</option>
                  <option value="Jalandhar">Jalandhar</option>
                  <option value="Vizag">Vizag</option>
                  <option value="Ahmedabad">Ahmedabad</option>
                  <option value="Lucknow">Lucknow</option>
                </select>
                <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                  <div *ngIf="f.city.errors.required">City is required.</div>
              </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-select">
                <select [ngClass]="{
                  'is-invalid field-error':
                  submitted && f.checkedLoan.errors
                }"   formControlName="checkedLoan"  name="checkedLoan" id="checkedLoan">
                  <option value="" selected="true" disabled="disabled">
                    Funds
                  </option>
                  <option value="yes">Loan</option>
                  <option value="no">Self</option>
                </select>
                <div *ngIf="submitted && f.checkedLoan.errors" class="invalid-feedback">
                  <div *ngIf="f.checkedLoan.errors.required">Loan is required.</div>
              </div>
              </div>
            </div>
            
            <div class="col-md-12 text-center">
              <div class="row ml-3 my-3">
                <div class="col" *ngFor="let choice of checks; let i=index">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="{{choice.description}}" name="country[]" (change)="onCheckChange($event)" [value]="choice.value" required>
                    <label class="custom-control-label" for="{{choice.description}}">{{choice.description}}</label>
                    <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                      <div *ngIf="f.country.errors.required">
                        Country is required.
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="col text-center ">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheckg" name="example2">
                    <label class="custom-control-label" for="customCheckg">UK</label>
                  </div>
                </div>
                <div class="col text-center">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheckm" name="example2">
                    <label class="custom-control-label" for="customCheckm">USA</label>
                  </div>
                </div>
                <div class="col text-center ">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customChecklm" name="example1">
                    <label class="custom-control-label" for="customChecklm">AUS</label>
                  </div>
              </div>
              <div class="col text-center">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="customCheckla" name="example1">
                  <label class="custom-control-label" for="customCheckla">NZ</label>
                </div>
            </div>
            <div class="col text-center">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customChecko" name="example1">
                <label class="custom-control-label" for="customChecko">Other</label>
              </div>
          </div> -->
          </div>
            </div>
            <div class="col-md-12">
              <button type="submit" class="btn btn--primary w-100 type--uppercase">
                Send Enquiry
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--end of row-->
  </div>
  <!--end of container-->
</section>