import { Component, OnInit } from '@angular/core';
import { ApplicationService } from '../../services/application.service';
import { Application } from '../../interfaces/application';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-statement-purpose',
  templateUrl: './statement-purpose.component.html',
  styleUrls: ['./statement-purpose.component.scss'],
})
export class StatementPurposeComponent implements OnInit {
  staticPage = {} as Application;

  constructor(
    public ApplicationService: ApplicationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (params.slug) {
        this.getStaticPage(params.slug);
      }
    });
  }
  getStaticPage(slug: string) {
    this.ApplicationService.getStaticPage(slug).subscribe((response) => {
      this.staticPage = response;
      console.log(this.staticPage);
    });
  }
}
