import { Component, OnInit } from '@angular/core';
import { PreCounselService } from '../../services/pre-counsel.service';
import {
  PreCounselOTP,
  PreCounselOTPVerify,
  PreCounselForm,
} from '../../interfaces/pre-counsel';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
  FormArray,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AuthService } from 'src/app/core/auth/auth.service';
@Component({
  selector: 'app-pre-counsel',
  templateUrl: './pre-counsel.component.html',
  styleUrls: ['./pre-counsel.component.scss'],
})
export class PreCounselComponent implements OnInit {
  name: string;
  OtpJson = {} as PreCounselOTP;
  OtpVerifyJson = {} as PreCounselOTPVerify;
  PreCounselInfoJson = {} as PreCounselForm;
  token: string;
  otpForm!: FormGroup;
  submitted: boolean = false;
  sectionVisible = 1;
  briefInformationForm!: FormGroup;
  briefinfosubmitted: boolean = false;
  educationsubmitted: boolean = false;
  educationDetailForm: FormGroup;
  qualificationForm: FormGroup;
  qualificationsubmitted: boolean = false;
  experienceForm: FormGroup;
  experiencesubmitted: boolean = false;
  readinessForm: FormGroup;
  readinesssubmitted: boolean = false;
  detailForm: FormGroup;
  detailsubmitted: boolean = false;
  feedbackForm: FormGroup;
  feedbacksubmitted: boolean = false;
  phone: number;
  lastFourCharacter: any;
  showChangePhone: any;
  otpSendStatus: string;
  intake: Array<string> = []

  constructor(
    private notifyService: NotificationService,
    private route: ActivatedRoute,
    private PreCounselService: PreCounselService,
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService
  ) {
    this.formModule();
  }

  ngOnInit(): void {
    this.getIntake();

    this.route.params.subscribe((params) => {
      this.token = params['token'];
      this.getDetails();
    });
  }

  getIntake() {
    let today = new Date();

    for (let index = 0; index < 12; index++) {
      let newMonth = new Date(today.setMonth(today.getMonth()+1));
      
      this.intake.push(
        newMonth.toLocaleString('en-us', { month: 'short' }) +
          ' ' +
          newMonth.getFullYear()
      );
    }
  }

  getDetails() {
    const json = {
      token: this.token,
    };
    this.PreCounselService.getData(json).subscribe((response) => {
      this.phone = response.result.phone;
      this.name = response.result.name;
      this.lastFourCharacter = response.result.phone.substr(-4);
      this.sendOtp(response.result.phone, response.result.name);
    });
  }
  async formModule() {
    this.otpForm = await this.fb.group({
      code: ['', [Validators.required]],
    });

    this.briefInformationForm = await this.fb.group({
      interest_course: ["", []],
      course_keywords: ["", []],
      country: ["", []],
      interest_level: ["", []],
      interest_intake: ["", []],
      exam_given: ["", []],
      exam_score: ["", []],
    });
    this.educationDetailForm = await this.fb.group({
     
      tenth_year: ["", []],
      tenth_percentage: ["", []],
      twelfth_percentage: ["", []],
      twelfth_year: ["", []],
      bachelor_percentage: ["", []],
      bachelor_year: ["", []],
      work_year: ["", []],
      coaching_joined_planning: ["", []],
      require_help_coaching: ["", []],
      budget: ["", []]
    });
    this.qualificationForm = await this.fb.group({
      last_univ: ["", []],
      last_degree: ["", []],
      last_course: ["", []],
      last_pyear: ["", []],
      last_from: ["", []],
      last_to: ["", []],
      last_percentage: ["", []],
      last_backlog: ["", []],
    });
    this.detailForm = await this.fb.group({
      previous_travel: ["", []],
      travel_when: ["", []],
      visa_reject: ["", []],
      visa_rejection_why: ["", []],
      relative_abroad: ["", []],
      relative_abroad_relation: ["", []],
    });
    this.experienceForm = await this.fb.group({
      company_name: ["", []],
      work_domain: ["", []],
      work_year: ["", []],
      work_from: ["", []],
      work_to: ["", []],
      gap_year: ["", []],
      incomplete_course: ["", []],
      gap_reason: [''],
    });
    this.readinessForm = await this.fb.group({
      coaching_joined_planning: ["", []],
      require_help_coaching: ["", []],
      budget: ["", []],
      study_abroad_reason: ["", []],
      shortlist_applied: ["", []],
    });
    this.feedbackForm = await this.fb.group({
      how_hear_meetuniversity: ["", []],
    });
  }

  get p() {
    return this.otpForm.controls;
  }

  get b() {
    return this.briefInformationForm.controls;
  }

  get e() {
    return this.educationDetailForm.controls;
  }

  get q() {
    return this.qualificationForm.controls;
  }

  get exp() {
    return this.experienceForm.controls;
  }

  get r() {
    return this.readinessForm.controls;
  }
  get d() {
    return this.detailForm.controls;
  }
  get o() {
    return this.otpForm.controls;
  }
  get f() {
    return this.feedbackForm.controls;
  }

  sendOtp(phone: any, name: string) {
    this.OtpJson = {
      token: this.token,
      phone: phone,
    };
    this.PreCounselService.sendOtp(this.OtpJson).subscribe(
      async (response: any) => {
        if (response == 'ok') {
          this.otpSendStatus = `Hi ${name} ! We have sent you OTP on XXXXXX${phone.substr(
            -4
          )}`;
          this.notifyService.showSuccess(this.otpSendStatus);
        }
      }
    );
  }

  onConfirm() {
    //this.sectionVisible = 3;
    if (this.otpForm.status == 'INVALID') {
      this.submitted = true;
      return;
    }

    if (this.otpForm.status == 'VALID') {
      this.OtpVerifyJson = {
        token: this.token,
        code: this.otpForm.value.code,
      };

      this.PreCounselService.verifyOtp(this.OtpVerifyJson).subscribe(
        async (response: any) => {
          if (response == 'Verified') {
            this.notifyService.showSuccess(
              'Your otp is  verified successfully'
            );
            this.sectionVisible = 3;
          } else {
           // this.sectionVisible = 3;
            this.notifyService.showError('Your otp does not match or verified');
          }
        },(error)=>{
          //this.sectionVisible = 3;
          this.notifyService.showError('Your otp does not match or verified');
        }
      );
    }
  }

  async saveBriefInfoForm() {
    this.briefinfosubmitted = true;
    console.log(
      this.briefinfosubmitted,
      this.briefInformationForm.controls.interest_course.errors
    );
    console.log(this.briefInformationForm);
    if (this.briefInformationForm.status == 'INVALID') {
      this.briefinfosubmitted = true;
      return;
    }
    var data={
      exam_given: this.briefInformationForm.value.exam_given,
      exam_score: this.briefInformationForm.value.exam_score,
      interest_course: this.briefInformationForm.value.course_keywords,
      course_keywords: this.briefInformationForm.value.exam_given,
      interest_level: this.briefInformationForm.value.interest_level,
      interest_intake: this.briefInformationForm.value.interest_intake,
      country: this.briefInformationForm.value.country,
    }
    var parseData=JSON.stringify(data);
    if (this.briefInformationForm.status == 'VALID') {
      this.PreCounselInfoJson = {
        token: this.token,
        step: 2,
        data: parseData
      };
      this.saveFormInformation(2);
    }
  }

  async educationDetailSubmit() {
    this.educationsubmitted = true;
    console.log(this.educationDetailForm);

    if (this.educationDetailForm.status == 'INVALID') {
      this.educationsubmitted = true;
      return;
    }
    var data={
      tenth_year: this.educationDetailForm.value.tenth_year,
      tenth_percentage: this.educationDetailForm.value.tenth_percentage,
      twelfth_percentage: this.educationDetailForm.value.twelfth_percentage,
      twelfth_year: this.educationDetailForm.value.twelfth_year,
      bachelor_percentage: this.educationDetailForm.value.bachelor_percentage,
      bachelor_year: this.educationDetailForm.value.bachelor_year,
      work_year: this.educationDetailForm.value.work_year,
      coaching_joined_planning: this.educationDetailForm.value.coaching_joined_planning,
      require_help_coaching: this.educationDetailForm.value.require_help_coaching,
      budget: this.educationDetailForm.value.budget,
      
    }
    var parseData=JSON.stringify(data);
    if (this.educationDetailForm.status == 'VALID') {
      this.PreCounselInfoJson = {
        token: this.token,
        step: 3,
        data:parseData
      };
      this.saveFormInformation(3);
    }
  }

  async qualificationSubmit() {
    console.log(this.qualificationForm);
    this.qualificationsubmitted = true;

    if (this.qualificationForm.status == 'INVALID') {
      this.qualificationsubmitted = true;
      return;
    }
    var data={
      last_univ: this.qualificationForm.value.last_univ,
      last_degree: this.qualificationForm.value.last_degree,
      last_course: this.qualificationForm.value.last_course,
      last_pyear: this.qualificationForm.value.last_pyear,
      last_from: this.qualificationForm.value.last_from,
      last_to: this.qualificationForm.value.last_to,
      last_percentage: this.qualificationForm.value.last_percentage,
      last_backlog: this.qualificationForm.value.last_backlog,
    }
    var parseData=JSON.stringify(data);
    if (this.qualificationForm.status == 'VALID') {
      this.PreCounselInfoJson = {
        token: this.token,
        step: 4,
        data:parseData
      };
      this.saveFormInformation(4);
    }
  }

  async experienceSubmit() {
    console.log(this.experienceForm);
    this.experiencesubmitted = true;

    if (this.experienceForm.status == 'INVALID') {
      this.experiencesubmitted = true;
      return;
    }
    var data={
      company_name: this.experienceForm.value.company_name,
      work_domain: this.experienceForm.value.work_domain,
      work_year: this.experienceForm.value.work_year,
      work_from: this.experienceForm.value.work_from,
      work_to: this.experienceForm.value.work_to,
      gap_year: this.experienceForm.value.gap_year,
      incomplete_course: this.experienceForm.value.incomplete_course,
      gap_reason: this.experienceForm.value.gap_reason,
    }
    var parseData=JSON.stringify(data);
    if (this.experienceForm.status == 'VALID') {
      this.PreCounselInfoJson = {
        token: this.token,
        step: 5,
        data: parseData
      };
      this.saveFormInformation(5);
    }
  }

  async readinessSubmit() {
    console.log(this.readinessForm);
    this.readinesssubmitted = true;

    if (this.readinessForm.status == 'INVALID') {
      this.readinesssubmitted = true;
      return;
    }
    if (this.readinessForm.status == 'VALID') {
      var data={
        coaching_joined_planning:
        this.readinessForm.value.coaching_joined_planning,
        require_help_coaching: this.readinessForm.value.require_help_coaching,
        budget: this.readinessForm.value.budget,
        shortlist_applied: this.readinessForm.value.shortlist_applied,
        study_abroad_reason: this.readinessForm.value.study_abroad_reason,
      }
      var parseData=JSON.stringify(data);
      this.PreCounselInfoJson = {
        token: this.token,
        step: 6,
        data:parseData
      };
      this.saveFormInformation(6);
    }
  }

  async detailSubmit() {
    console.log(this.detailForm);
    this.detailsubmitted = true;

    if (this.detailForm.status == 'INVALID') {
      this.detailsubmitted = true;
      return;
    }
    if (this.detailForm.status == 'VALID') {
      var data={
        previous_travel: this.detailForm.value.previous_travel,
          travel_when: this.detailForm.value.travel_when,
          visa_reject: this.detailForm.value.visa_reject,
          visa_rejection_why: this.detailForm.value.visa_rejection_why,
          relative_abroad: this.detailForm.value.relative_abroad,
          relative_abroad_relation:
            this.detailForm.value.relative_abroad_relation,
      }
      var parseData=JSON.stringify(data);
      this.PreCounselInfoJson = {
        token: this.token,
        step: 7,
        data:parseData
      };
      this.saveFormInformation(7);
    }
  }

  async feedbackSubmit() {
    console.log(this.detailForm);
    this.feedbacksubmitted = true;

    if (this.feedbackForm.status == 'INVALID') {
      this.feedbacksubmitted = true;
      return;
    }
    var data={
      how_hear_meetuniversity:
      this.feedbackForm.value.how_hear_meetuniversity,
    }
    var parseData=JSON.stringify(data);
    if (this.feedbackForm.status == 'VALID') {
      this.PreCounselInfoJson = {
        token: this.token,
        step: 8,  
        data:parseData
      };
      
      this.saveFormInformation(8);
    }
  }

  saveFormInformation(step: number) {
    console.log("1212");
    this.PreCounselService.updateLmsProfile(this.PreCounselInfoJson).subscribe(
      async (response: any) => {
        // if (response == 'ok') {
          if (step == 2) {
            this.sectionVisible = 4;
          } else if (step == 3) {
            this.sectionVisible = 5;
          } else if (step == 4) {
            this.sectionVisible = 6;
          } else if (step == 5) {
            this.sectionVisible = 7;
          } else if (step == 6) {
            this.sectionVisible = 8;
          } else if (step == 7) {
            this.sectionVisible = 9;
          } else if (step == 8) {
            this.sectionVisible = 10;
          }
        // }
      }
    );
  }

  onShowPhone() {
    this.showChangePhone = !this.showChangePhone;
  }

  onChangePhone(newphone: string) {
    if (newphone == '') {
      this.notifyService.showError('Please fill new phone number.');
      return;
    }

    const json = {
      token: this.token,
      new_phone: newphone,
    };
    this.PreCounselService.onChangePhone(json).subscribe(
      (response) => {
        if (response) {
          this.notifyService.showSuccess(
            'Our representative will get in touch soon!'
          );
          this.showChangePhone = false;
        }
      },
      (error) => {
        this.notifyService.showError(error);
      }
    );
  }
}
