import { Component, Input, OnInit } from '@angular/core';
import { FaqData } from '../../interfaces/faqData';
import { FaqService } from '../../services/faq.service';
import { MessageService } from '../../../core/services/message.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-public-faq',
  templateUrl: './public-faq.component.html',
  styleUrls: ['./public-faq.component.scss'],
})
export class PublicFaqComponent implements OnInit {
  @Input() q: string;
  faqDatas: FaqData[];
  messages: any[] = [];
  subscription: Subscription;
  country: string;
  constructor(private faqService: FaqService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.q = this.country || 'general';
    this.subscription = this.messageService.onMessage().subscribe(message => {
      this.country = message.country
      this.q = this.country || 'general';
      this.getFaq();
    });
    this.getFaq();
    setTimeout(() => {
      this.accordionFunction();
    }, 2000);
  }

  accordionFunction() {
    var acc = document.getElementsByClassName("accordions");
    var i;
    
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function(this:any ) {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        } 
      });
    }
  }
  
  getFaq() {
    this.faqService.getFaq(this.q).subscribe((response) => {
      this.faqDatas = response.faqs;      
    });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }
}
