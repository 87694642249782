<section class="cover height-80 imagebg parallax" data-overlay="4" style="visibility: visible">
    <div class="background-image-holder" style="
      background: url('assets/img/education-1.jpg');
      opacity: 1;
      top: -63.6562px;
      transform: translate3d(0px, 0px, 0px);
    ">
        <img height="auto" width="auto" alt="image" loading="lazy" src="assets/img/education-1.jpg" />
    </div>
    <div class="container pos-vertical-center">
        <div class="row">
            <div class="col-md-9 col-lg-7">
                <h1>
                    {{ DisciplineInner.name }} :
                    {{ DisciplineInner.course_count }} Courses in
                    {{ DisciplineInner.univ_count }} Universities available.
                </h1>
                <p class="lead">
                    {{ DisciplineInner.info }}
                </p>
                <div class="modal-instance block">
                    <div (click)="openModal(DisciplineInner.video_url)"
                        class="video-play-icon video-play-icon--sm modal-trigger" data-modal-index="1"></div>
                    <span> </span>

                    <!--end of modal-container-->
                </div>
                <!--end of modal instance-->
            </div>
        </div>
        <!--end of row-->
    </div>
    <!--end of container-->
</section>

<section class="switchable feature-large">
    <div class="container">
        <div class="row justify-content-around">
            <div class="col-md-6">
                <div class="boxed boxed--lg boxed--border">
                    <div class="feature feature-2">
                        <i class="icon icon-Magnifi-Glass2 color--primary"></i>
                        <div class="feature__body">
                            <h5>Search</h5>
                            <p>
                                Search from our extensive course and university listings for a
                                course that suits you best.
                            </p>
                        </div>
                    </div>
                    <!--end feature-->
                    <div class="feature feature-2">
                        <i class="icon icon-Magnifi-Glass color--primary"></i>
                        <div class="feature__body">
                            <h5>Discover</h5>
                            <p>
                                Set Filters according to your requirements and narrow down to
                                speciﬁc courses and universities to suit your needs.
                            </p>
                        </div>
                    </div>
                    <!--end feature-->
                    <div class="feature feature-2">
                        <i class="icon icon-Folder-Share color--primary"></i>
                        <div class="feature__body">
                            <h5>Apply</h5>
                            <p>
                                Upon narrowing down to the required Course and University,
                                simply upload your documents and apply.
                            </p>
                        </div>
                    </div>
                    <!--end feature-->
                </div>
            </div>

            <div class="col-md-6 col-lg-5">
                <div class="switchable__text">
                    <h2>
                        We help you search, discover and apply to desired university with
                        ease.
                    </h2>
                    <p class="lead">
                        Get experienced assistance throughout your application process. Have
                        everything from your Application forms to SoPs, Loan Application to
                        Accommodation and Visa taken care of.
                    </p>
                </div>
            </div>
        </div>
        <!--end of row-->
    </div>
    <!--end of container-->
</section>

<section class="imagebg parallax" data-scrim-top="9" style="visibility: visible">
    <div class="background-image-holder" style="
      background: url('assets/img/education-3.jpg');
      opacity: 1;
      transform: translate3d(0px, 128.039px, 0px);
    ">
        <img height="auto" width="auto" alt="image" loading="lazy" src="assets/img/education-3.jpg" />
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="cta">
                    <h2>Applications are now open</h2>
                    <p class="lead">
                        We're now helping students apply for for 2022 intakes.
                        MeetUniversity has a 99% success rate for students applying for
                        international universities . get in touch to perfect your
                        application
                    </p>
                    <a class="btn btn--primary type--uppercase" routerLink="/ask-expert">
                        <span  class="btn__text"> Apply Now </span>
                    </a>
                </div>
            </div>
        </div>
        <!--end of row-->
    </div>
    <!--end of container-->
</section>

<section class="course-section">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-md-12 col-lg-12">
                <h2>Featured Courses</h2>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4 col-12" *ngFor="let feature of FeatureCourses">
                <div class="boxed boxed--border courselist">
                    <div class="row">
                        <div class="col-sm-4 col-lg-3">
                            <img height="auto" width="auto" alt="image" loading="lazy" [src]="imgUrl+feature.img"
                                class="profilepic" />
                        </div>
                        <div class="col-sm-8 col-lg-9">
                            <h4 class="cardmargin">
                                <b>{{ feature.name }}</b>
                            </h4>
                            <p class="word-slice">{{ feature.univ_name }}</p>
                            <p class="bluecolor word-slice">
                                {{ feature.duration }}
                            </p>
                            <div class="teffaf">
                                <div class="teffel-left smalltxt">
                                    Toefl:<br />
                                    {{ feature.toefl }}<sup>*</sup>
                                </div>
                                <div class="teffel-left smalltxt">
                                    Ielts:<br />
                                    {{ feature.ielts }}
                                </div>
                                <div class="teffel-left smalltxt">
                                    Intake:<br /><b>{{ feature.intake }}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end item-->
        </div>

        <div class="text-center">
            <a class="btn btn--primary type--uppercase" href="/search?q={{indusryType}}">
                <span class="btn__text"> View All </span>
            </a>
        </div>

        <!--end of row-->
    </div>
    <!--end of container-->
</section>