<section class="m1" style="
    background: URL('https://c4.wallpaperflare.com/wallpaper/487/320/851/tower-bridge-london-bridge-europe-wallpaper-preview.jpg');
    width: 100%;
    height: 500px;
    background-repeat: no-repeat;
    background-size: 100% 130%;
  ">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <a href="#" style="color: white">Home &nbsp; &nbsp; ⨠ &nbsp; &nbsp; </a>
                <h1 *ngIf="currentPage=='top-rank-university'" class="h1--large" style="color: white; margin-top: 15px">
                    Top Rank Universities in <span class="text-uppercase">{{country}}</span>
                </h1>
                <h1 *ngIf="currentPage=='economical-university'" class="h1--large" style="color: white; margin-top: 15px">
                    Economical Universities in <span class="text-uppercase">{{country}}</span>
                </h1>
                 <h1 *ngIf="currentPage=='friendly-university'" class="h1--large" style="color: white; margin-top: 15px">
                    Friendly Universities in <span class="text-uppercase">{{country}}</span>
                </h1>
               
            </div>
        </div>
    </div>
    <!-- /.container-->
</section>



<section style="padding: 6rem 0">
    <div class="container">
        <div class="row">
            <div class="col-sm-8">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>University</th>
                                <th>Ranking</th>
                                <th></th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr  *ngFor="let item of univertyList; let i=index" >
                                <td>{{i+1}}</td>
                                <td>{{item.name}}</td>
                                <td>{{item.rank}}</td>
                                <td><button (click)="navigateToUniversityDetail(item)" class="btn btn-primary btn-sm">Know more</button></td>

                            </tr>
                       
                        </tbody>
                    </table>
                </div>
                <!-- <div class="row" style="margin-top: 15px">
                    <div class="col-sm-5">
                        <img src="https://mu-assets.s3.amazonaws.com/new/img/misc/app-apply.jpg" style="width: 100%"
                            alt="" />
                    </div>
                    <div class="col-sm-7" >
                        <span></span>

                        <a class="btn btn--primary-2" style="margin-top: 5px; font-size: 16px"
                        href="/apply-to-abroad-university">
                        <span class="btn__text">Apply Now</span>
                    </a>
                    </div>

                </div>

                <div class="row" style="margin-top: 15px">
                    <div class="col-sm-5">
                        <img src="https://mu-assets.s3.amazonaws.com/new/img/misc/app-juno.jpg" style="width: 100%"
                            alt="" />
                    </div>
                    <div class="col-sm-7">
                        <p class="lead">
                            SDFASF
                        </p>
                        <a class="btn btn--primary-2" style="margin-top: 10px; font-size: 16px"
                            href="/apply-to-abroad-university">
                            <span class="btn__text">Ask Juno</span>
                        </a>
                    </div>
                </div>

                <div class="row" style="margin-top: 15px">
                    <div class="col-sm-5">
                        <img src="	https://mu-assets.s3.amazonaws.com/new/img/misc/app-scholarship.jpg"
                            style="width: 100%" alt="" />
                    </div>
                    <div class="col-sm-7">
                        <p class="lead">AFD</p>
                        <a class="btn btn--primary-2" style="margin-top: 10px; font-size: 16px"
                            href="/apply-to-abroad-university">
                            <span class="btn__text">Get Scholarship</span>
                        </a>
                    </div>
                </div> -->
            </div>
            <div class="col-sm-4">
                <div class="boxed boxed--border bg--secondary">
                    <div class="sidebar__widget">
                        <h5>Articles &amp; News</h5>
                        <ul class="sidebar-article-lh">
                            <li>- Scholarships to Study in UK</li>
                            <li>
                                - Scholarships for International Students in UK | Study in UK
                                Scholarships
                            </li>
                        </ul>
                    </div>
                    <!--end widget-->
                    <div class="sidebar__widget">
                        <h5>Quick Tip</h5>
                        <p>
                            To get professional assitance for your study abraod related
                            queries, <a href="/expert-list">visit here</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
