<section class="m1" class="bg-image" 
[style.background-image]="'url(' + imageUrls[currentImageIndex] + ')'"
[@fadeInOut] style="
    width: 100%;
    height: 500px;
    background-repeat: no-repeat;
    background-size: 100% 130%;
    background-size: cover; position: relative;
  "><div class="overlay"></div>
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <!-- <a href="#" style="color: white">Home &nbsp; &nbsp; ⨠ &nbsp; &nbsp; </a> -->
                <h1 class="h1--large" style="color: white; margin-top: 15px">
                    Study in <span class="text-uppercase">{{ countryId }}</span>
                </h1>
                <p class="header-desc">Explore this page to uncover the keys to pursuing your academic ambitions in a country where dreams are realized and horizons are expanded.</p>
                <a class="btn btn--primary type--uppercase" routerLink='/ask-expert'>
                    <span class="btn__text"> Apply Now </span>
                </a>
            </div>
        </div>
    </div>
    <!-- /.container-->
</section>

<section class="bg--secondary" style="padding: 25px 0px">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12">
                <div>
                    <h2 class="planning-study" style="text-align: center;">
                        Planning to study in
                        <span class="text-uppercase">{{ countryId }}</span>?
                        <a rel="noopener" href="/expert-list">
                            <span class="btn__text" style="text-decoration: underline">
                                Get Free Expert Advise now.
                            </span>
                        </a>
                    </h2>
                </div>
            </div>
        </div>
    </div>
</section>

<section style="padding: 0px">
    <div class="row">
        <div class="col-sm-7 bg--primary" style="padding: 5rem 80px">
            <h1 class="title">Why Study in <span class="text-uppercase">{{ countryId }}</span>?</h1>
            <div innerHTML="{{ countryPage.overview }}"></div>
        </div>
        <div class="col-sm-5 bg--primary-1" style="padding: 5rem 60px; color: white">
            <h1 class="title">Facts</h1>
            <ul style="list-style: unset" innerHTML="{{ countryPage.facts }}">
                
            </ul>
        </div>
    </div>
</section>

<section class="space--sm unpad--bottom bg--secondary" style="padding-bottom: 4.95238095em !important">
    <div class="container">
        <h2 style="color: black;" class="title">Choose where you want to go</h2>
        <div class="row">
            <div class="col-md-4">
                <div class="card-container cursor-pointer" routerLink="/top-rank-university/uk">
                    <img alt="background" src="../../../../assets/img/latest-ranking.jpg" />
                    <h4>Worlds to rank universities with latest rankings</h4>
                    <div class="application-deadline">Application Deadline</div>
                    <div class="time-counter">
                        <div class="counter-container">
                            <span>{{ remainingTime.days }}</span><span>days</span>
                        </div>
                        <div class="counter-container">
                            <span>{{ remainingTime.hours }}</span><span>hours</span>
                        </div>
                        <div class="counter-container">
                            <span>{{ remainingTime.minutes }}</span><span>minutes</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card-container cursor-pointer" routerLink="/economical-university/uk">
                    <img alt="background" src="../../../../assets/img/budget-friendly.jpg" />
                    <h4>Universities with budget freindlly fees</h4>
                    <div class="application-deadline">Application Deadline</div>
                    <div class="time-counter">
                        <div class="counter-container">
                            <span>{{ remainingTime.days }}</span><span>days</span>
                        </div>
                        <div class="counter-container">
                            <span>{{ remainingTime.hours }}</span><span>hours</span>
                        </div>
                        <div class="counter-container">
                            <span>{{ remainingTime.minutes }}</span><span>minutes</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card-container cursor-pointer" routerLink="/friendly-university/uk">
                    <img alt="background" src="../../../../assets/img/good-facilities.jpg" />
                    <h4>Universities with good facilities & having a good faclity</h4>
                    <div class="application-deadline">Application Deadline</div>
                    <div class="time-counter">
                        <div class="counter-container">
                            <span>{{ remainingTime.days }}</span><span>days</span>
                        </div>
                        <div class="counter-container">
                            <span>{{ remainingTime.hours }}</span><span>hours</span>
                        </div>
                        <div class="counter-container">
                            <span>{{ remainingTime.minutes }}</span><span>minutes</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--end of row-->
    </div>
</section>

<section style="padding: 2rem 0">
    <div class="container">
        <div class="row" style="padding: 0 18px">
            <div class="col-sm-6" style="padding: 30px 20px">
                <h1 style="font-size: 2.35714286em">
                    We help you search, discover and apply to desired university with
                    ease.
                </h1>
                <br />
                <br />
                <p style="font-size: 21px; color: grey">
                    Get experienced assistance throughout your application process. Have
                    everything from your Application forms to SoPs, Loan Application to
                    Accommodation and Visa taken care of.
                </p>
            </div>
            <div class="col-sm-6" style="
          padding: 30px 20px;
          border: 1px solid #ececec;
          border-radius: 6px;
        ">
                <div style="padding: 10px">
                    <div class="row" style="display: flex; align-items: center">
                        <div class="col-sm-3" style="padding: 5%">
                            <i class="icon icon-Magnifi-Glass2 color--primary"></i>
                        </div>
                        <div class="col-sm-9">
                            <h2 style="
                  margin-bottom: 6px;
                  font-size: 1rem;
                  font-weight: bold;
                  color: black;
                ">
                                Search
                            </h2>
                            <p>
                                Search from our extensive course and university listings for a
                                course that suits you best.
                            </p>
                        </div>
                    </div>
                    <div class="row" style="display: flex; align-items: center">
                        <div class="col-sm-3" style="padding: 5%">
                            <i class="icon icon-Magnifi-Glass color--primary"></i>
                        </div>
                        <div class="col-sm-9">
                            <h2 style="
                  margin-top: 25px;
                  margin-bottom: 6px;
                  font-size: 1rem;
                  font-weight: bold;
                  color: black;
                ">
                                Discover
                            </h2>
                            <p>
                                Set Filters according to your requirements and narrow down to
                                speciﬁc courses and universities to suit your needs..
                            </p>
                        </div>
                    </div>
                    <div class="row" style="display: flex; align-items: center">
                        <div class="col-sm-3" style="padding: 5%">
                            <i class="icon icon-Folder-Share color--primary"></i>
                        </div>
                        <div class="col-sm-9">
                            <h2 style="
                                margin-top: 25px;
                                margin-bottom: 6px;
                                font-size: 1rem;
                                font-weight: bold;
                                color: black;
                                ">
                                Apply
                            </h2>
                            <p>
                                Upon narrowing down to the required Course and University,
                                simply upload your documents and apply.
                            </p>
                            <a class="btn btn--primary-2" style="margin-top: 10px; font-size: 16px">
                            <span routerLink="/ask-expert" class="btn__text">Apply Now</span>
                        </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="promo-card">
            <div class="overlay"></div>
            <h4>Unlock the Door to Your Global Future: Discover, Learn, and Thrive Abroad with Meet University</h4>
            <p>Get experienced assistance throughout your application process. Have everything from your Application forms to SoPs, Loan Application to Accommodation and Visa taken care of.</p>
            <a class="btn btn--primary type--uppercase" routerLink='/ask-expert'>
                <span class="btn__text"> Apply Now </span>
            </a>
        </div>    
    </div>
</section>

<section class="bg--secondary" style="padding: 2rem 0">
    <div class="container">
        <h2>Explore <span class="text-uppercase">{{ countryId }}</span></h2>
        <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
                <iframe height="730" width="770" class="teleport-card" [src]="this.costURL"></iframe>
            </div>
            <div class="col-sm-12 col-md-6">
                <iframe height="730" width="770" class="teleport-card" [src]="this.jobSalaryURL"></iframe>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
                <iframe height="1365" width="770" class="teleport-card" [src]="this.safetyURL"></iframe>
            </div>
            <div class="col-sm-12 col-md-6">
                <iframe height="1365" width="770" class="teleport-card" [src]="this.educationURL"></iframe>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
                <iframe height="820" width="770" class="teleport-card" [src]="this.climateURL"></iframe>
            </div>
            <div class="col-sm-12 col-md-6">
                <iframe height="820" width="770" class="teleport-card" [src]="this.exploreURL"></iframe>
            </div>
        </div>
    </div>
</section>

<section style="padding-top: 3rem">
    <div class="container">
        <h2>Popular Courses</h2>
        <div class="row">
            <div class="col-md-4">
                <div class="card-container cursor-pointer" routerLink="/disciplines/IEJ1c2luZXNzICYgTWFuYWdlbWVudCA">
                    <img alt="background" src="../../../../assets/img/mba.jpg" />
                    <h4 class="card-title">Master of Business Administration (MBA)</h4>
                    <div class="card-description">Elevate your business acumen with an MBA program that emphasizes leadership, strategic management, and entrepreneurship, offered by some of Canada's top business schools.</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card-container cursor-pointer" routerLink="/disciplines/IENvbXB1dGVyIFNjaWVuY2UgJiBJVCA">
                    <img alt="background" src="../../../../assets/img/tech.jpg" />
                    <h4 class="card-title">Computer Science &amp; IT</h4>
                    <div class="card-description">Computer Sciences and IT are skills that automate processes by developing softwares and hardwares. Globally, more and more organizations are adopting automation. This is creating a huge demand for skilled Computer Science and IT professionals making it a impressively high paying job.</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card-container cursor-pointer" routerLink="/disciplines/IEVuZ2luZWVyaW5nICYgVGVjaG5vbG9neSA">
                    <img alt="background" src="../../../../assets/img/engg.jpg" />
                    <h4 class="card-title">Engineering &amp; Technology</h4>
                    <div class="card-description">While change is the only constant, nothing is changing faster than technology. As an engineer, you can help facilitate change across industries such as manufacturing, maintenance and management and increase their efficiency and demand a very favorable compensation.</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-12 mb-4" *ngFor="let course of courses">
                <div class="boxed boxed--border courselist">
                    <div class="row">
                        <div class="col-sm-3 padding">
                            <img height="auto" width="auto" alt="image" loading="lazy" src="{{course.course[0].img}}"
                                class="profilepic" />
                        </div>
                        <div class="col-sm-9 padding">
                            <h4 class="cardmargin">
                                <b>{{course.course[0].name}}</b>
                            </h4>
                            <p class="word-slice">
                                <a rel="noopener" href="#">{{course.course[0].univ.name}}</a>
                            </p>
                            <p class="bluecolor word-slice">
                                {{course.course[0].meta_data[0].duration}}
                            </p>
                            <div class="teffaf">
                                <div class="teffel-left smalltxt">
                                    Toefl:<br />
                                    {{course.course[0].score.toefl}}<sup>*</sup>
                                </div>
                                <div class="teffel-left smalltxt">
                                    Ielts:<br />
                                    {{course.course[0].score.ielts}}
                                </div>
                                <div class="teffel-left smalltxt">
                                    Intake:<br /><b>{{course.course[0].meta_data[0].intake}}</b>
                                </div>
                            </div>
                            <div class="mt-3">
                                <a class="btn mySquareBtn" href=""><span class="btn__text">
                                        <i class="icon icon--lg icon-Bookmark"></i> </span></a>
                                <a class="btn mySquareBtn" href="https://wa.me/919289499701" target="_blank"><span class="btn__text">
                                        <i class="socicon-whatsapp icon"></i></span>
                                </a>
                                <a class="btn mySquareBtn" href="tel:+918375034794">
                                    <span class="btn__text">
                                        <i class="icon-Phone-2 icon"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end of container-->
</section>



<section style="padding: 2rem 0" [hidden]="blogs.length == 0">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12">
                <h2>Know more about <span class="text-uppercase">{{ countryId }}</span>, Universities & Student Life from our students & blogs</h2>
            </div>
        </div>
    </div>
</section>

<section style="padding-top: 0" [hidden]="blogs.length == 0">
    <div class="container">
        <div class="row">
            <div *ngFor="let blog of blogs" class="col-md-4">
                <div class="blog_card boxed boxed--border">
                    <img [src]="blog.thumbnail" alt="{{blog.thumbnail}}">
                    <h4 class="line-clamp" [innerHTML]="">{{blog.title}}</h4>
                    <a [href]="blog.link" target="_blank">Read More</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section style="padding: 2rem 0" *ngIf="eventsData.length > 0">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12">
                <h2>Events this month</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4" *ngFor="let item of eventsData">
                <div>
                    <div class="feature feature-1 boxed boxed--border">
                        <span class="label">{{ item.venue }}</span>
                        <h5 style="margin-top: 10px;">{{ item.university }}</h5>
                        <p style="margin: 10px 0px;">{{ item.address}}</p>
                        <p>From {{ item.startTime }} to {{ item.endTime }}</p>
                        <a class="btn btn--primary type--uppercase" style="margin-top: 10px;" routerLink='/ask-expert'>
                            <span class="btn__text"> Apply Now </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        </div>
</section>

<!-- <section style="padding: 2rem 0">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12">
                <h2>Get professional Assistance</h2>
            </div>
        </div>
    </div>
</section> -->

<!-- <section style="padding: 0">
    <div class="container">
        <div class="row">
            <div class="col-sm-8">
                <div class="row" style="margin-top: 15px">
                    <div class="col-sm-5">
                        <img src="https://mu-assets.s3.amazonaws.com/new/img/misc/app-apply.jpg" style="width: 100%"
                            alt="" />
                    </div>
                    <div class="col-sm-7" >
                        <span innerHTML = {{countryPage.stats}}></span>

                        <a class="btn btn--primary-2" style="margin-top: 5px; font-size: 16px">
                        <span routerLink="/ask-expert" class="btn__text">Apply Now</span>
                    </a>
                    </div>

                </div>

                <div class="row" style="margin-top: 15px">
                    <div class="col-sm-5">
                        <img src="https://mu-assets.s3.amazonaws.com/new/img/misc/app-juno.jpg" style="width: 100%"
                            alt="" />
                    </div>
                    <div class="col-sm-7">
                        <p class="lead">
                            {{countryPage.application_visa}}
                        </p>
                        <a class="btn btn--primary-2" style="margin-top: 10px; font-size: 16px"
                            >
                            <span routerLink="/ask-juno" class="btn__text">Ask Juno</span>
                        </a>
                    </div>
                </div>

                <div class="row" style="margin-top: 15px">
                    <div class="col-sm-5">
                        <img src="	https://mu-assets.s3.amazonaws.com/new/img/misc/app-scholarship.jpg"
                            style="width: 100%" alt="" />
                    </div>
                    <div class="col-sm-7">
                        <p class="lead">{{countryPage.scholarship}}</p>
                        <a class="btn btn--primary-2" style="margin-top: 10px; font-size: 16px"
                        >
                            <span routerLink="/get-scholarship" class="btn__text">Get Scholarship</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="boxed boxed--border bg--secondary">
                    <div class="sidebar__widget">
                        <h5>Articles &amp; News</h5>
                        <ul class="sidebar-article-lh">
                            <li>- Scholarships to Study in UK</li>
                            <li>
                                - Scholarships for International Students in UK | Study in UK
                                Scholarships
                            </li>
                        </ul>
                    </div>
                    <div class="sidebar__widget">
                        <h5>Quick Tip</h5>
                        <p>
                            To get professional assitance for your study abraod related
                            queries, <a href="/expert-list">visit here</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->