import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { COUNTRY } from 'src/app/core/constants/country.constants';
import { CountryPage } from '../../interfaces/country-page';
import { CountryPageService } from '../../services/country-page.service';
import { MessageService } from 'src/app/core/services/message.service';
import { FlickrService } from 'src/app/public/services/flickr.service';
import { WordPressService } from '../../services/wordpress.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { trigger, style, animate, transition } from '@angular/animations';
import { CourseListService } from '../../services/courselist.service';
import { DataService } from './dataservice';
@Component({
  selector: 'app-country-pages',
  templateUrl: './country-pages.component.html',
  styleUrls: ['./country-pages.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('3s', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('3s', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class CountryPagesComponent implements OnInit {
  countryData: any[] = [];
  currentIndex = 0;
  timer: any;
  remainingTime: { days: number, hours: number, minutes: number };
  countryId: string;
  imageUrls: any[] = [];
  blogs: any[] = [];
  cityName: string;
  courses: any;
  countryPage : CountryPage;
  costURL: SafeResourceUrl;
  educationURL: SafeResourceUrl;
  jobSalaryURL: SafeResourceUrl;
  safetyURL: SafeResourceUrl;
  climateURL: SafeResourceUrl;
  exploreURL: SafeResourceUrl;
  bgImage: string = this.imageUrls[0];
  currentImageIndex: number = 0;
  eventsData: any[];
  constructor(private dataService: DataService, private courseService: CourseListService, private sanitizer: DomSanitizer, private blogService: WordPressService, private flickrService: FlickrService, private messageService: MessageService,private route: ActivatedRoute, private router: Router, private countryPageService : CountryPageService) {}

  ngOnInit(): void {

    this.remainingTime = this.calculateRemainingTime();

    var acc = document.getElementsByClassName('accordion');
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener('click', function (this: any) {
        this.classList.toggle('active');
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + 'px';
        }
      });
    }

    this.route.params.subscribe((params: Params) => {
      this.blogs = [];
      if (params.countryid) {
        let checkExistingCountry = COUNTRY.STUDENT_GUIDE_COUNTRY.filter(
          (x) => x == params.countryid
        );

        if (checkExistingCountry.length == 0) {
          this.router.navigate(['/notfound']);
        }
        this.countryId = params.countryid;
        this.messageService.sendMessage({country:params.countryid})
        this.getCountryDetails(this.countryId);

        this.blogService.fetchCountries().subscribe(response => {
          let countryId = this.blogService.getCountryId(response.Country, this.countryId);
          this.blogService.fetchCountryData(countryId).subscribe(data => {
            this.blogs = data;
          });
        });
        this.updateCityName(params.countryid);
        this.costURL = this.sanitizer.bypassSecurityTrustResourceUrl(`https://teleport.org/cities/${this.cityName}/widget/costs/?currency=USD`);
        this.educationURL = this.sanitizer.bypassSecurityTrustResourceUrl(`https://teleport.org/cities/${this.cityName}/widget/education/?currency=USD`);
        this.jobSalaryURL = this.sanitizer.bypassSecurityTrustResourceUrl(`https://teleport.org/cities/${this.cityName}/widget/salaries/?currency=USD`);
        this.safetyURL = this.sanitizer.bypassSecurityTrustResourceUrl(`https://teleport.org/cities/${this.cityName}/widget/crime/?currency=USD`);
        this.climateURL = this.sanitizer.bypassSecurityTrustResourceUrl(`https://teleport.org/cities/${this.cityName}/widget/weather/?currency=USD`);
        this.exploreURL = this.sanitizer.bypassSecurityTrustResourceUrl(`https://teleport.org/cities/${this.cityName}/widget/outdoors/?currency=USD`);
      }
      this.imageUrls = this.flickrService.getImages(this.countryId);
      this.courseService.getData(this.countryId).subscribe(response => {
        this.courses = response.results.slice(0, 4);;
      });
      
      this.eventsData = this.dataService.getDataByCountry(this.countryId.toUpperCase());
    });

    setInterval(() => {
      this.currentImageIndex = (this.currentImageIndex + 1) % this.imageUrls.length;
    }, 3000);

  }

  updateCityName(countryCode: string) {
    if (countryCode==="uk") {
      this.cityName = "london"
    }
    else if (countryCode==="singapore") {
      this.cityName = "singapore"
    }
    else if (countryCode==="france") {
      this.cityName = "paris"
    }
    else if (countryCode==="usa") {
      this.cityName = "washington-dc"
    }
    else if (countryCode==="australia") {
      this.cityName = "sydney"
    }
    else if (countryCode==="canada") {
      this.cityName = "ottawa"
    }
    else if (countryCode==="ireland") {
      this.cityName = "dublin"
    }
    else if (countryCode==="germany") {
      this.cityName = "berlin"
    }
  }

  getCountryDetails(countryId: string){
    this.countryPageService.getCountryDetails(countryId).subscribe((response)=>{
      this.countryPage = response;
    })
  }

  getDataByCountry(country: string): any[] {
    return this.eventsData.filter(item => item.Country === country);
  }

  calculateRemainingTime() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    let targetDate = new Date(currentYear, currentMonth, 21);

    if (currentDate.getDate() >= 21) {
      targetDate = new Date(currentYear, currentMonth + 1, 21);
    }

    const timeDifference = targetDate.getTime() - currentDate.getTime();

    if (timeDifference >= 0) {
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

      return { days, hours, minutes };
    } else {
      return { days: 0, hours: 0, minutes: 0 };
    }
  }
  
  ngOnDestroy() {
    localStorage.removeItem('faqCountry');
  }
}
