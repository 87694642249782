<section class="space--xs maintitle detailpage">
    <div class="container bannermiddle">
        <div class="row">
            <div class="col-md-9">
                <div class="row">
                    <div class="col-sm-2">
                        <img height="auto" width="auto" alt="image" loading="lazy" [src]="imgUrl" class="profilepic">
                    </div>
                    <div class="col-sm-10">
                        <h3>
                            {{CourseDetails.name}}
                            <span>
                                - {{CourseDetails.code}}
                            </span>
                        </h3>
                        <div class="counts"><i class="fa fa-map-marker"></i>
                            {{CourseDetails.address}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="row mt-4">
                    <div class="col-md-4">
                        <img height="auto" width="auto" alt="image" loading="lazy" src="assets/img/icon-askjuno.png">
                    </div>
                    <div class="col-md-4">
                        <a class="btn type--uppercase btn--primary" href="/apply-to-abroad-university">
                            <span class="btn__text">
                                Apply
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <hr>
        </div>
    </div>
    <!--end of container-->
</section>



<section class="bg--secondary space--xs">
    <div class="container">
        <div class="row">
            <div class="form-group col-sm-2 light-table-filter pl-0 pr-0">
                <select name="state" id="maxRows" (change)="onChange($event)">
                    <option value="1000">Show All</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                </select>
            </div>
            <div class="col-sm-8"></div>
            <input type="search" (keyup)="onSearch($event)" class="light-table-filter col-sm-2 float-end" data-table="order-table"
                placeholder="search" autocomplete="off">
            <table class="">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Course</th>
                        <th>Duration</th>
                        <th>Toefl</th>
                        <th>Ielts</th>
                        <th>Intake</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let list of LimitedCourseTableList | paginate: { itemsPerPage: pageSize, currentPage: p }; let i = index"
                        [attr.data-index]="i">
                        <td>{{i+1}}</td>
                        <td>{{list.name}}</td>
                        <td>{{list.meta_data[0].duration}}</td>
                        <td></td>
                        <td></td>
                        <td>{{list.meta_data[0].intake}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <pagination-controls  [autoHide]="autoHide" [responsive]="responsive"
                    (pageChange)="p = $event" class="float-right" [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"></pagination-controls><div class="text-bold float-right" *ngIf="pageSize == 1000">{{LimitedCourseTableList.length}} results</div>
            </div>
        </div>
    </div>
</section>