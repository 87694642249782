// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  LMS: 'https://app.meetuniversity.com/',
  V1: 'https://search.meetuniversity.com:8000/v1/',
  imgUrl: 'https://mu-assets.s3.amazonaws.com/new/brand/univ/',
  accessKeyId:'AKIAUGGXEDGSS35INQPK',
  secretAccessKey:'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',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
