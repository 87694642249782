<section class="bg--secondary space--xs maintitle">
  <div class="container">
    <div class="row">
      <div class="col-md-5">
        <h3 (click)="openModal(1)">{{ expertList.length }} Experts found</h3>
      </div>
      <hr />
    </div>
  </div>
</section>


<section class="bg--secondary space--xs">
    <div class="container">
        <div class="row">
          
            <div class="col-md-6" *ngFor="let item of expertList">
                <div class=" row feature feature-2 boxed boxed--border expertlist">
                    <div class="col-sm-3">
                        <img 
                        height="auto"
                        width="auto"
                        alt="image"
                        loading="lazy"
                        [src]="getImageLisk(item.avatar, 'profile')"
                        class="profilepic"></div>
                    <div class="col-sm-9">
                        <div class="profile-top">
                            <div class="profile-top-left">
                                <h4 class="mb-2">
                                    <b>{{ item.first_name }} {{ item.last_name }}</b>
                                  </h4>
                                  <p>
                                    Study Abroad Expert<br />
                                    <i class="fa fa-star orangecolor"></i
                                    ><i class="fa fa-star orangecolor"></i
                                    ><i class="fa fa-star orangecolor"></i
                                    ><i class="fa fa-star-half-o orangecolor"></i
                                    ><i class="fa fa-star"></i>
                                  </p>
                            </div>
                            <div class="profile-top-right">
                                <img  height="auto"
                                width="auto"
                                alt="image"
                                loading="lazy"
                                class="logo logo-dark"
                                [src]="getImageLisk(item.company_logo, 'logo')"></div>
                        </div>
                        <div class="profile-bottom">
                            <div class="profile-bottom-left">
                                <em
                                >{{ item.univ_name }}<br />
                                Last Active: Available Now</em
                              >
                            </div>
                            <div class="profile-bottom-right consultation">Consultation:<br>
                                <b class="bluecolor">Free</b>
                            </div>
                        </div>
                        <div class="button-secttion" >
                            <a class="btn greenbtn btn-sm"  (click)="openModal(item.id)">
                                <span class="btn__text greenbtn" >BOOK APPOINTMENT</span>
                            </a>
                            <a target="_blank"
                            href="https://app.meetuniversity.com/expert/#/video?call=1088560&id=1088"
                            class="btn livebtn"
                          >
                                LIVE NOW
                            </a>
                        </div>
                    </div>
                </div>
                <!--end feature-->
            </div>
        </div>
    
 </div>
</section>