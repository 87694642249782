import { Component, OnInit } from '@angular/core';
import { TimelineService } from '../../services/timeline.service';
import { CountryList } from '../../interfaces/timeline';
import { ActivatedRoute, Router  } from '@angular/router';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {
  CountryList: CountryList[] = [];
  IntakeList=[];
  constructor(private timelineService: TimelineService,private router: Router) { }

  ngOnInit(): void {
    this.getCountry();
    this.getIntake();
  }

  getCountry() {
    this.timelineService.getCountry('country').subscribe((Res:any) => {
      console.log(Res)
      this.CountryList=Res.response
      console.log(this.CountryList);
    });
  }
  getIntake() {
    var json={
      country:'55',
      type:'intake',
    }
    this.timelineService.getIntake(json).subscribe((Res:any) => {
      console.log(Res)
      this.IntakeList=Res.response
    });
  }
}
