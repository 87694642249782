import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Base } from '../interfaces/base';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }),
  };

  constructor(private httpClient: HttpClient) {}

  get(endpoint: string): Observable<any> {
    return this.httpClient
      .get<any>(`${endpoint}`)
      .pipe(retry(2), catchError(this.handleError));
  }
  getById(id: number, endpoint: string): Observable<any> {
    return this.httpClient
      .get<any>(`${endpoint}/${id}`)
      .pipe(retry(2), catchError(this.handleError));
  }
  create(item: any, endpoint: string): Observable<any> {
    return this.httpClient
      .post<any>(`${endpoint}`, item, this.httpOptions)
      .pipe(retry(2), catchError(this.handleError));
  }

  createvalidate(item: any, endpoint: string): Observable<any> {


    return this.httpClient
      .post(`${endpoint}`, item, {
        headers: { 'Content-Type': 'text/plain' },
        observe: 'response',
        responseType: 'text'
      })
      .pipe(retry(2), catchError(this.handleError));
  }

  update(item: any, endpoint: string): Observable<any> {
    return this.httpClient
      .put<any>(`${endpoint}`, JSON.stringify(item), this.httpOptions)
      .pipe(retry(2), catchError(this.handleError));
  }

  delete(item: any, endpoint: string) {
    return this.httpClient
      .delete<any>(`$${endpoint}/${item.id}`, this.httpOptions)
      .pipe(retry(2), catchError(this.handleError));
  }

  // getData(endpoint: string): Observable<any> {
  //   return this.httpClient
  //     .get<any>(`${environment.url2}/${endpoint}`)
  //     .pipe(retry(2), catchError(this.handleError));
  // }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {
      //error client
      errorMessage = error.error.message;
    } else {
      //error server
      errorMessage = `error: ${error.status}, message: ${error.message}`;
    }

    return throwError(errorMessage);
  }
}
