import { Component, OnInit } from '@angular/core';
import { FooterData } from '../../interfaces/footerData';
import { FooterService } from '../../services/footer.service';


@Component({
  selector: 'app-public-footer',
  templateUrl: './public-footer.component.html',
  styleUrls: ['./public-footer.component.scss'],
})
export class PublicFooterComponent implements OnInit {
  footerData: FooterData;
  constructor(private footerService: FooterService) {}

  ngOnInit(): void {
    this.footerService
      .getStudentCounselerCount()
      .subscribe((response) => {
        this.footerData = response.response;
      });
  }
}
