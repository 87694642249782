import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private _rouer: Router,private notifyService: NotificationService) { }

  ngOnInit(): void {
  }

  searchByKeyWord(val: any) {
    if (val) {
      this._rouer.navigate(["/search"], { queryParams: {q:val} });
    } else {
      this.notifyService.showWarning('Please enter text to search.');
    }
  }

}
