import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services/api.service';
import { apiUrl } from '../../../config/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AskExpertService {
  constructor(private ApiService: ApiService) {}

  OtpGenerateUrl = apiUrl.get_otp_url;
  OtpVerifyUrl = apiUrl.get_verify_url;
  PostFormDataUrl = apiUrl.validate_data;

  getOtp(params: any): Observable<any> {
    return this.ApiService.get(
      this.OtpGenerateUrl +
        '&phone=' +
        params.phone +
        '&name=' +
        params.name +
        '&email=' +
        params.email
    );
  }

  getVerifyOtp(params: any): Observable<any> {
    return this.ApiService.get(
      this.OtpVerifyUrl +
        '&phone=' +
        params.phone +
        '&code=' +
        params.code +
        '&name=' +
        params.name +
        '&email=' +
        params.email
    );
  }

  postFormData(body: any): Observable<any> {
    return this.ApiService.createvalidate(body, this.PostFormDataUrl);
  }
}
