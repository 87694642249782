<section class="bar bar-3 bar--sm bg--secondary">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                
            </div>
            <div class="col-lg-6 text-right text-left-xs text-left-sm">
                <div class="bar__module">
                    <ul class="menu-horizontal">
                        <li>
                            <a routerLink='/expert-list' routerLinkActive="active">
                                Book Appointment
                            </a>
                        </li>
                        <li>
                            <!-- <a routerLink='/blog' routerLinkActive="active"> commented by Ritu
                                Blog
                            </a> -->
                        </li>
                        <li>
                            <a href="https://www.meetuniversity.com/video" target="_blank">       
                            Video
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!--end of row-->
    </div>
    <!--end of container-->
</section>
<!--end bar-->

<div class="nav-container ">
    <div class="bar bar--sm visible-xs">
        <div class="container">
            <div class="row">
                <div class="col-3 col-md-2">
                    <a routerLink="home">
                        <img class="logo logo-dark" alt="logo" src="assets/img/logo-dark.webp" />
                        <img class="logo logo-light" alt="logo" src="assets/img/logo-dark.webp" />
                    </a>
                </div>
                <div class="col-9 col-md-10 text-right">
                    <a href="#" class="hamburger-toggle" data-toggle-class="#menu2;hidden-xs">
                        <i class="icon icon--sm stack-interface stack-menu"></i>
                    </a>
                </div>
            </div>
            <!--end of row-->
        </div>
        <!--end of container-->
    </div>
    <!--end bar-->
    <nav id="menu2" class="bar bar-2 hidden-xs bar--transparent bar--absolute" data-scroll-class='100vh:pos-fixed'>
        <div class="container">
            <div class="row">
                <div class="col-lg-1 col-md-2 hidden-xs">
                    <div class="bar__module">
                        <a routerLink="home">
                            <img class="logo logo-dark" alt="logo" src="assets/img/logo-dark.webp" />
                            <img class="logo logo-light" alt="logo" src="assets/img/logo-dark.webp" />
                        </a>
                    </div>
                    <!--end module-->
                </div>
                <div class="col-lg-11 col-md-12 text-right text-left-xs text-left-sm">
                    <div class="bar__module">
                        <ul class="menu-horizontal text-left">
                            <li class="dropdown dropdown--hover">
                                <span class="dropdown__trigger">APPLICATION PROCESS</span>
                                <div class="dropdown__container">
                                    <div class="container">
                                        <div class="row">
                                            <div class="dropdown__content dropdown__content--lg col-lg-8">
                                                <div class="pos-absolute col-lg-4 imagebg hidden-sm hidden-xs" data-overlay="6">
                                                    <div class="background-image-holder" style=" opacity: 1;">
                                                        <img alt="background" src="assets/img/inner-6.webp" />
                                                    </div>
                                                    <div class="container pos-vertical-center pl-5">
                                                        <div class="row">
                                                            <div class="col-lg-10">
                                                                <img routerLink="/home" alt="Logo" src="assets/img/logo-dark.webp" class="image--xs cursor" />
                                                                <span routerLink="/home" class="h3 color--white cursor">Get Professional Assitance</span>
                                                            </div>
                                                        </div>
                                                        <!--end of row-->
                                                    </div>
                                                </div>
                                                <div class="row justify-content-end">
                                                    <div class="col-lg-8">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <ul class="menu-vertical">
                                                                    <li>
                                                                           <h5>Study Abroad Language Exams</h5> 
                                                                    </li>
                                                                    <li>
                                                                        <a href="#" routerLink='/exam/toefl' routerLinkActive="active">
                                                                            Toefl
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="#" routerLink='/exam/ielts' routerLinkActive="active">
                                                                            Ielts
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="#" routerLink='/exam/pte' routerLinkActive="active">
                                                                            PTE
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                       
                                                                            <h5>Aptitude Exams</h5>
                                                                      
                                                                    </li>
                                                                    <li>
                                                                        <a href="#" routerLink='/exam/gre' routerLinkActive="active">
                                                                            GRE
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="#" routerLink='/exam/gmat' routerLinkActive="active">
                                                                            GMAT
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="#" routerLink='/exam/sat' routerLinkActive="active">
                                                                            SAT
                                                                        </a>
                                                                    </li>
                                                                  
                                                                </ul>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <ul class="menu-vertical">
                                                                    <li>
                                                                        <h5  >Study Abroad Application Writing</h5>
                                                                    </li>
                                                                    <li>
                                                                        <a href="#" routerLink='/services/statement-of-purpose-sop' routerLinkActive="active">
                                                                            Statement of purpose (SOP)
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="#" routerLink='/services/letter-of-recommendation-lor' routerLinkActive="active">
                                                                            Letter of recommendation (LOR)
                                                                        </a>
                                                                    </li>
                                                                  
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <!--end of row-->
                                                    </div>
                                                    <!--end of col-->
                                                </div>
                                                <!--end of row-->
                                            </div>
                                            <!--end dropdown content-->
                                        </div>
                                        <!--end row-->
                                    </div>
                                    <!--end container-->
                                </div>
                                <!--end dropdown container-->
                            </li>
                            <li class="dropdown dropdown--hover">
                                <span class="dropdown__trigger" routerLink='/disciplines' routerLinkActive="active">DISCIPLINES</span>
                                <div class="dropdown__container">
                                    <div class="container">
                                        <div class="row">
                                            <div class="dropdown__content dropdown__content--lg col-lg-6">
                                                <div class="pos-absolute col-lg-4 imagebg hidden-sm hidden-xs" data-overlay="6">
                                                    <div class="background-image-holder" style="background: url(&quot;assets/img/inner-6.webp&quot;); opacity: 1;">
                                                        <img alt="background" src="assets/img/inner-6.webp" />
                                                    </div>
                                                    <div class="container pos-vertical-center pl-5">
                                                        <div class="row">
                                                            <div class="col-lg-10">
                                                                <img routerLink="/home" alt="Logo" src="assets/img/logo-dark.webp" class="image--xs cursor" />
                                                                <span routerLink="/home" class="h3 color--white cursor">Pick from Desciplines</span>
                                                            </div>
                                                        </div>
                                                        <!--end of row-->
                                                    </div>
                                                </div>
                                                <div class="row justify-content-end">
                                                    <div class="col-lg-8">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <ul class="menu-vertical">
                                                                   
                                                                    <li *ngFor="let list of DisciplineList">
                                                                        <a href="javascript:void(0)" (click)="reachedInnerPage(list.name)">
                                                                            {{ list.name }}
                                                                        </a>
                                                                    </li>
                                                                  
                                                                </ul>
                                                            </div>
                                                            
                                                        </div>
                                                        <!--end of row-->
                                                    </div>
                                                    <!--end of col-->
                                                </div>
                                                <!--end of row-->
                                            </div>
                                            <!--end dropdown content-->
                                        </div>
                                        <!--end row-->
                                    </div>
                                    <!--end container-->
                                </div>
                                <!--end dropdown container-->
                            </li>
                            
                    
                        </ul>
                    </div>
                    <!--end module-->
                    <div class="bar__module">
                        <a class="btn btn--sm type--uppercase" routerLink='/ask-expert' routerLinkActive="active">
                            <span class="btn__text">
                                Ask @Expert
                            </span>
                        </a>
                    </div>
                    <!--end module-->
                </div>
            </div>
            <!--end of row-->
        </div>
        <!--end of container-->
    </nav>
    <!--end bar-->
</div>