<section class="imagebg height-100 pt-0" data-overlay="2">
  <div class="background-image-holder" style="background: url(&quot;assets/img/application.jpg&quot;); opacity: 1;">
    <img alt="background" src="assets/img/application.jpg">
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-xs-12">
        <div class="logo" routerLink="">
          <img src="assets/img/logo-light.png" />
        </div>

      </div>
      <div class="col-lg-6 col-md-6 col-xs-12 text-right">
        <button routerLink="/contact" class="btn btn-outline-light mt-5">Contact Us</button>

      </div>

    </div>
  </div>
  <div class="container pb-2">
    <div class="row justify-content-between align-items-center backgroundtestimonial pt-3 pb-3">
      <div class="col-lg-6 col-md-7">
        <div class="section-slider">
          <div class="p-5">
            <h2 class="mt-3"> Apply to 750+ Universities. </h2>
            <p class="lead text-white"> One form, Multiple Universities. </p>
          </div>
          <hr>
          <carousel [interval]="4500">
            <slide>
              <div class="row">
                <div class="testimonial">
                  <div class="col-md-12">
                    <p>
                      “I wanted to pursue a degree which could help me get the
                      relevant expertise for my real estate business, I was able
                      to search for the course of my choice, connect with expert
                      counsellors &amp; take a wise decision. The webinars &amp;
                      direct connect feature was really helpful.”
                    </p>
                    <h5>- Muhammad Shahzaib</h5>
                  </div>
                </div>
              </div>
            </slide>
            <slide>
              <div class="row">
                <div class="testimonial">
                  <div class="col-md-12">
                    <p>
                      “The counsellors helped me shortlist universities
                      according to my interests. With the ability to engage
                      &amp; speak to universities directly, I was more confident
                      of the right university to choose.”
                    </p>
                    <h5>- Arijit Banerjee</h5>
                    <!-- <span>Interface Designer &mdash; Yoke</span> -->
                  </div>
                </div>
              </div>
            </slide>
            <slide>
              <div class="row">
                <div class="testimonial">
                  <div class="col-md-12">
                    <p>
                      “I would like to thank you for such a significant SOP. It
                      is absolutely great. I'll be needing your help in proof
                      reading in coming future, though. I enjoyed working with
                      you.”
                    </p>
                    <h5>- Shreshtha Srivastav</h5>
                    <!-- <span>Interface Designer &mdash; Yoke</span> -->
                  </div>
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
      <div class="col-md-6">
        <div class="boxed boxed--lg bg--white boxed--border boxed-form">
          <div class="form-section">
            <h4 class="text-bold text-center">Connect with Universities & Study Abroad Experts Instantly !</h4>

            <form class="form row mx-0" [formGroup]="askExpert" (ngSubmit)="onSubmit()" *ngIf="views == 1">
              <div class="col-md-12 col-12">
                <input type="text" placeholder="Your Name" name="name" formControlName="full_name" [ngClass]="{
                            'is-invalid field-error':
                              submitted && p.full_name.errors
                          }" />
                <div *ngIf="submitted && p.full_name.errors" class="invalid-feedback">
                  <div *ngIf="p.full_name.errors.required">
                    full name is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-6">
                <input type="email" formControlName="email_address" name="email" placeholder="Email Address" [ngClass]="{
                        'is-invalid field-error':
                          submitted && p.email_address.errors
                      }" />
                <div *ngIf="submitted && p.email_address.errors" class="invalid-feedback">
                  <div *ngIf="p.email_address.errors.required">
                    Email is required.
                  </div>
                  <div *ngIf="p.email_address.errors.email">
                    Please enter valid email.
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <input placeholder="Phone Number" formControlName="phone_number" type="number" name="email" [ngClass]="{
                        'is-invalid field-error':
                          submitted && p.phone_number.errors 
                      }" />
                <div *ngIf="submitted && p.phone_number.errors" class="invalid-feedback">
                  <div *ngIf="p.phone_number.errors.required">
                    Phone number is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <input type="text" placeholder="Last Course" formControlName="last_course" [ngClass]="{
                        'is-invalid field-error':
                          submitted && p.last_course.errors 
                      }" name="email" />
                <div *ngIf="submitted && p.last_course.errors" class="invalid-feedback">
                  <div *ngIf="p.last_course.errors.required">
                    Last course is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-6">
                <input type="email" placeholder="Last Percentage" formControlName="last_score" [ngClass]="{
                        'is-invalid field-error':
                          submitted && p.last_score.errors 
                      }" name="email" />
                <div *ngIf="submitted && p.last_score.errors" class="invalid-feedback">
                  <div *ngIf="p.last_score.errors.required">
                    Last percentage is required.
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="input-select">
                  
                  <select name="course" id="course" formControlName="course" [ngClass]="{
                          'field-error':
                            submitted && p.course.errors 
                        }">
                    <option value="" selected="true" disabled="disabled">Interested Course</option>
                    
                    <option value="Accountancy">Accountancy</option>
                    <option value="Accounting &amp; Finance">
                      Accounting &amp; Finance
                    </option>
                    <option value="Agricultural Sciences">
                      Agricultural Sciences
                    </option>
                    <option value="Animation">Animation</option>
                    <option value="Applied Sciences">Applied Sciences</option>
                    <option value="Architecture">Architecture</option>
                    <option value="BBA">BBA</option>
                    <option value="Biology">Biology</option>
                    <option value="Biomedical Science">Biomedical Science</option>
                    <option value="Biotechnology">Biotechnology</option>
                    <option value="Business and Economics">
                      Business and Economics
                    </option>
                    <option value="Business and Entrepreneurship">
                      Business and Entrepreneurship
                    </option>
                    <option value="Business and Finance">
                      Business and Finance
                    </option>
                    <option value="Business Management">
                      Business Management
                    </option>
                    <option value="Chemical Engineering">
                      Chemical Engineering
                    </option>
                    <option value="Civil Engineering">Civil Engineering</option>
                    <option value="Computer Science">Computer Science</option>
                    <option value="Computing">Computing</option>
                    <option value="Criminology">Criminology</option>
                    <option value="Cyber Security">Cyber Security</option>
                    <option value="Data Science &amp; Analytics">
                      Data Science &amp; Analytics
                    </option>
                    <option value="Digital Marketing">Digital Marketing</option>
                    <option value="Earth Sciences">Earth Sciences</option>
                    <option value="Economics">Economics</option>
                    <option value="Electrical Engineering">
                      Electrical Engineering
                    </option>
                    <option value="Electrical-Electronics">
                      Electrical-Electronics
                    </option>
                    <option value="Electronics &amp; Communication">
                      Electronics &amp; Communication
                    </option>
                    <option value="Energy Engineering">Energy Engineering</option>
                    <option value="Engineering Management">
                      Engineering Management
                    </option>
                    <option value="Entrepreneurship">Entrepreneurship</option>
                    <option value="Fashion Design">Fashion Design</option>
                    <option value="Finance">Finance</option>
                    <option value="Geology">Geology</option>
                    <option value="Health Sciences">Health Sciences</option>
                    <option value="Hospitality Management">
                      Hospitality Management
                    </option>
                    <option value="Information Technology">
                      Information Technology
                    </option>
                    <option value="Interior Design">Interior Design</option>
                    <option value="International Business">
                      International Business
                    </option>
                    <option value="Journalism &amp; Mass Communication">
                      Journalism &amp; Mass Communication
                    </option>
                    <option value="LLB">LLB</option>
                    <option value="LLM">LLM</option>
                    <option value="MBA">MBA</option>
                    <option value="MBBS">MBBS</option>
                    <option value="Media Science">Media Science</option>
                    <option value="Oil &amp; Gas / Petroleum Engineering">
                      Oil &amp; Gas / Petroleum Engineering
                    </option>
                    <option value="Pharmacy">Pharmacy</option>
                    <option value="Renewable Energy">Renewable Energy</option>
                    <option value="Software Engineering">
                      Software Engineering
                    </option>
                    <option value="Sustainable Development">
                      Sustainable Development
                    </option>
                    <option value="Textile Engineering">
                      Textile Engineering
                    </option>
                    <option value="Others">Others</option>
                  </select>
                  <div *ngIf="submitted && p.course.errors" class="invalid-feedback">
                    <div *ngIf="p.course.errors.required">
                      Course is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="input-select">
                  <select name="city" id="city" formControlName="city" [ngClass]="{
                          'is-invalid field-error':
                            submitted && p.city.errors 
                        }">
                    <option value="" selected="true" disabled="disabled">
                      City
                    </option>
                    <option value="Delhi">Delhi</option>
                    <option value="Mumbai - Bandra">Mumbai - Bandra</option>
                    <option value="Mumbai - Thane">Mumbai - Thane</option>
                    <option value="Mumbai - Vashi">Mumbai - Vashi</option>
                    <option value="Mumbai">Mumbai</option>
                    <option value="Bangalore">Bangalore</option>
                    <option value="Chennai">Chennai</option>
                    <option value="Hyderabad">Hyderabad</option>
                    <option value="Pune">Pune</option>
                    <option value="Coimbatore">Coimbatore</option>
                    <option value="Kochi">Kochi</option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Ludhiana">Ludhiana</option>
                    <option value="Amritsar">Amritsar</option>
                    <option value="Jalandhar">Jalandhar</option>
                    <option value="Vizag">Vizag</option>
                    <option value="Ahmedabad">Ahmedabad</option>
                    <option value="Lucknow">Lucknow</option>
                  </select>
                  <div *ngIf="submitted && p.city.errors" class="invalid-feedback">
                    <div *ngIf="p.city.errors.required">
                      City is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="input-select">
                  <select name="checkedLoan" id="checkedLoan" formControlName="checkedLoan" [ngClass]="{
                          'field-error':
                            submitted && p.checkedLoan.errors 
                        }">
                    <option value="" selected="true" disabled="disabled">
                      Funds
                    </option>
                    <option value="yes">Loan</option>
                    <option value="no">Self</option>
                  </select>
                  <div *ngIf="submitted && p.checkedLoan.errors" class="invalid-feedback">
                    <div *ngIf="p.checkedLoan.errors.required">
                      Checke Loan is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-center">
                <div class="row ml-3">
                  <div class="col" *ngFor="let choice of checks; let i=index">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="{{choice.description}}" name="country[]" (change)="onCheckChange($event)" [value]="choice.value" required>
                      <label class="custom-control-label" for="{{choice.description}}">{{choice.description}}</label>
                      <div *ngIf="submitted && p.country.errors" class="invalid-feedback">
                        <div *ngIf="p.country.errors.required">
                          Country is required.
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
              </div>
              <div class="col-md-12">
                <button type="submit" class="btn btn--primary w-100 type--uppercase">
                  Send Enquiry
                </button>
              </div>
            </form>
            <form [formGroup]="OTPVerifyForm" (ngSubmit)="verifyOTP()">
              <div class="row" *ngIf="views == 2">
                <div class="col-sm-1"></div>
                <div class="col-sm-10 pl-4">
                  <p class="text-black">
                    {{otpSendStatus}}
                  </p>
                  <div class="font-weight-bold text-bold mb-3">Please enter the one time password sent to your mobile
                  </div>
                  <input type="text" placeholder="e.g.54786" formControlName="code" class="w-50" [ngClass]="{
                          'is-invalid field-error':
                          applied && o.code.errors 
                        }">
                  <div *ngIf="applied && o.code.errors" class="invalid-feedback">
                    <div *ngIf="o.code.errors.required">
                      Token is required.
                    </div>
                  </div>
                  <p>Didn't get one? <a class="link" (click)="sendOtp()">Resend OTP</a></p>
                  <p>Verify with a different mobile number? <a class="link" (click)="views = 1">Change Mobile Number</a>
                  </p>
                  <button type="submit" class="btn btn--primary w-100 type--uppercase">
                    CONFIRM
                  </button>
                </div>
                <div class="col-sm-1"></div>
              </div>
            </form>
            <p class="lead text-center" *ngIf="views == 3">
              Thank you for contacting us, We will get in touch soon!
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--end of row-->
  </div>
  <!--end of container-->
</section>