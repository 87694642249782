import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonApplicationService } from '../../services/common-application.service';
import {
  CommonApplicationOtp,
  CommonApplicationOtpVerify,
} from '../../interfaces/common-application';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
  FormArray,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-common-application-form',
  templateUrl: './common-application-form.component.html',
  styleUrls: ['./common-application-form.component.scss'],
})
export class CommonApplicationFormComponent implements OnInit {
  token: string;
  phone: number;
  name: string;
  leadId: string;
  OtpJson = {} as CommonApplicationOtp;
  OtpVerifyJson = {} as CommonApplicationOtpVerify;
  otpForm: FormGroup;
  submitted: boolean = false;
  lastFourCharacter: any;
  otpSendStatus:string;
  showChangePhone:boolean = false;

  constructor(
    private notifyService : NotificationService,
    private fb: FormBuilder,
    private CommonApplicationService: CommonApplicationService,
    private route: ActivatedRoute,
    private router: Router,
    private authService : AuthService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.token = params['token'];
    });

    this.otpForm = this.fb.group({
      code: ['', [Validators.required]],
    });
    this.getDetails();
  }

  get o() {
    return this.otpForm.controls;
  }

  getDetails() {
    const json = {
      token: this.token,
    };
    this.CommonApplicationService.getData(json).subscribe((response) => {
      this.phone = response.result.phone;
      this.name = response.result.name;
      this.leadId = response.result.id;
      this.sendOtp(response.result.phone, response.result.name);
    });
  }

  sendOtp(phone: any, name: string) {
    this.OtpJson = {
      token: this.token,
      phone: phone,
    };
    this.CommonApplicationService.sendOtp(this.OtpJson).subscribe(
      async (response: any) => {
        if (response == 'ok') {
          this.otpSendStatus = `Hi ${name} ! We have sent you OTP on XXXXXX${phone.substr(-4)}`;
          this.notifyService.showSuccess(this.otpSendStatus);
        }
      }
    );
  }

  onConfirm() {
    // this.authService.setToken("abcdefghijklmnopqrstuvwxyz", "865511");
    // this.router.navigate(['admin/preferences']);
    if (this.otpForm.status == 'INVALID') {
      this.submitted = true;
    }

    if (this.otpForm.status == 'VALID') {
      this.OtpVerifyJson = {
        token: this.token,
        code: this.otpForm.value.code,
      };

      this.CommonApplicationService.verifyOtp(this.OtpVerifyJson).subscribe(
        async (response: any) => {
          if (response == 'Verified') {
            this.notifyService.showSuccess('Your otp is  verified successfully');
            this.authService.setToken("abcdefghijklmnopqrstuvwxyz", this.leadId);
            this.router.navigate(['admin/preferences']);
          } else {
            this.notifyService.showError('Your otp does not match or verified');
          }
        },(error)=>{
          this.notifyService.showError('Your otp does not match or verified');
        }
      );
    }
  }

  onShowPhone(){
    this.showChangePhone = !this.showChangePhone;
  }

  onChangePhone(newphone: string){
    if(newphone ==""){
      this.notifyService.showError("Please fill new phone number.")
      return;
    }

    const json = {
      token: this.token,
      new_phone: newphone
    };
    this.CommonApplicationService.onChangePhone(json).subscribe((response) => {
      if(response ){
        this.notifyService.showSuccess("Our representative will get in touch soon!");
        this.showChangePhone = false;
      }
    },(error)=>{
      this.notifyService.showError(error);
    });
  }
}
