<section class="imagebg height-100 pt-0" data-overlay="2">
  <div class="background-image-holder" style="background: url('assets/img/application.jpg'); opacity: 1">
    <img alt="background" src="assets/img/application.jpg" />
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-xs-12">
        <div class="logo" routerLink="">
          <img src="assets/img/logo-light.png" />
        </div>

      </div>
      <div class="col-lg-6 col-md-6 col-xs-12 text-right">
        <button routerLink="/contact" class="btn btn-outline-light mt-5">Contact Us</button>

      </div>

    </div>
  </div>

  <div class="container backgroundblue">
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="section-slider">
          <div class="p-5">
            <h2 class="mt-5"> Apply to 750+ Universities. </h2>
            <p class="lead text-white"> One form, Multiple Universities. </p>
          </div>
          <hr>
          <carousel [interval]="4500">
            <slide>
              <div class="row">
                <div class="testimonial">
                  <div class="col-md-12">
                    <p>
                      “I wanted to pursue a degree which could help me get the
                      relevant expertise for my real estate business, I was able
                      to search for the course of my choice, connect with expert
                      counsellors &amp; take a wise decision. The webinars &amp;
                      direct connect feature was really helpful.”
                    </p>
                    <h5>- Muhammad Shahzaib</h5>
                  </div>
                </div>
              </div>
            </slide>
            <slide>
              <div class="row">
                <div class="testimonial">
                  <div class="col-md-12">
                    <p>
                      “The counsellors helped me shortlist universities
                      according to my interests. With the ability to engage
                      &amp; speak to universities directly, I was more confident
                      of the right university to choose.”
                    </p>
                    <h5>- Arijit Banerjee</h5>
                    <!-- <span>Interface Designer &mdash; Yoke</span> -->
                  </div>
                </div>
              </div>
            </slide>
            <slide>
              <div class="row">
                <div class="testimonial">
                  <div class="col-md-12">
                    <p>
                      “I would like to thank you for such a significant SOP. It
                      is absolutely great. I'll be needing your help in proof
                      reading in coming future, though. I enjoyed working with
                      you.”
                    </p>
                    <h5>- Shreshtha Srivastav</h5>
                    <!-- <span>Interface Designer &mdash; Yoke</span> -->
                  </div>
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
      
      <div class="col-md-6 col-lg-6 align-self-center">
        <div class="boxed boxed--lg boxed--border bg-white boxed-form">
          <h4 class="text-black">
            With MeetUniversity it’s simple to apply to various universities
            with a single form
          </h4>
          <p class="text-black">
            {{otpSendStatus}}
          </p>
          <form [formGroup]="otpForm" (ngSubmit)="onConfirm()">
            <div class="row">
              <div class="col-12">
                <input type="text" name="Enter OTP" placeholder="Enter OTP" formControlName="code" class="w-50" />
                <div *ngIf="submitted && o.code.errors" class="invalid-feedback">
                  <div *ngIf="o.code.errors.required">OTP is required.</div>
                </div>
              </div>
              <div class="col-12">
                <button type="submit" class="btn btn--primary type--uppercase w-50">
                  Confirm
                </button>
              </div>
              <div class="col-12">
                <a href="javascript: void(0);" (click)="onShowPhone()" class="text-black">Change Phone </a>
                <label> | </label>
                <a href="javascript: void(0);" (click)="sendOtp(phone,name)" class="text-black"> Resend OTP</a>
              </div>
              <div class="col-12" *ngIf="showChangePhone">
                <input type="text" name="New Phone" placeholder="New Phone" class="w-50" #newphone />
                <button type="button" class="btn btn--primary type--uppercase mt-0"
                  (click)="onChangePhone(newphone.value)">Change</button>
              </div>
            </div>
            <!--end row-->
          </form>
        </div>
      </div>
    </div>
    <!--end of row-->
  </div>
  <!--end of container-->
</section>

<!-- 
      
<div class="switchable__text">
  <div id="verifyy" role="form" class="text-left verify col-md-12 mt-5">
    <input type="hidden" name="step" value="4" />
    <h4>
      With MeetUniversity it’s simple to apply to various universities
      with a single form
    </h4>
    <form class="form row mx-0" [formGroup]="otpForm" (ngSubmit)="otpVerification()">
      <div class="col-md-6">
        <input [ngClass]="{
            'is-invalid field-error': submitted && o.code.errors
          }" type="hidden" id="code" name="code" />

        <label>We have sent you otp on xxxxxx{{ lastFourCharacter }}</label>
        <input type="text" id="otp" formControlName="code" name="otp" placeholder="Enter OTP" class="" />
        <div *ngIf="submitted && o.code.errors" class="invalid-feedback">
          <div *ngIf="o.code.errors.required">Otp is required.</div>
        </div>
        <br />
        <label>
          <span onclick="phone_change();">
            <a rel="noopener" href="#"></a>

          </span>
          |
          <span onclick="regenOtp();">
            <a rel="noopener" href="#">Resend OTP</a>
          </span>
        </label>
      </div>
      <div class="col-md-6">
        <p id="otp_error" style="
            color: red;
            font-size: small;
            padding: 10px;
            display: none;
          ">
          Invalid OTP
        </p>
      </div>
      <div class="col-md-6">
        <button type="submit" name="verify" class="btn btn--primary type--uppercase"
          style="width: 100%; margin-top: 15px">
          Confirm
        </button>
      </div>
    </form>
  </div>
</div> -->