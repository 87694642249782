import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import {apiUrl} from "../../../config/config"

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(private apiService: ApiService) { }

  getFaq(q: string = 'general'){
    return this.apiService.get(`${apiUrl.search_tags}?q=${q}&content=1,2,3&size=6`);
  }
}
