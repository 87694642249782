import { Options } from '@angular-slider/ngx-slider';
import { Component, OnInit,ViewChild } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';



@Component({
  selector: 'app-ask-juno',
  templateUrl: './ask-juno.component.html',
  styleUrls: ['./ask-juno.component.scss']
})
export class AskJunoComponent implements OnInit {
  @ViewChild('staticTabs', { static: false }) staticTabs?: TabsetComponent;
  value: number = 10;
  options: Options = {
    floor: 10,
    ceil: 100,
    step:10
  };
  value1: number = 10;
  options1: Options = {
    floor: 10,
    ceil: 100,
    step:10
  };
 
  selectTab(tabId: number) {
    if (this.staticTabs?.tabs[tabId]) {
      this.staticTabs.tabs[tabId].active = true;
    }
  }

  constructor() { }

  ngOnInit(): void {
   
  }

}
