import { Component, OnInit } from '@angular/core';
import { CourseService } from '../../services/course.service';
import { CourseDetails, CourseTableList } from '../../interfaces/course-details';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {
  public CourseDetails = {} as CourseDetails;
  public CourseTableList: CourseTableList[] = [];
  public LimitedCourseTableList = [] as CourseTableList[];
  public count: any;
  public imgUrl: any;
  p: number = 1;
  pageSize:number=1000;
  public autoHide: boolean = true;
  public responsive: boolean = false;
  public labels: any = {
    previousLabel: '',
    nextLabel: '',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`
};

  constructor(private courseService: CourseService) { }

  ngOnInit(): void {
    this.getCourseDetailUrl();
    this.getCourseTable();
  }

  getCourseDetailUrl() {
    this.courseService.getCourseDetailUrl().subscribe(async (json) => {
      this.CourseDetails = await json;
      this.imgUrl = environment.imgUrl + this.CourseDetails.img;
    });
  }

  getCourseTable() {
    this.courseService.getCourseTable().subscribe(async (json) => {
      this.CourseTableList = await json;
      this.LimitedCourseTableList =  await json;
    });
  }

  onChange(ev: any) {
    this.pageSize = ev.target.value;
  }

  // async limitFilterArray(limit: any) {
  //   this.LimitedCourseTableList = await this.CourseTableList.filter((val, i) => i < limit);
  // }

  onSearch(e:any){
    console.log(e.target.value);
    this.LimitedCourseTableList = performSearch(this.CourseTableList, e.target.value);
  }

}

const performSearch = (rows: any[], term: string) => {
  const results = rows.filter(
      (row) =>
      row.name.toLowerCase().includes(term.toLowerCase()) ||
      row.meta_data[0].duration.toLowerCase().includes(term.toLowerCase()) ||
      row.meta_data[0].intake.toLowerCase().includes(term.toLowerCase())
  );

  return results;
};
