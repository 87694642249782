<!-- <div class="container vh-100">
  <div class="row justify-content-center h-100">
    <div class="card my-auto shadow">
      <div class="card-body px-5">
        <div class="text-center">
 
        </div>
        <div class="text-center">
          <h2>Welcome</h2>
        </div>
        <p style="color: lightslategray; text-align: center">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please enter your
          login details&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </p>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <input
              type="email"
              id="email"
              placeholder="Email"
              class="form-control"
              formControlName="email"
              name="email"
            />
          </div>
          <div class="form-group">
            <input
              type="password"
              id="password"
              placeholder="Password"
              class="form-control"
              formControlName="password"
              name="password"
            />
          </div>
          <input
            type="submit"
            class="btn btn-primary w-100"
            value="Login"
            name=""
          />
        </form>
      </div>
      <div class="card-footer text-right">
        <small><a routerLink="../forgot-password">Forgot Password</a></small>
      </div>
    </div>
  </div>
</div> -->


<div class="main-container">
  <section class="height-100 imagebg text-center" data-overlay="4">
      <div class="background-image-holder">
          <img alt="background" src="assets/img/inner-6.jpg" />
      </div>
      <div class="container pos-vertical-center">
          <div class="row">
              <div class="col-md-7 col-lg-5">
                  <h2>Login to continue</h2>
                  <p class="lead">
                      Welcome back, sign in with your existing Stack account credentials
                  </p>
                  <form>
                      <div class="row">
                          <div class="col-md-12">
                              <input type="text" placeholder="Username" />
                          </div>
                          <div class="col-md-12">
                              <input type="password" placeholder="Password" />
                          </div>
                          <div class="col-md-12">
                              <button class="btn btn--primary type--uppercase" type="submit">Login</button>
                          </div>
                      </div>
                      <!--end of row-->
                  </form>
                  <span class="type--fine-print block">Dont have an account yet?
                      <a href="page-accounts-create-1.html">Create account</a>
                  </span>
                  <span class="type--fine-print block">Forgot your username or password?
                      <a href="page-accounts-recover.html">Recover account</a>
                  </span>
              </div>
          </div>
          <!--end of row-->
      </div>
      <!--end of container-->
  </section>
</div>