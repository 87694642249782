import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AskExpertListComponent } from './components/ask-expert-list/ask-expert-list.component';
import { AskJunoComponent } from './components/ask-juno/ask-juno.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { CountryPagesComponent } from './components/country-pages/country-pages.component';
import { AboutComponent } from './components/about/about.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { TermOfServiceComponent } from './components/term-of-service/term-of-service.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { PremiumServicesComponent } from './components/premium-services/premium-services.component';
import { AskJunoChatComponent } from './components/ask-juno-chat/ask-juno-chat.component';
import { UpcomingEventsComponent } from './components/upcoming-events/upcoming-events.component';
import { VideosComponent } from './components/videos/videos.component';
import { TestPrepComponent } from './components/test-prep/test-prep.component';
import { GuidePagesComponent } from './components/guide-pages/guide-pages.component';
import { SearchPageComponent } from './components/search-page/search-page.component';
import { HomeComponent } from './components/home/home.component';
import { UniversityComponent } from './components/university/university.component';
import { CoursesComponent } from './components/courses/courses.component';
import { BookingComponent } from './components/booking/booking.component';
import { DisciplinesComponent } from './components/disciplines/disciplines.component';
import { DisciplinesInnerComponent } from './components/disciplines-inner/disciplines-inner.component';
import { StatementPurposeComponent } from './components/statement-purpose/statement-purpose.component';
import { AskExpertComponent } from './components/ask-expert/ask-expert.component';
import { PreCounselComponent } from './components/pre-counsel/pre-counsel.component';
import { CommonApplicationFormComponent } from './components/common-application-form/common-application-form.component';
import { UniversityListComponent } from './components/university-list/university-list.component';
import { ScholarshipComponent } from './components/scholarship/scholarship.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'expert-list', component: AskExpertListComponent },
  //{ path: 'ask-expert', component: AskExpertComponent },
  { path: 'timeline', component: TimelineComponent },
  { path: 'signup', component: SignUpComponent },
  { path: 'study-in/:countryid', component: CountryPagesComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactUsComponent },
  { path: 'terms', component: TermOfServiceComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: 'pricing', component: PremiumServicesComponent },
  { path: 'events', component: UpcomingEventsComponent },
  { path: 'video', component: VideosComponent },
  { path: 'student-guide/:countryid', component: GuidePagesComponent },
  { path: 'search', component: SearchPageComponent },
  { path: 'university', component: UniversityComponent },
  { path: 'course', component: CoursesComponent },
  { path: 'booking', component: BookingComponent },
  { path: 'disciplines', component: DisciplinesComponent },
  { path: 'disciplines/:name', component: DisciplinesInnerComponent },
  { path: 'exam/:slug', component: StatementPurposeComponent },
  { path: 'services/:slug', component: StatementPurposeComponent },
  { path: 'top-rank-university/:country', component: UniversityListComponent },
  { path: 'economical-university/:country', component: UniversityListComponent },
  { path: 'friendly-university/:country', component: UniversityListComponent },
  { path: 'university/:country/:univname', component: UniversityComponent },
  { path: 'get-scholarship', component: ScholarshipComponent },
  // { path: 'pre-counsel/:token', component: PreCounselComponent },
  // { path: 'common-application-form/:token', component: CommonApplicationFormComponent },

  // { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicRoutingModule {}
