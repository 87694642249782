<style>

  
  input[type="date"]:before {
      content: attr(placeholder);
      color: #aaa; /* Placeholder text color */
      margin-right: 5px;
  }
  </style>

<section class="imagebg height-100 pt-0" data-overlay="2">
  <div class="background-image-holder" style="background: url(&quot;assets/img/application.jpg&quot;); opacity: 1;">
    <img alt="background" src="assets/img/application.jpg">
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-xs-12">
        <div class="logo" routerLink="">
          <img src="assets/img/logo-light.png" />
        </div>

      </div>
      <div class="col-lg-6 col-md-6 col-xs-12 text-right">
        <button routerLink="/contact" class="btn btn-outline-light mt-5">Contact Us</button>

      </div>

    </div>
  </div>
  <div class="container">
    <div class="row  backgroundtestimonial">
      <div class="col-md-6 col-12">
        <div class="section-slider">
          <div class="p-5">
            <h2 class="mt-5"> Apply to 750+ Universities. </h2>
            <p class="lead text-white"> One form, Multiple Universities. </p>
          </div>
          <hr>
          <carousel [interval]="4500">
            <slide>
              <div class="row">
                <div class="testimonial">
                  <div class="col-md-12">
                    <p>
                      “I wanted to pursue a degree which could help me get
                      the relevant expertise for my real estate business, I
                      was able to search for the course of my choice,
                      connect with expert counsellors &amp; take a wise
                      decision. The webinars &amp; direct connect feature
                      was really helpful.”
                    </p>
                    <h5>- Muhammad Shahzaib</h5>
                  </div>
                </div>
              </div>
            </slide>
            <slide>
              <div class="row">
                <div class="testimonial">
                  <div class="col-md-12">
                    <p>
                      “The counsellors helped me shortlist universities
                      according to my interests. With the ability to engage
                      &amp; speak to universities directly, I was more
                      confident of the right university to choose.”
                    </p>
                    <h5>- Arijit Banerjee</h5>
                    <!-- <span>Interface Designer &mdash; Yoke</span> -->
                  </div>
                </div>
              </div>
            </slide>
            <slide>
              <div class="row">
                <div class="testimonial">
                  <div class="col-md-12">
                    <p>
                      “I would like to thank you for such a significant SOP.
                      It is absolutely great. I'll be needing your help in
                      proof reading in coming future, though. I enjoyed
                      working with you.”
                    </p>
                    <h5>- Shreshtha Srivastav</h5>
                    <!-- <span>Interface Designer &mdash; Yoke</span> -->
                  </div>
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </div>

      <div class="col-md-6 col-lg-6 align-self-center">
        <div class="boxed boxed--lg boxed--border bg-white boxed-form ">
          <h4 class="text-black">
            With MeetUniversity it’s simple to apply to various universities
            with a single form
          </h4>

          <div *ngIf="sectionVisible == 1">
            <p class="text-black">
              {{otpSendStatus}}
            </p>
            <form [formGroup]="otpForm" (ngSubmit)="onConfirm()">
              <div class="row">
                <div class="col-12">
                  <input type="text" name="Enter OTP" placeholder="Enter OTP" formControlName="code" class="w-50" />
                  <div *ngIf="submitted && o.code.errors" class="invalid-feedback">
                    <div *ngIf="o.code.errors.required">Otp is required.</div>
                  </div>
                </div>
                <div class="col-12">
                  <button type="submit" class="btn btn--primary type--uppercase w-50">
                    Confirm
                  </button>
                </div>
                <div class="col-12">
                  <a href="javascript: void(0);" (click)="onShowPhone()" class="text-black">Change Phone </a>
                  <label class="text-black"> | </label>
                  <a href="javascript: void(0);" (click)="sendOtp(phone, name)" class="text-black"> Resend OTP</a>
                </div>
                <div class="col-12" *ngIf="showChangePhone">
                  <input type="text" name="New Phone" placeholder="New Phone" class="w-50" #newphone />
                  <button type="button" class="btn btn--primary type--uppercase mt-0"
                    (click)="onChangePhone(newphone.value)">Change</button>
                </div>
              </div>
              <!--end row-->
            </form>
          </div>

          <div *ngIf="sectionVisible == 2">
          </div>

          <div *ngIf="sectionVisible == 3">
            <div class="col-md-12">
              <h3 class="text-black">Brief Information</h3>
            </div>
            <form class="form row mx-0" [formGroup]="briefInformationForm" (ngSubmit)="saveBriefInfoForm()">
              <div class="col-md-6">
                <div class="input-select">
                  <select name="interest_course" id="interest_course" formControlName="interest_course" [ngClass]="{
              'field-error': briefinfosubmitted && b.interest_course.errors
            }">
                    <option disabled value="" selected="true">Interested Course</option>
                    <option value="Accountancy">Accountancy</option>
                    <option value="Accounting &amp; Finance">
                      Accounting &amp; Finance
                    </option>
                    <option value="Agricultural Sciences">Agricultural Sciences</option>
                    <option value="Animation">Animation</option>
                    <option value="Applied Sciences">Applied Sciences</option>
                    <option value="Architecture">Architecture</option>
                    <option value="BBA">BBA</option>
                    <option value="Biology">Biology</option>
                    <option value="Biomedical Science">Biomedical Science</option>
                    <option value="Biotechnology">Biotechnology</option>
                    <option value="Business and Economics">
                      Business and Economics
                    </option>
                    <option value="Business and Entrepreneurship">
                      Business and Entrepreneurship
                    </option>
                    <option value="Business and Finance">Business and Finance</option>
                    <option value="Business Management">Business Management</option>
                    <option value="Chemical Engineering">Chemical Engineering</option>
                    <option value="Civil Engineering">Civil Engineering</option>
                    <option value="Computer Science">Computer Science</option>
                    <option value="Computing">Computing</option>
                    <option value="Criminology">Criminology</option>
                    <option value="Cyber Security">Cyber Security</option>
                    <option value="Data Science &amp; Analytics">
                      Data Science &amp; Analytics
                    </option>
                    <option value="Digital Marketing">Digital Marketing</option>
                    <option value="Earth Sciences">Earth Sciences</option>
                    <option value="Economics">Economics</option>
                    <option value="Electrical Engineering">
                      Electrical Engineering
                    </option>
                    <option value="Electrical-Electronics">
                      Electrical-Electronics
                    </option>
                    <option value="Electronics &amp; Communication">
                      Electronics &amp; Communication
                    </option>
                    <option value="Energy Engineering">Energy Engineering</option>
                    <option value="Engineering Management">
                      Engineering Management
                    </option>
                    <option value="Entrepreneurship">Entrepreneurship</option>
                    <option value="Fashion Design">Fashion Design</option>
                    <option value="Finance">Finance</option>
                    <option value="Geology">Geology</option>
                    <option value="Health Sciences">Health Sciences</option>
                    <option value="Hospitality Management">
                      Hospitality Management
                    </option>
                    <option value="Information Technology">
                      Information Technology
                    </option>
                    <option value="Interior Design">Interior Design</option>
                    <option value="International Business">
                      International Business
                    </option>
                    <option value="Journalism &amp; Mass Communication">
                      Journalism &amp; Mass Communication
                    </option>
                    <option value="LLB">LLB</option>
                    <option value="LLM">LLM</option>
                    <option value="MBA">MBA</option>
                    <option value="MBBS">MBBS</option>
                    <option value="Media Science">Media Science</option>
                    <option value="Oil &amp; Gas / Petroleum Engineering">
                      Oil &amp; Gas / Petroleum Engineering
                    </option>
                    <option value="Pharmacy">Pharmacy</option>
                    <option value="Renewable Energy">Renewable Energy</option>
                    <option value="Software Engineering">Software Engineering</option>
                    <option value="Sustainable Development">
                      Sustainable Development
                    </option>
                    <option value="Textile Engineering">Textile Engineering</option>
                    <option value="Others">Others</option>
                  </select>
                  <div *ngIf="briefinfosubmitted && b.interest_course" class="invalid-feedback">
                    <div *ngIf="b.interest_course.errors">
                      Interested Course is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-6">
                <input type="text" placeholder="Enter Course keyword" name="course_keywords"
                  formControlName="course_keywords" [ngClass]="{
            'is-invalid field-error':
              briefinfosubmitted && b.course_keywords.errors
          }" />
                <div *ngIf="briefinfosubmitted && b.course_keywords.errors" class="invalid-feedback">
                  <div *ngIf="b.course_keywords.errors.required">
                    Course Keyword is required.
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-12" style="height:50%">
                <!-- <div class="input-select"> -->
                <select size="6" style="height:50%" name="country" id="country" formControlName="country" [ngClass]="{
            'field-error': briefinfosubmitted && b.country.errors
          }" multiple>
                  <option [ngValue]="null" value="" disabled selected>
                    Preferred Country
                  </option>
                  <option value="UK">UK</option>
                  <option value="Ireland">Ireland</option>
                  <option value="USA">USA</option>
                  <option value="Australia">Australia</option>
                  <option value="Germany">Germany</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Canada">Canada</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Europe">Europe</option>
                  <option value="France">France</option>
                  <option value="Others">Others</option>
                </select>

                <div *ngIf="briefinfosubmitted && b.country.errors" class="invalid-feedback">
                  <div *ngIf="b.country.errors.required">Country is required.</div>
                </div>
              </div>
              <!-- </div> -->

              <div class="col-md-6">
                <div class="input-select">
                  <select name="interest_level" id="interest_level" formControlName="interest_level" [ngClass]="{
              'field-error': briefinfosubmitted && b.interest_level.errors
            }">
                    <option value="" disabled selected="true">Interested Level</option>
                    <option value="Masters">Masters</option>
                    <option value="Bachelors">Bachelors</option>
                    
                  </select>
                  <div *ngIf="briefinfosubmitted && b.interest_level.errors" class="invalid-feedback">
                    <div *ngIf="b.interest_level.errors.required">
                      Level is required.
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="input-select">
                  <select name="interest_intake" id="interest_intake" formControlName="interest_intake" [ngClass]="{
              'field-error': briefinfosubmitted && b.interest_intake.errors
            }">
                    <option value="" disabled selected>Intake (Month Year)</option>

                    <option value="{{item}}" *ngFor="let item of intake">{{item}}</option>
                    
                  </select>
                  <div *ngIf="briefinfosubmitted && b.interest_intake.errors" class="invalid-feedback">
                    <div *ngIf="b.interest_intake.errors.required">
                      Intake is required.
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="row"> -->
              <div class="col-md-6">
                <div class="input-select">
                  <select name="exam_given" id="exam_given" formControlName="exam_given" [ngClass]="{
              'field-error': briefinfosubmitted && b.exam_given.errors
            }">
                    <option value="" disabled selected="true">Exam Given</option>
                    <option value="IELTS">IELTS</option>
                    <option value="TOEFL">TOEFL</option>
                    <option value="PTE">PTE</option>
                    <option value="GRE">GRE</option>
                    <option value="GMAT">GMAT</option>
                    <option value="SAT">SAT</option>
                    <option value="ACT">ACT</option>
                    <option value="None">None</option>
                  </select>
                  <div *ngIf="briefinfosubmitted && b.exam_given.errors" class="invalid-feedback">
                    <div *ngIf="b.exam_given.errors.required">Exam Given is required.</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <input id="exam-score" type="number" name="exam-score" placeholder="Score" formControlName="exam_score"
                  [ngClass]="{
            'field-error': briefinfosubmitted && b.exam_score.errors
          }" />
                <div *ngIf="briefinfosubmitted && b.exam_score.errors" class="invalid-feedback">
                  <div *ngIf="b.exam_score.errors.required">Score is required.</div>
                </div>
              </div>
              <!-- </div> -->
              <div class="col-md-12">
                <button type="submit" class="btn btn--primary w-100 type--uppercase">
                  Next
                </button>
              </div>
            </form>
          </div>
          <div *ngIf="sectionVisible == 4">
            <div class="col-md-12">
              <h3 class="text-black">Education/Experience Details</h3>
            </div>
            <form class="form row mx-0" [formGroup]="educationDetailForm" (ngSubmit)="educationDetailSubmit()">
             
              <div class="col-md-6 col-6">
                <input type="number" placeholder="10th Percentage" name="tenth_percentage"
                  formControlName="tenth_percentage" [ngClass]="{
                  'is-invalid field-error':
                  educationsubmitted && e.tenth_percentage.errors
                  }" />
                <div *ngIf="educationsubmitted && e.tenth_percentage.errors" class="invalid-feedback">
                  <div *ngIf="e.tenth_percentage.errors.required">
                    10th Percentage is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-6">
                <input type="date" id="10passingYear" placeholder="Passing Year" name="tenth_year" formControlName="tenth_year"
                  [ngClass]="{
                  'is-invalid field-error':
                  educationsubmitted && e.tenth_year.errors
                  }" />
                <div *ngIf="educationsubmitted && e.tenth_year.errors" class="invalid-feedback">
                  <div *ngIf="e.tenth_year.errors.required">
                    Passing Year is required.
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-6">
                
                <input type="number" placeholder="12th Percentage" name="twelfth_percentage"
                  formControlName="twelfth_percentage" [ngClass]="{
                  'is-invalid field-error':
                  educationsubmitted && e.twelfth_percentage.errors
                  }" />
                <div *ngIf="educationsubmitted && e.twelfth_percentage.errors" class="invalid-feedback">
                  <div *ngIf="e.twelfth_percentage.errors.required">
                    12th Percentage is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-6">
                <!-- <input type="date" id="dateInput" placeholder="dd/mm/yyyy" onfocus="(this.type='date')" onblur="(this.type='text')"> -->
                <input type="date" id="passingYear" name="twelfth_year" onfocus="(this.type='date')" onblur="if(this.value==''){this.type='text'}" placeholder="Passing Year" formControlName="twelfth_year"
                   />
                <div *ngIf="educationsubmitted && e.twelfth_year.errors" class="invalid-feedback">
                  <div *ngIf="e.twelfth_year.errors.required">
                    Passing Year is required.
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-6">
                <input type="number" placeholder="Bachelor Percentage" name="bachelor_percentage"
                  formControlName="bachelor_percentage" [ngClass]="{
                  'is-invalid field-error':
                  educationsubmitted && e.bachelor_percentage.errors
                  }" />
                <div *ngIf="educationsubmitted && e.bachelor_percentage.errors" class="invalid-feedback">
                  <div *ngIf="e.bachelor_percentage.errors.required">
                    Bachelor Percentage is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-6">
                <input type="date" placeholder="Passing Year" name="bachelor_year" formControlName="bachelor_year"
                  [ngClass]="{
                  'is-invalid field-error':
                  educationsubmitted && e.bachelor_year.errors
                  }" />
                <div *ngIf="educationsubmitted && e.bachelor_year.errors" class="invalid-feedback">
                  <div *ngIf="e.bachelor_year.errors.required">
                    Passing Year is required.
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <input type="text" placeholder="Years of Work Experience" name="work_year" formControlName="work_year"
                  [ngClass]="{
                  'is-invalid field-error':
                  educationsubmitted && e.work_year.errors
                  }" />
                <div *ngIf="educationsubmitted && e.work_year.errors" class="invalid-feedback">
                  <div *ngIf="e.work_year.errors.required">
                    Years of Experience is required.
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="input-select">
                  <select name="coaching_joined_planning" id="coaching_joined_planning"
                    formControlName="coaching_joined_planning" [ngClass]="{
                    'field-error': educationsubmitted && e.coaching_joined_planning.errors
                  }">
                    <option value="" disabled selected="true">Have you already joined a coaching or a planning ?
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No
                    </option>

                  </select>
                  <div *ngIf="educationsubmitted && e.coaching_joined_planning" class="invalid-feedback">
                    <div *ngIf="e.coaching_joined_planning.errors">
                      This is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="input-select">
                  <select name="require_help_coaching" id="require_help_coaching"
                    formControlName="require_help_coaching" [ngClass]="{
                    'field-error': educationsubmitted && e.require_help_coaching.errors
                  }">
                    <option disabled value="" selected="true">Would you require our assistance in coaching? </option>
                    <option value="Yes">Yes</option>
                    <option value="No">
                      No
                    </option>

                  </select>
                  <div *ngIf="educationsubmitted && e.require_help_coaching" class="invalid-feedback">
                    <div *ngIf="e.require_help_coaching.errors">
                      This is required.
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="input-select">
                  <select name="budget" id="budget" formControlName="budget" [ngClass]="{
                    'field-error': educationsubmitted && e.budget.errors
                  }">
                    <option disabled value="" selected="true">Select Budget? </option>
                    <option value="Below 10 Lacs">Below 10 Lacs</option>
                    <option value="10 - 20 Lacs">
                      10 - 20 Lacs
                    </option>
                    <option value="10 - 20 Lacs">
                      10 - 20 Lacs
                    </option>
                    <option value="10 - 20 Lacs">
                      10 - 20 Lacs
                    </option>
                    <option value="20 - 30 Lacs">
                      20 - 30 Lacs
                    </option>
                    <option value="30 - 40 Lacs">
                      30 - 40 Lacs
                    </option>
                    <option value="Above 40 Lacs">
                      Above 40 Lacs
                    </option>
                  </select>
                  <div *ngIf="educationsubmitted && e.budget" class="invalid-feedback">
                    <div *ngIf="e.budget.errors">
                      Budget is required.
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <button type="submit" class="btn btn--primary w-100 type--uppercase">
                  Next
                </button>
              </div>
            </form>
          </div>
        
     
        </div>
      </div>
    </div>
  </div>
</section>

<script>
  // Reset to text type when the input loses focus and is empty
  document.getElementById('dateInput').addEventListener('blur', function() {
    if (this.value === '') {
      this.type = 'text';
    }
  });
</script>