<footer class="space--sm footer-2 bg--dark ">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-lg-2 col-6">
                <h6 class="type--uppercase">Country Search</h6>
                <ul class="list--hover">
                    <li>
                        <a routerLink="/study-in/uk">Study in UK</a>
                    </li>
                    <li>
                        <a routerLink="/study-in/usa">Study in USA</a>
                    </li>
                    <li>
                        <a routerLink="/study-in/australia">Study in Australia</a>
                    </li>
                    <li>
                        <a routerLink="/study-in/canada">Study in Canada</a>
                    </li>
                    <li>
                        <a routerLink="/study-in/ireland">Study in Ireland</a>
                    </li>
                    <li>
                        <a routerLink="/study-in/germany">Study in Germany</a>
                    </li>
                    <li>
                        <a routerLink="/study-in/france">Study in France</a>
                    </li>
                    <li>
                        <a routerLink="/study-in/singapore">Study in Singapore</a>
                    </li>
                </ul>
            </div>
            <div class="col-md-6 col-lg-2 col-6">
                <h6 class="type--uppercase">About</h6>
                <ul class="list--hover">
                    <li>
                        <a routerLink="/about">About</a>
                    </li>
                    <li>
                        <a routerLink="/contact">Contact us</a>
                    </li>
                    <li>
                        <a routerLink="/terms">Terms of Service</a>
                    </li>
                    <li>
                        <a routerLink="/disclaimer">Disclaimer</a>
                    </li>
                  
                </ul>
            </div>
            <div class="col-md-6 col-lg-2 col-6">
                <h6 class="type--uppercase">More</h6>
                <ul class="list--hover">
                    <li>
                        <a routerLink="/expert-list">Our Experts</a>
                    </li>
                    <li>
                        <a routerLink="/pricing">Premium Services</a>
                    </li>
                    <li>
                        <a routerLink="/ask-juno">Ask Juno</a>
                    </li>
                    <li>
                        <a routerLink="/pricing">Upcoming /Fairs</a>
                    </li>
                    <li>
                        <a href="#">Videos</a>
                    </li>
                    <li>
                        <a href="#">Blog</a>
                    </li>
                    <li>
                        <a href="#">Test Prep</a>
                    </li>
                </ul>
            </div>
            <div class="col-md-6 col-lg-3 col-6">
                <h6 class="type--uppercase">CONTACT</h6>
                <ul class="list--hover">
                    <li>
                        <p style="background: url(assets/img/footer-address.png) no-repeat left 8px!important;line-height: 20px!important;margin-bottom: 6px!important;padding: 4px 10px 4px 40px;">

                            MeetUniversity.Com <br>
                            B-4, First Floor, B Block, Sector 63, Noida, Uttar Pradesh 201301</p>
                    </li>
                    <li>
                    <p style="background: url(assets/img/footer-address.png) no-repeat left 8px!important;line-height: 20px!important;margin-bottom: 6px!important;padding: 4px 10px 4px 40px;">

                        Ground Floor, Chromium, Jogeshwari - Vikhroli Link Rd, Raje Sambhaji Nagar, Marol, Andheri East, Mumbai, Maharashtra 400076</p>
                    </li>
                    <li>
                        <p style="background: url(assets/img/footer-phone.png) no-repeat left 8px!important;margin-bottom: 6px!important;padding: 4px 10px 4px 40px;">
                            <a rel="noopener" href="tel:+918375034794">+91 8375034794</a>
                        </p>
                    </li>
                    <li>
                        <p style="background: url(assets/img/footer-time.png) no-repeat left 8px!important;margin-bottom: 6px!important;padding: 4px 10px 4px 40px;">
                            Mon - Fri: 9AM - 6PM</p>
                    </li>
                    <li>
                        <p style="background: url(assets/img/footer-mail.png) no-repeat left 8px!important;margin-bottom: 6px!important;padding: 4px 10px 4px 40px;">
                            <a rel="noopener" href="mailto:connect@meetuniversity.com">connect@meetuniversity.com</a></p>
                    </li>
                </ul>
            </div>
            <div class="col-md-6 col-lg-3 col-6">
                <h6 class="type--uppercase"></h6>
                <ul class="social-list-ul">
                    <li> <img height="auto" width="auto" alt="image" loading="lazy" src="assets/img/footer-connect-1.png" class="mb-2">

                        <p class="counter">{{footerData?.totalLeads}}</p>
                        <p class="counter">
                            <span class="counter-text">students
                                counseled
                            </span>
                        </p>
                    </li>
                    <li> <img height="auto" width="auto" alt="image" loading="lazy" src="assets/img/footer-connect-2.png" class="mt-5 mb-2">
                        <p class="counter">{{footerData?.connect_total}}</p>
                        <p class="counter">
                            <span class="counter-text">university
                                connects</span>
                        </p>

                    </li>
                </ul>
            </div>
        </div>
        <!--end of row-->
        <div class="row">
            <div class="col-md-6">
                <span class="type--fine-print">©
                    <span class="update-year">2022</span> Meet University</span>
 
            </div>
            <div class="col-md-6 text-right text-left-xs">
                <ul class="social-list list-inline list--hover">
                    
                    <li>
                        <a href="https://twitter.com/meetuniversity" target="_blank">
                            <i class="socicon socicon-twitter icon icon--xs"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/MeetUniversity" target="_blank">
                            <i class="socicon socicon-facebook icon icon--xs"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/school/meetuniversity/?originalSubdomain=in" target="_blank">
                            <i class="socicon socicon-linkedin icon icon--xs"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/channel/UC8kRK66OgNudEdbIhxxufrw" target="_blank">
                            <i class="socicon socicon-youtube icon icon--xs" ></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <!--end of row-->
    </div>
    <!--end of container-->
</footer>