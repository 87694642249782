import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../core/services/api.service';
import { apiUrl } from '../../../config/config';
import { Booking } from '../interfaces/booking';

@Injectable({
  providedIn: 'root',
})

export class BookingService {
  ExpertListUrl = apiUrl.book_appointment;

  constructor(private ApiService: ApiService) {}

  postBookingData(body: any): Observable<Booking> {
    return this.ApiService.create(body, this.ExpertListUrl);
  }
}
