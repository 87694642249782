import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { apiUrl } from 'src/config/config';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private ApiService: ApiService) {}
  searchUrl = apiUrl.search_new;
  exotelCallUrl = apiUrl.exotel_call;

  getSearchResult(filter: any) {
    console.log("filter", filter)
    let queryString: string;
    queryString = `q=${filter.q}`;
    queryString =
    queryString + (filter.country != '' ? `&country=${filter.country}` : '&country=uk');
    queryString =
    queryString + (filter.level != '' ? `&level=${filter.level}` : '');
    queryString = queryString + (filter.englishExam == 'ielts' ? `&ielts=4.0` : '');
    queryString = queryString + (filter.englishExam == 'toefl' ? `&toefl=60` : '&toefl=60');

    return this.ApiService.get(`${this.searchUrl}?${queryString}`);
  }

  ///Todo: open modal popup for phone number and call it
  exotelCall(phone: number) {
    return this.ApiService.get(`${this.exotelCall}?to=${phone}`);
  }
}
