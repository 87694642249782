<footer class="footer-3 text-center-xs space--xs ">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <img alt="Image" class="logo" src="assets/img/logo-dark.webp" />
            </div>
            <div class="col-md-6 text-right text-center-xs">
                <ul class="social-list list-inline list--hover">
                    <li class="list-inline-item">
                        <a href="#">
                            <i class="socicon socicon-google icon icon--xs"></i>
                        </a>
                    </li>
                    <li class="list-inline-item">
                        <a href="#">
                            <i class="socicon socicon-twitter icon icon--xs"></i>
                        </a>
                    </li>
                    <li class="list-inline-item">
                        <a href="#">
                            <i class="socicon socicon-facebook icon icon--xs"></i>
                        </a>
                    </li>
                    <li class="list-inline-item">
                        <a href="#">
                            <i class="socicon socicon-instagram icon icon--xs"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <!--end of row-->
        <div class="row">
            <div class="col-md-6">
                <p class="type--fine-print">
                    Manage your education profile
                </p>
            </div>
            <div class="col-md-6 text-right text-center-xs">
                <span class="type--fine-print">&copy;
                    <span class="update-year"></span> Meet University</span>
                <!-- <a class="type--fine-print" href="#">Privacy Policy</a>
                <a class="type--fine-print" href="#">Legal</a> -->
            </div>
        </div>
        <!--end of row-->
    </div>
    <!--end of container-->
</footer>