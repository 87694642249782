import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminHeaderComponent } from './components/admin-header/admin-header.component';
import { AdminFooterComponent } from './components/admin-footer/admin-footer.component';
import { PublicHeaderComponent } from './components/public-header/public-header.component';
import { PublicFooterComponent } from './components/public-footer/public-footer.component';
import { AdminSidebarComponent } from './components/admin-sidebar/admin-sidebar.component';
import { RouterModule } from '@angular/router';
import { PublicFaqComponent } from './components/public-faq/public-faq.component';
import { PublicTestimonialComponent } from './components/public-testimonial/public-testimonial.component';
import { AdminNavComponent } from './components/admin-nav/admin-nav.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { AccordionModule } from 'ngx-bootstrap/accordion';
@NgModule({
  declarations: [
    AdminHeaderComponent,
    AdminFooterComponent,
    PublicHeaderComponent,
    PublicFooterComponent,
    AdminSidebarComponent,
    PublicFaqComponent,
    PublicTestimonialComponent,
    AdminNavComponent,
  ],
  imports: [CommonModule, RouterModule,CarouselModule,NgxSliderModule,AccordionModule],
  exports: [
    AdminHeaderComponent,
    AdminFooterComponent,
    PublicHeaderComponent,
    PublicFooterComponent,
    AdminSidebarComponent,
    PublicFaqComponent,
    PublicTestimonialComponent,
    AdminNavComponent,
  ],
})
export class SharedModule {}
