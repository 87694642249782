import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { apiUrl } from '../../../config/config';

@Injectable({
  providedIn: 'root'
})
export class UniversitylistService {
  UniversityListUrl = apiUrl.university_list;
  UniversityCoursesListUrl=apiUrl.university_courses;
  UniversityExtraDataUrl=apiUrl.new_university_extradata;
  constructor(private ApiService: ApiService) {}

  getUniversityList(json:any) {
    return this.ApiService.get(this.UniversityListUrl+'?country='+json.country+'&cat='+json.cat);
  }
  getUniversityCourses(json:any) {
    return this.ApiService.get(this.UniversityCoursesListUrl+'?uid='+json.uid+'&size='+json.size);
  }
  getUniversityExtraData(json:any) {
    return this.ApiService.get(this.UniversityExtraDataUrl+'&university_name='+json.university_name);
  }
}
