import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss']
})
export class AdminSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  showSidebar(){
    var hamburger = document.getElementById('hamburger');

  }
  myFunc(ee:any) {
    const target: HTMLElement = ee.target;
    target.classList.toggle('active');
}
}
