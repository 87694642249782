import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services/api.service';
import { apiUrl } from '../../../config/config';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(private ApiService: ApiService) {}
  EventUrl = apiUrl.get_events;

  getEvent() {
    return this.ApiService.get(this.EventUrl);
  }
}
