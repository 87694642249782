<section class="imagebg height-100 pt-0" data-overlay="2">
  <div class="background-image-holder" style="background: url(&quot;assets/img/application.jpg&quot;); opacity: 1;">
    <img alt="background" src="assets/img/application.jpg">
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-xs-12">
        <div class="logo" routerLink="">
          <img src="assets/img/logo-light.png" />
        </div>

      </div>
      <div class="col-lg-6 col-md-6 col-xs-12 text-right">
        <button routerLink="/contact" class="btn btn-outline-light mt-5">Contact Us</button>

      </div>

    </div>
  </div>
  <div class="container">
    <div class="row  backgroundtestimonial">
      <div class="col-md-6 col-12">
        <div class="section-slider">
          <div class="p-5">
            <h2 class="mt-5"> Apply to 750+ Universities. </h2>
            <p class="lead text-white"> One form, Multiple Universities. </p>
          </div>
          <hr>
          <carousel [interval]="4500">
            <slide>
              <div class="row">
                <div class="testimonial">
                  <div class="col-md-12">
                    <p>
                      “I wanted to pursue a degree which could help me get
                      the relevant expertise for my real estate business, I
                      was able to search for the course of my choice,
                      connect with expert counsellors &amp; take a wise
                      decision. The webinars &amp; direct connect feature
                      was really helpful.”
                    </p>
                    <h5>- Muhammad Shahzaib</h5>
                  </div>
                </div>
              </div>
            </slide>
            <slide>
              <div class="row">
                <div class="testimonial">
                  <div class="col-md-12">
                    <p>
                      “The counsellors helped me shortlist universities
                      according to my interests. With the ability to engage
                      &amp; speak to universities directly, I was more
                      confident of the right university to choose.”
                    </p>
                    <h5>- Arijit Banerjee</h5>
                    <!-- <span>Interface Designer &mdash; Yoke</span> -->
                  </div>
                </div>
              </div>
            </slide>
            <slide>
              <div class="row">
                <div class="testimonial">
                  <div class="col-md-12">
                    <p>
                      “I would like to thank you for such a significant SOP.
                      It is absolutely great. I'll be needing your help in
                      proof reading in coming future, though. I enjoyed
                      working with you.”
                    </p>
                    <h5>- Shreshtha Srivastav</h5>
                    <!-- <span>Interface Designer &mdash; Yoke</span> -->
                  </div>
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </div>

      <div class="col-md-6 col-lg-6 align-self-center">
        <div class="boxed boxed--lg boxed--border bg-white boxed-form ">
          <h4 class="text-black">
            With MeetUniversity it’s simple to apply to various universities
            with a single form
          </h4>

          <div *ngIf="sectionVisible == 1">
            <p class="text-black">
              {{otpSendStatus}}
            </p>
            <form [formGroup]="otpForm" (ngSubmit)="onConfirm()">
              <div class="row">
                <div class="col-12">
                  <input type="text" name="Enter OTP" placeholder="Enter OTP" formControlName="code" class="w-50" />
                  <div *ngIf="submitted && o.code.errors" class="invalid-feedback">
                    <div *ngIf="o.code.errors.required">Otp is required.</div>
                  </div>
                </div>
                <div class="col-12">
                  <button type="submit" class="btn btn--primary type--uppercase w-50">
                    Confirm
                  </button>
                </div>
                <div class="col-12">
                  <a href="javascript: void(0);" (click)="onShowPhone()" class="text-black">Change Phone </a>
                  <label class="text-black"> | </label>
                  <a href="javascript: void(0);" (click)="sendOtp(phone, name)" class="text-black"> Resend OTP</a>
                </div>
                <div class="col-12" *ngIf="showChangePhone">
                  <input type="text" name="New Phone" placeholder="New Phone" class="w-50" #newphone />
                  <button type="button" class="btn btn--primary type--uppercase mt-0"
                    (click)="onChangePhone(newphone.value)">Change</button>
                </div>
              </div>
              <!--end row-->
            </form>
          </div>

          <div *ngIf="sectionVisible == 2">
          </div>

          <div *ngIf="sectionVisible == 3">
            <div class="col-md-12">
              <h3 class="text-black">Brief Information</h3>
            </div>
            <form class="form row mx-0" [formGroup]="briefInformationForm" (ngSubmit)="saveBriefInfoForm()">
              <div class="col-md-6">
                <div class="input-select">
                  <select name="interest_course" id="interest_course" formControlName="interest_course" [ngClass]="{
              'field-error': briefinfosubmitted && b.interest_course.errors
            }">
                    <option disabled value="" selected="true">Interested Course</option>
                    <option value="Accountancy">Accountancy</option>
                    <option value="Accounting &amp; Finance">
                      Accounting &amp; Finance
                    </option>
                    <option value="Agricultural Sciences">Agricultural Sciences</option>
                    <option value="Animation">Animation</option>
                    <option value="Applied Sciences">Applied Sciences</option>
                    <option value="Architecture">Architecture</option>
                    <option value="BBA">BBA</option>
                    <option value="Biology">Biology</option>
                    <option value="Biomedical Science">Biomedical Science</option>
                    <option value="Biotechnology">Biotechnology</option>
                    <option value="Business and Economics">
                      Business and Economics
                    </option>
                    <option value="Business and Entrepreneurship">
                      Business and Entrepreneurship
                    </option>
                    <option value="Business and Finance">Business and Finance</option>
                    <option value="Business Management">Business Management</option>
                    <option value="Chemical Engineering">Chemical Engineering</option>
                    <option value="Civil Engineering">Civil Engineering</option>
                    <option value="Computer Science">Computer Science</option>
                    <option value="Computing">Computing</option>
                    <option value="Criminology">Criminology</option>
                    <option value="Cyber Security">Cyber Security</option>
                    <option value="Data Science &amp; Analytics">
                      Data Science &amp; Analytics
                    </option>
                    <option value="Digital Marketing">Digital Marketing</option>
                    <option value="Earth Sciences">Earth Sciences</option>
                    <option value="Economics">Economics</option>
                    <option value="Electrical Engineering">
                      Electrical Engineering
                    </option>
                    <option value="Electrical-Electronics">
                      Electrical-Electronics
                    </option>
                    <option value="Electronics &amp; Communication">
                      Electronics &amp; Communication
                    </option>
                    <option value="Energy Engineering">Energy Engineering</option>
                    <option value="Engineering Management">
                      Engineering Management
                    </option>
                    <option value="Entrepreneurship">Entrepreneurship</option>
                    <option value="Fashion Design">Fashion Design</option>
                    <option value="Finance">Finance</option>
                    <option value="Geology">Geology</option>
                    <option value="Health Sciences">Health Sciences</option>
                    <option value="Hospitality Management">
                      Hospitality Management
                    </option>
                    <option value="Information Technology">
                      Information Technology
                    </option>
                    <option value="Interior Design">Interior Design</option>
                    <option value="International Business">
                      International Business
                    </option>
                    <option value="Journalism &amp; Mass Communication">
                      Journalism &amp; Mass Communication
                    </option>
                    <option value="LLB">LLB</option>
                    <option value="LLM">LLM</option>
                    <option value="MBA">MBA</option>
                    <option value="MBBS">MBBS</option>
                    <option value="Media Science">Media Science</option>
                    <option value="Oil &amp; Gas / Petroleum Engineering">
                      Oil &amp; Gas / Petroleum Engineering
                    </option>
                    <option value="Pharmacy">Pharmacy</option>
                    <option value="Renewable Energy">Renewable Energy</option>
                    <option value="Software Engineering">Software Engineering</option>
                    <option value="Sustainable Development">
                      Sustainable Development
                    </option>
                    <option value="Textile Engineering">Textile Engineering</option>
                    <option value="Others">Others</option>
                  </select>
                  <div *ngIf="briefinfosubmitted && b.interest_course" class="invalid-feedback">
                    <div *ngIf="b.interest_course.errors">
                      Interested Course is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-6">
                <input type="text" placeholder="Enter Course keyword" name="course_keywords"
                  formControlName="course_keywords" [ngClass]="{
            'is-invalid field-error':
              briefinfosubmitted && b.course_keywords.errors
          }" />
                <div *ngIf="briefinfosubmitted && b.course_keywords.errors" class="invalid-feedback">
                  <div *ngIf="b.course_keywords.errors.required">
                    Course Keyword is required.
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-12" style="height:50%">
                <!-- <div class="input-select"> -->
                <select size="6" style="height:50%" name="country" id="country" formControlName="country" [ngClass]="{
            'field-error': briefinfosubmitted && b.country.errors
          }" multiple>
                  <option [ngValue]="null" value="" disabled selected>
                    Preferred Country
                  </option>
                  <option value="UK">UK</option>
                  <option value="Ireland">Ireland</option>
                  <option value="USA">USA</option>
                  <option value="Australia">Australia</option>
                  <option value="Germany">Germany</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Canada">Canada</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Europe">Europe</option>
                  <option value="France">France</option>
                  <option value="Others">Others</option>
                </select>

                <div *ngIf="briefinfosubmitted && b.country.errors" class="invalid-feedback">
                  <div *ngIf="b.country.errors.required">Country is required.</div>
                </div>
              </div>
              <!-- </div> -->

              <div class="col-md-6">
                <div class="input-select">
                  <select name="interest_level" id="interest_level" formControlName="interest_level" [ngClass]="{
              'field-error': briefinfosubmitted && b.interest_level.errors
            }">
                    <option value="" disabled selected="true">Level</option>
                    <option value="Masters">Masters</option>
                    <option value="Bachelors">Bachelors</option>
                    <option value="Diploma">Diploma</option>
                    <option value="Foundation">Foundation</option>
                    <option value="Advance Diploma">Advance Diploma</option>
                    <option value="PG Diploma">PG Diploma</option>
                    <option value="PHD">PHD</option>
                    <option value="MRes">MRes</option>
                  </select>
                  <div *ngIf="briefinfosubmitted && b.interest_level.errors" class="invalid-feedback">
                    <div *ngIf="b.interest_level.errors.required">
                      Level is required.
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="input-select">
                  <select name="interest_intake" id="interest_level" formControlName="interest_intake" [ngClass]="{
              'field-error': briefinfosubmitted && b.interest_intake.errors
            }">
                    <option value="" disabled selected>Intake (Month Year)</option>

                    <option value="{{item}}" *ngFor="let item of intake">{{item}}</option>
                    
                  </select>
                  <div *ngIf="briefinfosubmitted && b.interest_intake.errors" class="invalid-feedback">
                    <div *ngIf="b.interest_intake.errors.required">
                      Intake is required.
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="row"> -->
              <div class="col-md-6">
                <div class="input-select">
                  <select name="exam_given" id="exam_given" formControlName="exam_given" [ngClass]="{
              'field-error': briefinfosubmitted && b.exam_given.errors
            }">
                    <option value="" disabled selected="true">Exam Given</option>
                    <option value="IELTS">IELTS</option>
                    <option value="TOEFL">TOEFL</option>
                    <option value="PTE">PTE</option>
                    <option value="GRE">GRE</option>
                    <option value="GMAT">GMAT</option>
                    <option value="SAT">SAT</option>
                    <option value="ACT">ACT</option>
                    <option value="None">None</option>
                  </select>
                  <div *ngIf="briefinfosubmitted && b.exam_given.errors" class="invalid-feedback">
                    <div *ngIf="b.exam_given.errors.required">Level is required.</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <input id="exam-score" type="number" name="exam-score" placeholder="Score" formControlName="exam_score"
                  [ngClass]="{
            'field-error': briefinfosubmitted && b.exam_score.errors
          }" />
                <div *ngIf="briefinfosubmitted && b.exam_score.errors" class="invalid-feedback">
                  <div *ngIf="b.exam_score.errors.required">Score is required.</div>
                </div>
              </div>
              <!-- </div> -->
              <div class="col-md-12">
                <button type="submit" class="btn btn--primary w-100 type--uppercase">
                  Next
                </button>
              </div>
            </form>
          </div>
          <div *ngIf="sectionVisible == 4">
            <div class="col-md-12">
              <h3 class="text-black">12th Education Details</h3>
            </div>
            <form class="form row mx-0" [formGroup]="educationDetailForm" (ngSubmit)="educationDetailSubmit()">
              <div class="col-md-6 col-6">
                <input type="text" placeholder="Enter School Name" name="twelfth_school"
                  formControlName="twelfth_school" [ngClass]="{
            'is-invalid field-error':
              educationsubmitted && e.twelfth_school.errors
          }" />
                <div *ngIf="educationsubmitted && e.twelfth_school.errors" class="invalid-feedback">
                  <div *ngIf="e.twelfth_school.errors.required">
                    School Name is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <select class="required valid" name="board-12" id="board-12" aria-required="true" aria-invalid="false"
                  name="twelfth_board" formControlName="twelfth_board" [ngClass]="{
            'is-invalid field-error':
              educationsubmitted && e.twelfth_board.errors
          }">
                  <option value="" disabled="disabled" selected>Enter Board</option>
                  <option value="Andhra Pradesh Board of Intermediate Education">
                    Andhra Pradesh Board of Intermediate Education
                  </option>
                  <option value="Assam Higher Secondary Education Council">
                    Assam Higher Secondary Education Council
                  </option>
                  <option value="Bihar School Examination Board">
                    Bihar School Examination Board
                  </option>
                  <option value="Board of High School and Intermediate Education Uttar Pradesh">
                    Board of High School and Intermediate Education Uttar Pradesh
                  </option>
                  <option value="Board of Secondary Education, Madhya Pradesh">
                    Board of Secondary Education, Madhya Pradesh
                  </option>
                  <option value="Board of Secondary Education, Rajasthan">
                    Board of Secondary Education, Rajasthan
                  </option>
                  <option value="Central Board of Secondary Education">
                    Central Board of Secondary Education
                  </option>
                  <option value="Chhattisgarh Board of Secondary Education">
                    Chhattisgarh Board of Secondary Education
                  </option>
                  <option value="Goa Board of Secondary &amp; Higher Secondary Education">
                    Goa Board of Secondary &amp; Higher Secondary Education
                  </option>
                  <option value="Gujarat Secondary Education Board">
                    Gujarat Secondary Education Board
                  </option>
                  <option value="Haryana Board of School Education">
                    Haryana Board of School Education
                  </option>
                  <option value="Himachal Pradesh State Open School[2]">
                    Himachal Pradesh State Open School[2]
                  </option>
                  <option value="Jammu and Kashmir State Board of School Education">
                    Jammu and Kashmir State Board of School Education
                  </option>
                  <option value="Karnataka Secondary Education Examination Board">
                    Karnataka Secondary Education Examination Board
                  </option>
                  <option value="Kerala Higher Secondary Examination Board">
                    Kerala Higher Secondary Examination Board
                  </option>
                  <option value="Maharashtra State Board of Secondary and Higher Secondary Education">
                    Maharashtra State Board of Secondary and Higher Secondary Education
                  </option>
                  <option value="Meghalaya Board of School Education">
                    Meghalaya Board of School Education
                  </option>
                  <option value="Mizoram Board of School Education">
                    Mizoram Board of School Education
                  </option>
                  <option value="Nagaland Board of School Education">
                    Nagaland Board of School Education
                  </option>
                  <option value="National Institute of Open Schooling">
                    National Institute of Open Schooling
                  </option>
                  <option value="Odisha Council of Higher Secondary Education">
                    Odisha Council of Higher Secondary Education
                  </option>
                  <option value="Punjab School Education Board">
                    Punjab School Education Board
                  </option>
                  <option value="Tamil Nadu Board of Secondary Education">
                    Tamil Nadu Board of Secondary Education
                  </option>
                  <option value="Telangana Board of Intermediate Education">
                    Telangana Board of Intermediate Education
                  </option>
                  <option value="Tripura Board of Secondary Education">
                    Tripura Board of Secondary Education
                  </option>
                  <option value="Uttarakhand Board of School Education">
                    Uttarakhand Board of School Education
                  </option>
                  <option value="West Bengal Council of Higher Secondary Education">
                    West Bengal Council of Higher Secondary Education
                  </option>
                  <option value="Others">Others</option>
                </select>
                <div *ngIf="educationsubmitted && e.twelfth_board.errors" class="invalid-feedback">
                  <div *ngIf="e.twelfth_board.errors.required">Board is required.</div>
                </div>
              </div>
              <div class="col-md-6 col-6">
                <input type="text" placeholder="Enter Subject" name="twelfth_subject" formControlName="twelfth_subject"
                  [ngClass]="{
            'is-invalid field-error':
              educationsubmitted && e.twelfth_subject.errors
          }" />
                <div *ngIf="educationsubmitted && e.twelfth_subject.errors" class="invalid-feedback">
                  <div *ngIf="e.twelfth_subject.errors.required">
                    Subject is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-6">
                <input type="text" placeholder="Language of Instruction" name="twelfth_language"
                  formControlName="twelfth_language" [ngClass]="{
            'is-invalid field-error':
              educationsubmitted && e.twelfth_language.errors
          }" />
                <div *ngIf="educationsubmitted && e.twelfth_language.errors" class="invalid-feedback">
                  <div *ngIf="e.twelfth_language.errors.required">
                    Language of Instruction is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-6">
                <input type="number" placeholder="Percentage" name="twelfth_percentage"
                  formControlName="twelfth_percentage" [ngClass]="{
            'is-invalid field-error':
              educationsubmitted && e.twelfth_percentage.errors
          }" />
                <div *ngIf="educationsubmitted && e.twelfth_percentage.errors" class="invalid-feedback">
                  <div *ngIf="e.twelfth_percentage.errors.required">
                    Percentage is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-6">
                <input type="date" placeholder="Passing Year" name="twelfth_year" formControlName="twelfth_year"
                  [ngClass]="{
            'is-invalid field-error':
              educationsubmitted && e.twelfth_year.errors
          }" />
                <div *ngIf="educationsubmitted && e.twelfth_year.errors" class="invalid-feedback">
                  <div *ngIf="e.twelfth_year.errors.required">
                    Passing Year is required.
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <button type="submit" class="btn btn--primary w-100 type--uppercase">
                  Next
                </button>
              </div>
            </form>
          </div>
          <div *ngIf="sectionVisible == 5">
            <div class="col-md-12">
              <h3 class="text-black">Last Qualification Details</h3>
            </div>
            <form class="form row mx-0" [formGroup]="qualificationForm" (ngSubmit)="qualificationSubmit()">
              <div class="col-md-6 col-6">
                <input type="text" placeholder="Enter University Name" name="last_univ" formControlName="last_univ"
                  [ngClass]="{
            'is-invalid field-error':
            qualificationsubmitted && q.last_univ.errors
          }" />
                <div *ngIf="qualificationsubmitted && q.last_univ.errors" class="invalid-feedback">
                  <div *ngIf="q.last_univ.errors.required">
                    University Name is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-6">
                <input type="text" placeholder="Enter Degree" name="last_degree" formControlName="last_degree"
                  [ngClass]="{
            'is-invalid field-error':
            qualificationsubmitted && q.last_degree.errors
          }" />
                <div *ngIf="qualificationsubmitted && q.last_degree.errors" class="invalid-feedback">
                  <div *ngIf="q.last_degree.errors.required">
                    Degree is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-6">
                <input type="text" placeholder="Enter Course" name="last_course" formControlName="last_course"
                  [ngClass]="{
            'is-invalid field-error':
            qualificationsubmitted && q.last_course.errors
          }" />
                <div *ngIf="qualificationsubmitted && q.last_course.errors" class="invalid-feedback">
                  <div *ngIf="q.last_course.errors.required">
                    Course is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-6">
                <input type="text" placeholder="Enter Passing Year" name="last_pyear" formControlName="last_pyear"
                  [ngClass]="{
            'is-invalid field-error':
            qualificationsubmitted && q.last_pyear.errors
          }" />
                <div *ngIf="qualificationsubmitted && q.last_pyear.errors" class="invalid-feedback">
                  <div *ngIf="q.last_pyear.errors.required">
                    Passing Year is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <input type="date" placeholder="Enter From Date" name="last_from" formControlName="last_from" [ngClass]="{
            'is-invalid field-error':
            qualificationsubmitted && q.last_from.errors
          }" />
                <div *ngIf="qualificationsubmitted && q.last_from.errors" class="invalid-feedback">
                  <div *ngIf="q.last_from.errors.required">
                    From Date is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <input type="date" placeholder="Enter To Date" name="last_to" formControlName="last_to" [ngClass]="{
            'is-invalid field-error':
            qualificationsubmitted && q.last_to.errors
          }" />
                <div *ngIf="qualificationsubmitted && q.last_to.errors" class="invalid-feedback">
                  <div *ngIf="q.last_to.errors.required">
                    To Date is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <input type="text" placeholder="Percentage" name="last_percentage" formControlName="last_percentage"
                  [ngClass]="{
            'is-invalid field-error':
            qualificationsubmitted && q.last_percentage.errors
          }" />
                <div *ngIf="qualificationsubmitted && q.last_percentage.errors" class="invalid-feedback">
                  <div *ngIf="q.last_percentage.errors.required">
                    Percentage is required.
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-12">
                <input type="text" placeholder="Enter Backlogs" name="last_backlog" formControlName="last_backlog"
                  [ngClass]="{
            'is-invalid field-error':
            qualificationsubmitted && q.last_backlog.errors
          }" />
                <div *ngIf="qualificationsubmitted && q.last_backlog.errors" class="invalid-feedback">
                  <div *ngIf="q.last_backlog.errors.required">
                    Backlogs is required.
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <button type="submit" class="btn btn--primary w-100 type--uppercase">
                  Next
                </button>
              </div>
            </form>
          </div>
          <div *ngIf="sectionVisible == 6">
            <div class="col-md-12">
              <h3 class="text-black">Work Experience</h3>
            </div>
            <form class="form row mx-0" [formGroup]="experienceForm" (ngSubmit)="experienceSubmit()">
              <div class="col-md-6 col-12">
                <input type="text" placeholder="Enter Company Name" name="company_name" formControlName="company_name"
                  [ngClass]="{
            'is-invalid field-error':
            experiencesubmitted && exp.company_name.errors
          }" />
                <div *ngIf="experiencesubmitted && exp.company_name.errors" class="invalid-feedback">
                  <div *ngIf="exp.company_name.errors.required">
                    Company Name is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <input type="text" placeholder="Enter Work Domain" name="company_name" formControlName="work_domain"
                  [ngClass]="{
            'is-invalid field-error':
            experiencesubmitted && exp.work_domain.errors
          }" />
                <div *ngIf="experiencesubmitted && exp.work_domain.errors" class="invalid-feedback">
                  <div *ngIf="exp.work_domain.errors.required">
                    Work Domain is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <input type="text" placeholder="Years of Experience" name="company_name" formControlName="work_year"
                  [ngClass]="{
            'is-invalid field-error':
            experiencesubmitted && exp.work_year.errors
          }" />
                <div *ngIf="experiencesubmitted && exp.work_year.errors" class="invalid-feedback">
                  <div *ngIf="exp.work_year.errors.required">
                    Years of Experience is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <input type="date" placeholder="Work From" name="company_name" formControlName="work_from" [ngClass]="{
            'is-invalid field-error':
            experiencesubmitted && exp.work_from.errors
          }" />
                <div *ngIf="experiencesubmitted && exp.work_from.errors" class="invalid-feedback">
                  <div *ngIf="exp.work_from.errors.required">
                    Work From is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <input type="date" placeholder="Work To" name="work_to" formControlName="work_to" [ngClass]="{
            'is-invalid field-error':
            experiencesubmitted && exp.work_to.errors
          }" />
                <div *ngIf="experiencesubmitted && exp.work_to.errors" class="invalid-feedback">
                  <div *ngIf="exp.work_to.errors.required">
                    Years of Experience is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <input type="text" placeholder="Gap in Years " name="gap_year" formControlName="gap_year" [ngClass]="{
            'is-invalid field-error':
            experiencesubmitted && exp.gap_year.errors
          }" />
                <div *ngIf="experiencesubmitted && exp.gap_year.errors" class="invalid-feedback">
                  <div *ngIf="exp.gap_year.errors.required">
                    Gap is required.
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-12">
                <input type="text" placeholder="Incomplete Course (If any)" name="incomplete_course"
                  formControlName="incomplete_course" [ngClass]="{
            'is-invalid field-error':
            experiencesubmitted && exp.incomplete_course.errors
          }" />
                <div *ngIf="experiencesubmitted && exp.incomplete_course.errors" class="invalid-feedback">
                  <div *ngIf="exp.incomplete_course.errors.required">
                    Incomplete Course (If any) is required.
                  </div>
                </div>
              </div>

              <div class="col-md-12 col-12">
                <label>Reason for Gap</label>
                <textarea id="gap-reason" placeholder="Reason (max 150 character)" maxlength="150"
                  name="gap-reason"></textarea>

              </div>


              <div class="col-md-12">
                <button type="submit" class="btn btn--primary w-100 type--uppercase">
                  Next
                </button>
              </div>
            </form>
          </div>
          <div *ngIf="sectionVisible == 7">
            <div class="col-md-12">
              <h3 class="text-black">Readiness</h3>
            </div>
            <form class="form row mx-0" [formGroup]="readinessForm" (ngSubmit)="readinessSubmit()">

              <div class="col-md-12">
                <div class="input-select">
                  <select name="coaching_joined_planning" id="coaching_joined_planning"
                    formControlName="coaching_joined_planning" [ngClass]="{
            'field-error': readinesssubmitted && r.coaching_joined_planning.errors
          }">
                    <option disabled value="" selected="true">Have you already joined a coaching or a planning ?
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">
                      No
                    </option>

                  </select>
                  <div *ngIf="readinesssubmitted && r.coaching_joined_planning" class="invalid-feedback">
                    <div *ngIf="r.coaching_joined_planning.errors">
                      This is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="input-select">
                  <select name="require_help_coaching" id="require_help_coaching"
                    formControlName="require_help_coaching" [ngClass]="{
            'field-error': readinesssubmitted && r.require_help_coaching.errors
          }">
                    <option disabled value="" selected="true">Would you require our assistance in coaching ? </option>
                    <option value="Yes">Yes</option>
                    <option value="No">
                      No
                    </option>

                  </select>
                  <div *ngIf="readinesssubmitted && r.require_help_coaching" class="invalid-feedback">
                    <div *ngIf="r.require_help_coaching.errors">
                      This is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-select">
                  <select name="budget" id="budget" formControlName="budget" [ngClass]="{
            'field-error': readinesssubmitted && r.budget.errors
          }">
                    <option disabled value="" selected="true">Select Budget? </option>
                    <option value="Below 10 Lacs">Below 10 Lacs</option>
                    <option value="10 - 20 Lacs">
                      10 - 20 Lacs
                    </option>
                    <option value="10 - 20 Lacs">
                      10 - 20 Lacs
                    </option>
                    <option value="10 - 20 Lacs">
                      10 - 20 Lacs
                    </option>
                    <option value="20 - 30 Lacs">
                      20 - 30 Lacs
                    </option>
                    <option value="30 - 40 Lacs">
                      30 - 40 Lacs
                    </option>
                    <option value="Above 40 Lacs">
                      Above 40 Lacs
                    </option>
                  </select>
                  <div *ngIf="readinesssubmitted && r.budget" class="invalid-feedback">
                    <div *ngIf="r.budget.errors">
                      This is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <input type="text" placeholder="Primary Reason of Studying Abroa " name="study_abroad_reason"
                  formControlName="study_abroad_reason" [ngClass]="{
          'is-invalid field-error':
          readinesssubmitted && r.study_abroad_reason.errors
        }" />
                <div *ngIf="readinesssubmitted && r.study_abroad_reason.errors" class="invalid-feedback">
                  <div *ngIf="r.study_abroad_reason.errors.required">
                    This is required.
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-12">
                <textarea type="text" placeholder="Have you Applied or Shortlist any university? Name Them"
                  name="shortlist_applied" formControlName="shortlist_applied" [ngClass]="{
          'is-invalid field-error':
          readinesssubmitted && r.shortlist_applied.errors
        }"></textarea>
                <div *ngIf="readinesssubmitted && r.shortlist_applied.errors" class="invalid-feedback">
                  <div *ngIf="r.shortlist_applied.errors.required">
                    This is required.
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <button type="submit" class="btn btn--primary w-100 type--uppercase">
                  Next
                </button>
              </div>
            </form>
          </div>
          <div *ngIf="sectionVisible == 8">
            <div class="col-md-12">
              <h3 class="text-black">Extra Details</h3>
            </div>
            <form class="form row mx-0" [formGroup]="detailForm" (ngSubmit)="detailSubmit()">

              <div class="col-md-12">
                <div class="input-select">
                  <select name="previous_travel" id="previous_travel" formControlName="previous_travel" [ngClass]="{
            'field-error': detailsubmitted && d.previous_travel.errors
          }">
                    <option disabled value="" selected="true">Have you previously traveled overseas? </option>
                    <option value="Yes">Yes</option>
                    <option value="No">
                      No
                    </option>

                  </select>
                  <div *ngIf="detailsubmitted && d.previous_travel" class="invalid-feedback">
                    <div *ngIf="d.previous_travel.errors">
                      This is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-12">
                <input type="text" placeholder="If Yes, When?" name="travel_when" formControlName="travel_when"
                  [ngClass]="{
          'is-invalid field-error':
          detailsubmitted && d.travel_when.errors
        }" />
                <div *ngIf="detailsubmitted && d.travel_when.errors" class="invalid-feedback">
                  <div *ngIf="d.travel_when.errors.required">
                    This is required.
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="input-select">
                  <select name="visa_reject" id="visa_reject" formControlName="visa_reject" [ngClass]="{
            'field-error': detailsubmitted && d.visa_reject.errors
          }">
                    <option disabled value="" selected="true">Have you got any VISA rejections? </option>
                    <option value="Yes">Yes</option>
                    <option value="No">
                      No
                    </option>

                  </select>
                  <div *ngIf="detailsubmitted && d.visa_reject" class="invalid-feedback">
                    <div *ngIf="d.visa_reject.errors">
                      This is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-12">
                <input type="text" placeholder="If Yes, When?" name="visa_rejection_why"
                  formControlName="visa_rejection_why" [ngClass]="{
          'is-invalid field-error':
          detailsubmitted && d.visa_rejection_why.errors
        }" />
                <div *ngIf="detailsubmitted && d.visa_rejection_why.errors" class="invalid-feedback">
                  <div *ngIf="d.visa_rejection_why.errors.required">
                    This is required.
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="input-select">
                  <select name="relative_abroad" id="relative_abroad" formControlName="relative_abroad" [ngClass]="{
            'field-error': detailsubmitted && d.relative_abroad.errors
          }">
                    <option disabled value="" selected="true">Any relative staying abroad? </option>
                    <option value="Yes">Yes</option>
                    <option value="No">
                      No
                    </option>

                  </select>
                  <div *ngIf="detailsubmitted && d.relative_abroad" class="invalid-feedback">
                    <div *ngIf="d.relative_abroad.errors">
                      This is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="input-select">
                  <select name="relative_abroad_relation" id="relative_abroad_relation"
                    formControlName="relative_abroad_relation" [ngClass]="{
            'field-error': detailsubmitted && d.relative_abroad_relation.errors
          }">
                    <option disabled value="" selected="true">Select Relation ? </option>
                    <option value="Parents">Parents</option>
                    <option value="First Blood Relation">
                      First Blood Relation
                    </option>
                    <option value="Others">
                      Others
                    </option>
                  </select>
                  <div *ngIf="detailsubmitted && d.relative_abroad_relation" class="invalid-feedback">
                    <div *ngIf="d.relative_abroad_relation.errors">
                      This is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <button type="submit" class="btn btn--primary w-100 type--uppercase">
                  Next
                </button>
              </div>


            </form>
          </div>
          <div *ngIf="sectionVisible == 9">
            <div class="col-md-12">
              <h3 class="text-black">Feedback</h3>
            </div>
            <form class="form row mx-0" [formGroup]="feedbackForm" (ngSubmit)="feedbackSubmit()">
              <div class="col-md-12 col-12">
                <textarea type="text" placeholder="How did you come to know about MeetUniversity.Com?"
                  name="how_hear_meetuniversity" formControlName="how_hear_meetuniversity" [ngClass]="{
          'is-invalid field-error':
          feedbacksubmitted && f.how_hear_meetuniversity.errors
        }"></textarea>
                <div *ngIf="feedbacksubmitted && f.how_hear_meetuniversity.errors" class="invalid-feedback">
                  <div *ngIf="f.how_hear_meetuniversity.errors.required">
                    This is required.
                  </div>
                </div>
              </div>




              <div class="col-md-12">
                <button type="submit" class="btn btn--primary w-100 type--uppercase">
                  Next
                </button>
              </div>


            </form>
          </div>
          <div *ngIf="sectionVisible == 10">
            <div class="col-md-12">
              <h3 style="color: black;">Feedback</h3>
            </div>
            <h1 style="color: black;">Thank you</h1>
            <div class="col-md-12">
              <button type="submit" routerLink="/home" class="btn btn--primary w-100 type--uppercase">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>