import { Component, OnInit } from '@angular/core';
import { ContactService } from '../../services/contact.service';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
  FormArray,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactForm: FormGroup;
  submitted: boolean = false;

  constructor(private notifyService : NotificationService,
    private fb: FormBuilder,
    private contactService: ContactService) { }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      query:['',[Validators.required]],
      message: ['', [Validators.required]],
    });

  }

  get contactFormControl() {
    return this.contactForm.controls;
  }

  onSubmit(){
    if (this.contactForm.status == 'INVALID') {
      this.submitted = true;
    }

    if (this.contactForm.status == 'VALID') {

      this.contactService.saveEnquiry(this.contactForm.value).subscribe(
        async (response: any) => {
          this.notifyService.showSuccess('Thanks for your enquiry, we will be in touch shortly.');
          this.contactForm.reset();
        },(error)=>{
          this.notifyService.showError('Problem in sending enquiry.');
        }
      );
    }
  }

}
