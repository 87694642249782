import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  data = [
    { country: 'Australia', university: 'The University of Sydney', venue: 'Pune', address: 'Tower -5, World Trade Centre, MIDC KNOWLEDGE PARK, 1, Kharadi Rd, opp. EON Free Zone, EON Free Zone, Kharadi, Pune, Maharashtra 411014', startTime: '12:30:PM', endTime: '1:30:PM' },
    { country: 'New Zealand', university: 'The University of Auckland', venue: 'Delhi South', address: 'MeetUniversity.Com, B-4, First Floor, B Block, Sector 63, Noida, Uttar Pradesh 201301', startTime: '11:00:AM', endTime: '12:30:PM' },
    { country: 'Canada', university: 'Camosun College', venue: 'Chandigarh', address: 'SCO 50-51, Sub. City Center, Sector 34B, Sector 34, Chandigarh, 160022', startTime: '1:00:PM', endTime: '3:00:PM' },
    { country: 'UK', university: 'University of Central Lancashire', venue: 'Delhi West', address: 'Floor 19, C-001/A2, Sector 16B, Noida, Uttar Pradesh 201301', startTime: '3:00:PM', endTime: '4:00:PM' },
    { country: 'UK', university: 'University of Glasgow', venue: 'Chandigarh', address: 'SCO 50-51, Sub. City Center, Sector 34B, Sector 34, Chandigarh, 160022', startTime: '4:00:PM', endTime: '5:30:PM' },
    { country: 'Canada', university: 'University of Northern British Columbia', venue: 'Patiala', address: '', startTime: '4:30:PM', endTime: '5:30:PM' },
    { country: 'UK', university: 'University of Greenwich', venue: 'Bangalore', address: 'BLOCK-L, Embassy TechVillage, Outer Ring Rd, Devarabisanahalli, Bellandur, Bengaluru, Karnataka 560103', startTime: '2:30:PM', endTime: '3:30:PM' },
    { country: 'UK', university: 'University of Greenwich', venue: 'Hyderabad', address: 'Kondapur Main Road, Laxmi Cyber City, Whitefields, Kondapur, Hyderabad, Telangana 500081', startTime: '4:00:PM', endTime: '5:30:PM' },
    { country: 'Canada', university: 'Navitas', venue: 'Bathinda', address: '', startTime: '3:00:PM', endTime: '4:00:PM' },
    { country: 'Canada', university: 'Cape Breton University', venue: 'Hyderabad', address: 'Kondapur Main Road, Laxmi Cyber City, Whitefields, Kondapur, Hyderabad, Telangana 500081', startTime: '10:30:AM', endTime: '11:30:AM' },
    { country: 'UK', university: 'Solent University Southampton', venue: 'Bangalore', address: 'BLOCK-L, Embassy TechVillage, Outer Ring Rd, Devarabisanahalli, Bellandur, Bengaluru, Karnataka 560103', startTime: '2:00:PM', endTime: '3:00:PM' },
    { country: 'Canada', university: 'University of Northern British Columbia', venue: 'Chandigarh', address: 'SCO 50-51, Sub. City Center, Sector 34B, Sector 34, Chandigarh, 160022', startTime: '11:00:AM', endTime: '12:00:PM' },
    { country: 'Canada', university: 'Navitas', venue: 'Patiala', address: '', startTime: '2:00:PM', endTime: '3:00:PM' },
    { country: 'UK', university: 'University for the Creative Arts', venue: 'Delhi South', address: 'MeetUniversity.Com, B-4, First Floor, B Block, Sector 63, Noida, Uttar Pradesh 201301', startTime: '2:30:PM', endTime: '3:30:PM' },
    { country: 'UK', university: 'Kingston University', venue: 'Bangalore', address: 'BLOCK-L, Embassy TechVillage, Outer Ring Rd, Devarabisanahalli, Bellandur, Bengaluru, Karnataka 560103', startTime: '2:30:PM', endTime: '3:30:PM' },
    { country: 'UK', university: 'University of South Wales', venue: 'Delhi South', address: 'MeetUniversity.Com, B-4, First Floor, B Block, Sector 63, Noida, Uttar Pradesh 201301', startTime: '3:00:PM', endTime: '4:30:PM' },
    { country: 'Canada', university: 'North Island College', venue: 'Delhi South', address: 'MeetUniversity.Com, B-4, First Floor, B Block, Sector 63, Noida, Uttar Pradesh 201301', startTime: '2:30:PM', endTime: '3:30:PM' },
    { country: 'Australia', university: 'La Trobe University', venue: 'Mumbai Andheri', address: 'No. A3&B2, B Cross Road, Marol, Industrial Area, Mumbai, Maharashtra 400093', startTime: '3:30:PM', endTime: '5:30:PM' },
    { country: 'Germany', university: 'EU Business School Munich', venue: 'Pune', address: 'Tower -5, World Trade Centre, MIDC KNOWLEDGE PARK, 1, Kharadi Rd, opp. EON Free Zone, EON Free Zone, Kharadi, Pune, Maharashtra 411014', startTime: '2:30:PM', endTime: '3:30:PM' },
    { country: 'Australia', university: 'Murdoch University', venue: 'Chandigarh', address: 'SCO 50-51, Sub. City Center, Sector 34B, Sector 34, Chandigarh, 160022', startTime: '2:30:PM', endTime: '4:00:PM' },
    { country: 'Australia', university: 'The University of Queensland', venue: 'Delhi South', address: 'MeetUniversity.Com, B-4, First Floor, B Block, Sector 63, Noida, Uttar Pradesh 201301', startTime: '3:30:PM', endTime: '4:15:PM' },
    { country: 'New Zealand', university: 'UNITEC Institute of Technology', venue: 'Delhi South', address: 'MeetUniversity.Com, B-4, First Floor, B Block, Sector 63, Noida, Uttar Pradesh 201301', startTime: '12:00:PM', endTime: '1:30:PM' },
    { country: 'Australia', university: 'IIBIT Federation', venue: 'Chennai', address: '', startTime: '3:15:PM', endTime: '4:00:PM' },
    { country: 'Canada', university: 'Camosun College', venue: 'Mohali', address: 'F-429, Phase 8B, Industrial Area, Sahibzada Ajit Singh Nagar, Punjab 160059', startTime: '1:00:PM', endTime: '3:00:PM' },
    { country: 'USA', university: 'Whitworth university MSM', venue: 'Delhi South', address: 'MeetUniversity.Com, B-4, First Floor, B Block, Sector 63, Noida, Uttar Pradesh 201301', startTime: '3:00:PM', endTime: '4:00:PM' },
    { country: 'Australia', university: 'Griffith College Australia', venue: 'Ludhiana', address: '', startTime: '1:00:PM', endTime: '2:00:PM' },
    { country: 'Australia', university: 'Macquarie University Sydney', venue: 'Delhi South', address: 'MeetUniversity.Com, B-4, First Floor, B Block, Sector 63, Noida, Uttar Pradesh 201301', startTime: '1:00:PM', endTime: '2:00:PM' },
    { country: 'USA', university: 'The University Of Akron', venue: 'Delhi South', address: 'MeetUniversity.Com, B-4, First Floor, B Block, Sector 63, Noida, Uttar Pradesh 201301', startTime: '12:30:PM', endTime: '1:00:PM' },
    { country: 'VAS Partners', university: 'NIB Health Insurance and Private Health Cover Australia', venue: 'Mumbai Bandra', startTime: '12:00:PM', endTime: '12:30:PM' },
    { country: 'Canada', university: 'University Canada West', venue: 'Delhi South', address: 'MeetUniversity.Com, B-4, First Floor, B Block, Sector 63, Noida, Uttar Pradesh 201301', startTime: '3:00:PM', endTime: '4:00:PM' },
    { country: 'Australia', university: 'Griffith College Australia', venue: 'Amritsar', startTime: '11:15:AM', endTime: '12:30:PM' },
    { country: 'Australia', university: 'The University of Sydney', venue: 'Hyderabad', address: 'Kondapur Main Road, Laxmi Cyber City, Whitefields, Kondapur, Hyderabad, Telangana 500081', startTime: '11:00:AM', endTime: '12:00:PM' },
    { country: 'UK', university: 'Heriot Watt University', venue: 'Hyderabad', address: 'Kondapur Main Road, Laxmi Cyber City, Whitefields, Kondapur, Hyderabad, Telangana 500081', startTime: '5:00:PM', endTime: '6:30:PM' },
    { country: 'VAS Partners', university: 'NIB Health Insurance and Private Health Cover Australia', venue: 'Mumbai Thane', startTime: '12:00:PM', endTime: '12:30:PM' },
    { country: 'VAS Partners', university: 'NIB Health Insurance and Private Health Cover Australia', venue: 'Mumbai Dadar', startTime: '11:00:AM', endTime: '11:30:AM' },
    { country: 'VAS Partners', university: 'NIB Health Insurance and Private Health Cover Australia', venue: 'Mumbai Andheri', address: 'No. A3&B2, B Cross Road, Marol, Industrial Area, Mumbai, Maharashtra 400093', startTime: '11:00:AM', endTime: '11:30:AM' },
    { country: 'Australia', university: 'Engineering Institute of Technology', venue: 'Delhi South', address: 'MeetUniversity.Com, B-4, First Floor, B Block, Sector 63, Noida, Uttar Pradesh 201301', startTime: '10:00:AM', endTime: '11:00:AM' }
  ];

  constructor() {}

  getDataByCountry(country: string): any[] {
    return this.data.filter(item => item.country === country);
  }
}
