<div class="main-container">
    <section class="height-100 bg--dark text-center">
        <div class="container pos-vertical-center">
            <div class="row">
                <div class="col-md-12">
                    <h1 class="h1--large">404</h1>
                    <p class="lead">
                        The page you were looking for was not found.
                    </p>
                    <a routerLink="/home">Go back to home page</a>
                </div>
            </div>
            <!--end of row-->
        </div>
        <!--end of container-->
    </section>
</div>