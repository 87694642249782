<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="heading-block">
                    <h3>Events This Month </h3>
                </div>
            </div>
            <div class="col-md-4" *ngFor="let upcomingEvent of upcomingEventList">
                <div class="feature feature-1 boxed boxed--border">
                    <span class="label">{{ upcomingEvent.city }}</span>
                    <h5>{{ upcomingEvent.univName}}</h5>
                    <p>
                        {{ upcomingEvent.venue}}, {{ upcomingEvent.country}}
                    </p>
                    <!-- <a href="#">
                        View &amp; Apply
                    </a> -->
                </div>
                <!--end feature-->
            </div>
            <!-- <div class="col-md-4">
                <div class="feature feature-1 boxed boxed--border">
                    <span class="label bg--primary-2">Remote</span>
                    <h5>Art Director</h5>
                    <p>
                        San Francisco, USA
                    </p> -->
                    <!-- <a href="#">
                        View &amp; Apply
                    </a> -->
                <!-- </div> -->
                <!--end feature-->
            <!-- </div> -->
            <!-- <div class="col-md-4">
                <div class="feature feature-1 boxed boxed--border">
                    <span class="label">JAIPUR</span>
                    <h5>Senior Interface Designer</h5>
                    <p>
                        Toronto, CA
                    </p> -->
                    <!-- <a href="#">
                        View &amp; Apply
                    </a> -->
                <!-- </div> -->
                <!--end feature-->
            <!-- </div> -->
            <!-- <div class="col-md-4">
                <div class="feature feature-1 boxed boxed--border">
                    <span class="label">JAIPUR</span>
                    <h5>Design Internship</h5>
                    <p>
                        Melbourne, AU
                    </p> -->
                    <!-- <a href="#">
                        View &amp; Apply
                    </a> -->
                <!-- </div> -->
                <!--end feature-->
            <!-- </div> -->
            <!-- <div class="col-md-4">
                <div class="feature feature-1 boxed boxed--border">
                    <span class="label">JAIPUR</span>
                    <h5>Graphic Designer (Junior)</h5>
                    <p>
                        San Francisco, USA
                    </p> -->
                    <!-- <a href="#">
                        View &amp; Apply
                    </a> -->
                <!-- </div> -->
                <!--end feature-->
            <!-- </div> -->
        </div>
        <!--end of row-->
    </div>
    <!--end of container-->
</section>