import { Injectable } from '@angular/core';
import { apiUrl } from 'src/config/config';
import { ApiService } from '../../core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  contactUrl = apiUrl.save_enquiry;

  constructor(private ApiService: ApiService) { }

  saveEnquiry(contactData: any) {
    return this.ApiService.get(`${this.contactUrl}&name=${contactData.name}&email=${contactData.email}&phone=${contactData.phone}&query=contact&message=${contactData.message}`);
  }
}
