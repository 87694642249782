import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Observable, forkJoin, of } from 'rxjs';
interface Media {
  media_details: {
    sizes: {
      thumbnail: {
        source_url: string;
      };
    };
  };
}

@Injectable({
  providedIn: 'root'
})
export class WordPressService {
  baseUrl = 'https://blog.meetuni.com/wp-json/wp/v2';
  countryURL = environment.LMS+'/get_countries_new/format/json?client_id=mu_internal&key=0a19a3edd791626cca1fe1a33f2f2dba';
  countries: any[] = [];
  private readonly DEFAULT_THUMBNAIL = 'https://archive.org/download/placeholder-image/placeholder-image.jpg';
  constructor(private http: HttpClient) { }

  fetchCountries(): Observable<any> {
    return this.http.get(this.countryURL);
  }

  getCountryId(countries: any[], countryName: string): string {
    let country = countries.find(c => c.countryName.toLowerCase() === countryName);
    return country ? country.id : null;
  }

  fetchCountryData(countryId: string, per_page = 6): Observable<any> {
    return this.http.get<any[]>(`${this.baseUrl}/posts?categories=${countryId}&per_page=${per_page}&_embed`).pipe(
      mergeMap(posts =>
        forkJoin(
          posts.map(post =>
            this.http.get<Media>(`${this.baseUrl}/media/${post.featured_media}`).pipe(
              map(media => ({
                title: post.title.rendered,
                link: post.link,
                thumbnail: media.media_details.sizes.thumbnail.source_url,
              })),
              catchError(() => of({
                title: post.title.rendered,
                link: post.link,
                thumbnail: this.DEFAULT_THUMBNAIL,
              }))
            )
          )
        )
      ),
      catchError(() => {
        return [];
      }),
    );
  }
}