<section class="bg--secondary space--xs search-bar maintitle bar">
    <div class="container">
        <form [formGroup]="searchForm" (ngSubmit)="onSearch()">
        <div class="row">
            <button class="accordion">Advance Filters</button>
                <div class="panel">
                    <div class="mt-3 searchsection">
                        <div class="row">
                            <div class="col-md-10">
                                <input placeholder="Search Courses, Universities" autocomplete="off"
                                    formControlName="q" [ngClass]="{
                                        'is-invalid field-error': submitted && searchFormControl.q.errors && searchFormControl.q.touched
                                      }" />
                                <div *ngIf="submitted && searchFormControl.q.errors && searchFormControl.q.touched" class="invalid-feedback">
                                    <div *ngIf="searchFormControl.q.errors.required">Course name is required.</div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <button type="submit" class="btn btn--primary type--uppercase">
                                    View Result
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-4">
                            <div id="form-timeline" class="bg--secondary boxed boxed--border">
                                <h6 class="type--uppercase">Preferred Degree</h6>
                                <div class="form-group input-select">
                                    <select  formControlName="level">
                                        <option value="">Level</option>
                                        <option value="1">Master's</option>
                                        <option value="2">Bachelor's</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div id="form-timeline" class="bg--secondary boxed boxed--border">
                                <h6 class="type--uppercase">Preferred Country</h6>
                                <div class="form-group input-select">
                                    <select id="country-list" formControlName="country">
                                        <option value="">Country</option>
                                        <option value="uk">UK</option>
                                        <option value="ireland">Ireland</option>
                                        <option value="usa">USA</option>
                                        <option value="australia">Australia</option>
                                        <option value="france">France</option>
                                        <option value="germant">Germany</option>
                                        <option value="canada">Canada</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div id="form-timeline" class="bg--secondary boxed boxed--border">
                                <h6 class="type--uppercase">
                                    English Proficiency Exam(if given)
                                </h6>
                                <div class="form-group input-select">
                                    <select id="country-list"  formControlName="englishExam">
                                        <option value="">Exam</option>
                                        <option value="ielts">IELTS</option>
                                        <option value="toefl">TOEFL</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </form>
    </div>
</section>

<section class="bg--secondary space--xs search-bar maintitle">
    <div class="container">
        <div class="row">
            <div class="col-md-9">
                <h3>
                    {{ searchData?.total }} Courses in
                    {{ searchData?.univ }} Universities found
                    <span>(Showing page 1 of 987)</span>
                </h3>
                <div class="counts">
                    <span class="bold">{{ searchData?.total }} Courses</span> ::
                    <a rel="noopener" href="#" alt="/search?q=art and design&amp;country=&amp;university=true">{{
                        searchData?.univ }} Universities</a>
                </div>
            </div>
            <div class="col-md-3 text-right titleright">
                <img height="auto" width="auto" alt="image" loading="lazy" src="assets/img/icon-askjuno.png" />
            </div>
        </div>
        <hr />
    </div>
</section>
<section class="bg--secondary">
    <div class="container">
        <div class="row" *ngFor="let university of searchData?.results">
            <div class="col-md-6 col-12" *ngFor="let course of university.course">
                <div class="boxed boxed--border courselist">
                    <div class="row">
                        <div class="col-sm-3 padding">
                            <img height="auto" width="auto" alt="image" loading="lazy"
                                [src]="imgUrl+course.img"
                                class="profilepic" />
                        </div>
                        <div class="col-sm-9 padding">
                            <h4 class="cardmargin text-truncate">
                                <b>{{ course.name }}</b>
                            </h4>
                            <p class="text-truncate">
                                <a   href="javascript:void(0);" rel="noopener" routerLink="/university/{{course.univ.code}}/{{course.univ.name}}">{{ course.univ.name }}</a>
                            </p>
                            <p class="text-primary text-truncate">
                                {{ course.level == '1' ? 'Masters |' : 'Bachelors |' }}
                                {{ course.meta_data[0]?.duration }}
                            </p>
                            <div class="teffaf">
                                <div class="teffel-left smalltxt">
                                    Toefl:<br />
                                    {{ course.score.toefl }}<sup>*</sup>
                                </div>
                                <div class="teffel-left smalltxt">
                                    Ielts:<br />
                                    {{ course.score.ielts }}
                                </div>
                                <div class="teffel-left smalltxt">
                                    Intake:<br /><b>{{ course.meta_data[0]?.intake }}</b>
                                </div>
                            </div>
                            <div class="mt-3">
                                <a   routerLink="/login" class="btn mySquareBtn" href=""><span class="btn__text">
                                        <i class="icon icon--lg icon-Bookmark"></i> </span></a>
                                <a class="btn mySquareBtn" target="_blank" href="http://api.whatsapp.com/send?phone=918368229929&text=I want to know about {{
                    course.name
                  }} in {{ course.univ.name }}"><span class="btn__text">
                                        <i class="socicon-whatsapp icon"></i></span>
                                </a>
                                <a class="btn mySquareBtn">
                                    <span class="btn__text" routerLink="/ask-expert">
                                        <i class="icon-Phone-2 icon"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end item-->
        </div>
    </div>
    <!--end of container-->
</section>