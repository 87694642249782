import { Component, OnInit } from '@angular/core';
import { DisciplinesService } from '../../services/disciplines.service';
import { DisciplineList } from '../../interfaces/discipline-list';
import { ActivatedRoute, Router  } from '@angular/router';

@Component({
  selector: 'app-disciplines',
  templateUrl: './disciplines.component.html',
  styleUrls: ['./disciplines.component.scss']
})
export class DisciplinesComponent implements OnInit {
  DisciplineList: DisciplineList[] = [];
  constructor(private disciplinesService: DisciplinesService,private router: Router) { }

  ngOnInit(): void {
    this.getDisciplines();
  }

  getDisciplines() {
    this.disciplinesService.getDiscipline().subscribe((Res:any) => {
      this.DisciplineList = Res;
    });
  }

  reachedInnerPage(name:any){
    try{
      let encodedName = btoa(name);
      this.router.navigate(['disciplines/',encodedName]);
    }catch(e){
      console.info(e);
    }
  }

}
