import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router} from '@angular/router';
import { environment } from 'src/environments/environment';
import { SearchData } from '../../interfaces/search-data';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss'],
})
export class SearchPageComponent implements OnInit {
  searchData: SearchData;
  searchForm!: FormGroup;
  submitted: boolean = false;
  public imgUrl = environment.imgUrl;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    var acc = document.getElementsByClassName('accordion');
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener('click', function (this: any) {
        this.classList.toggle('active');
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + 'px';
        }
      });
    }

    this.searchForm = this.fb.group({
      q: ['', [Validators.required]],
      country: [''],
      level: [''],
      englishExam: [''],
    });

    this.route.queryParams.subscribe((params: Params) => {
      if (params.q) {
        this.searchForm.patchValue({ q: params.q });
        this.getSearchResult();
      }
    });
  }

  get searchFormControl() {
    return this.searchForm.controls;
  }

  getSearchResult() {
    //  {
    //   q: string;
    //   country: string;
    //   level: string;
    //   ielts: string;
    //   toefl: string;
    // };
    this.searchService.getSearchResult(this.searchForm.value).subscribe((response: SearchData) => {
       if (response.results) {
      //   response.results.filter((x) => {
      //     if (x.level == '1') {
      //       x.level = 'Masters';
      //     } else if (x.level == '2') {
      //       x.level = 'Bachelors';
      //     } else {
      //       x.level = '';
      //     }
      //   });
        this.searchData = response;
      }
    });
  }

  onSearch() {
    this.submitted = true;
    console.log(this.searchForm.valid, this.searchForm.value);
    if (!this.searchForm.valid) {
      return;
    }

    this.getSearchResult();
  }

}
