import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { apiUrl } from '../../../config/config';

@Injectable({
  providedIn: 'root',
})
export class CommonApplicationService {
  sendOtpUrl = apiUrl.profile_link_send_otp;
  verifyOtpUrl = apiUrl.profile_link_verify_otp;
  ContactInfoUrl = apiUrl.lms_lead_formfill_updated;
  phone_change = apiUrl.phone_change;

  constructor(private ApiService: ApiService) {}

  sendOtp(jsonData: any) {
    return this.ApiService.get(
      this.sendOtpUrl + '&token=' + jsonData.token + '&phone=' + jsonData.phone
    );
  }

  verifyOtp(jsonData: any) {
    return this.ApiService.get(
      this.verifyOtpUrl + '&token=' + jsonData.token + '&code=' + jsonData.code
    );
  }

  getData(jsonData: any) {
    return this.ApiService.get(
      this.ContactInfoUrl + '&token=' + jsonData.token
    );
  }

  onChangePhone(jsonData: any) {
    return this.ApiService.get(`${this.phone_change}&token=${jsonData.token}&new_phone=${jsonData.new_phone}`);
  }

}
