<div class="main-container">
  <section class="container">

      <div class="row">
        <div  class="col-md-5">
            <div class="mt--5">
                <img
                  height="auto"
                  width="auto"
                  alt="image"
                  loading="lazy"
                  class="img-responsive"
                  src="assets/img/application.jpg"
                />
               
              </div>
            </div>
 
<div class="col-md-7">
    <div id="intro-box" class="boxed boxed--border  bg--secondary boxed--lg box-shadow">
        <h5>Get Timeline | Upload IELTS</h5>
        <p>
            Application timeline serves as a useful tool to manage your time and energy. Focus on priorities and keep a track of everything which is pending.                               
        </p>
            <a class="btn btn--primary">
            <span class="btn__text">
                Rs. <span class="type--strikethrough"> 999 </span>199
            </span>
        </a>
        <a aria-disabled="true" class="btn btn--primary">
            <span class="btn__text">
                Upload Ielts
            </span>
        </a>
    </div>


    <div id="form-timeline"  class="bg--secondary boxed boxed--border">
        <input type="hidden" id="type" name="type" autocomplete="off" value="intake">
        <h6 class="type--uppercase">WHICH COUNTRY ARE YOU TARGETTING?</h6>
        <div class="form-group input-select">
            <select   id="country-list" name="country">
                <option   [value]="data.id" *ngFor="let data of CountryList" >{{ data.countryName }}</option>
                </select>
        </div>
         <h6  class="type--uppercase intake">WHICH INTAKE ARE YOU TARGETTING?</h6>   
        <div class="form-group input-select intake">
           
            <select name="intake" id="intakes"><option disabled="" selected="" value="">Select Intake</option> <option    *ngFor="let data of IntakeList" >{{ data }}</option></select>
        </div>
        <button id="btn-text"  type="submit" class="btn btn--primary type--uppercase mrginFromTop">Create Timeline</button> 
</div>


<div id="login-box" class="boxed boxed--border">
    <h5>Awesome</h5>
    <p>
        Your timeline has been created and added to your dashboard. Please login to save and continue:
    </p>
    <a class="btn btn--primary">
        <span class="btn__text">
            Login
        </span>
    </a>
</div>
 </div>
</div>
  </section>




</div>