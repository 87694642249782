<div class="main-container">
  <section class="text-center imagebg" data-overlay="4">
    <div class="background-image-holder" style="background: url(&quot;assets/img/premium.jpg&quot;); opacity: 1;">
      <img
        height="auto"
        width="auto"
        alt="image"
        loading="lazy"
        src="assets/img/premium.jpg"
      />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <h1>PREMIUM COLLEGE COUNSELING</h1>
          <p>
            REMOVE YOUR DOUBTS AND CONFUSIONS WITH PREMIUM COLLEGE COUNSELING TO
            BE IN THE BEST COLLEGE
          </p>
          <a class="btn btn--primary type--uppercase" (click)="scroll(target)"
            ><span class="btn__text">JUMP TO PRICING/DETAILS</span></a
          >
        </div>
      </div>
      <!--end of row-->
    </div>
    <!--end of container-->
  </section>

  <section>
    <div id="pricing" class="tabs-container text-center" #target>
      <h2 id="services mb-4">Premium Services Packages</h2>

      <div class="container">
        <div class="row">
          <div class="col-sm-6 col-md-4">
            <div class="pricing pricing-3">
              <div class="pricing__head bg--secondary boxed">
                <h5>Basic</h5>
                <span class="h1">
                  <span class="pricing__dollar">₹</span>6,999</span
                >
                <p class="type--fine-print">
                  Assistance with Statement of Purpose (SOP)
                </p>
              </div>
              <ul>
                <li><span>Guidelines</span></li>
                <li><span>Samples</span></li>
                <li><span>20 Minutes Session</span></li>
                <li>
                  <span
                    >Basic Pointers for Generic SOP - 0
                    Universities</span
                  >
                </li>
                <li><span>Proof Reading</span></li>
                <li><span>Valid For : 1 month</span></li>
                <li><span>Loan Assistance</span></li>
                <li><span>VISA Assistance</span></li>
                <li>
                  <a
                    class="btn btn--primary type--uppercase"
                    href="https://imjo.in/pkr6GA"
                    target="_blank"
                    rel="noopener"
                    ><span class="btn__text">Purchase</span></a
                  >
                </li>
              </ul>
            </div>
            <!--end pricing-->
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="pricing pricing-3">
              <div class="pricing__head bg--secondary boxed">
                <h5>ULTRA</h5>
                <span class="h1">
                  <span class="pricing__dollar">₹</span>14,999</span
                >
                <p class="type--fine-print">
                  Assistance with Statement of Purpose (SOP)
                </p>
              </div>
              <ul>
                <li><span>Guidelines</span></li>
                <li><span>Samples</span></li>
                <li><span>60 Minutes Session</span></li>
                <li><span>Free IELTS Coaching</span></li>
                <li>
                  <span
                    >Customized Pointers for Generic SOP - 5
                    Universities</span
                  >
                </li>
                <li><span>Proof Reading</span></li>
                <li><span>Editing</span></li>
                <li><span>Valid For : 6 months</span></li>
                <li>
                  <a
                    class="btn btn--primary type--uppercase"
                    href="https://imjo.in/BfcZUf"
                    target="_blank"
                    rel="noopener"
                    ><span class="btn__text">Purchase</span></a
                  >
                </li>
              </ul>
            </div>
            <!--end pricing-->
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="pricing pricing-3">
              <div class="pricing__head bg--primary boxed">
                <span class="label">Value</span>
                <h5>Elite</h5>
                <span class="h1">
                  <span class="pricing__dollar">₹</span>74,999</span
                >
                <p class="type--fine-print">
                  Counseling &amp; Applications – MBA
                </p>
              </div>
              <ul>
                <li><span>120 Minutes of Counseling.</span></li>
                <li><span>Mock MBA Interview</span></li>
                <li><span>Free IELTS Coaching</span></li>
                <li>
                  <span
                    >University Short-listing : 6 Universities (Based on
                    Profile Evaluation)</span
                  >
                </li>
                <li><span>Exclusive Online Materials.</span></li>
                <li><span>Documents Evaluation</span></li>
                <li><span>CV Review (1 Round)</span></li>
                <li><span>SOP Review (2 Rounds)</span></li>
                <li><span>MBA Essay Review (2 Round)</span></li>
                <li><span>LOR Review (1 Round)</span></li>
                <li><span>MBA Preparation Interview</span></li>
                <li><span>Application Processing</span></li>
                <li><span>University Follow Up</span></li>
                <li><span>Loan Assistance</span></li>
                <li><span>VISA Assistance</span></li>
                <li><span>Pre-Departure Services</span></li>
                <li>
                  <a
                    class="btn btn--primary type--uppercase"
                    href="https://imjo.in/ASyHMN"
                    target="_blank"
                    rel="noopener"
                    ><span class="btn__text">Purchase</span></a
                  >
                </li>
              </ul>
            </div>
            <!--end pricing-->
          </div>
        </div>
        <!--end of row-->
      </div>
    </div>
  </section>
</div>
