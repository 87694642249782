import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import {NgxPaginationModule} from 'ngx-pagination';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { PublicRoutingModule } from './public-routing.module';
import { PublicComponent } from './public.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { FormsModule,  ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AskExpertListComponent } from './components/ask-expert-list/ask-expert-list.component';
import { AskJunoComponent } from './components/ask-juno/ask-juno.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { CountryPagesComponent } from './components/country-pages/country-pages.component';
import { AboutComponent } from './components/about/about.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { TermOfServiceComponent } from './components/term-of-service/term-of-service.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { PremiumServicesComponent } from './components/premium-services/premium-services.component';
import { AskJunoChatComponent } from './components/ask-juno-chat/ask-juno-chat.component';
import { UpcomingEventsComponent } from './components/upcoming-events/upcoming-events.component';
import { VideosComponent } from './components/videos/videos.component';
import { TestPrepComponent } from './components/test-prep/test-prep.component';
import { GuidePagesComponent } from './components/guide-pages/guide-pages.component';
import { SearchPageComponent } from './components/search-page/search-page.component';
import { HomeComponent } from './components/home/home.component';
import { UniversityComponent } from './components/university/university.component';
import { CoursesComponent } from './components/courses/courses.component';
import {HttpClientModule} from '@angular/common/http'
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BookingComponent } from './components/booking/booking.component';
import { DisciplinesComponent } from './components/disciplines/disciplines.component';
import { DisciplinesInnerComponent } from './components/disciplines-inner/disciplines-inner.component';
import { PopupVideoComponent } from './components/disciplines-inner/popup-video/popup-video.component';
import { StatementPurposeComponent } from './components/statement-purpose/statement-purpose.component';
import { AskExpertComponent } from './components/ask-expert/ask-expert.component';
import { PreCounselComponent } from './components/pre-counsel/pre-counsel.component';
import { CommonApplicationFormComponent } from './components/common-application-form/common-application-form.component';
import { UniversityListComponent } from './components/university-list/university-list.component';
import { ScholarshipComponent } from './components/scholarship/scholarship.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    PublicComponent,
    ForgotPasswordComponent,
    LoginComponent,
    NotFoundComponent,
    AskExpertListComponent,
    AskJunoComponent,
    TimelineComponent,
    SignUpComponent,
    CountryPagesComponent,
    AboutComponent,
    ContactUsComponent,
    TermOfServiceComponent,
    DisclaimerComponent,
    PremiumServicesComponent,
    AskJunoChatComponent,
    UpcomingEventsComponent,
    VideosComponent,
    TestPrepComponent,
    GuidePagesComponent,
    SearchPageComponent,
    HomeComponent,
    UniversityComponent,
    CoursesComponent,
    BookingComponent,
    DisciplinesComponent,
    DisciplinesInnerComponent,
    PopupVideoComponent,
    StatementPurposeComponent,
    AskExpertComponent,
    PreCounselComponent,
    CommonApplicationFormComponent,
    UniversityListComponent,
    ScholarshipComponent,  
  ],
  imports: [
    CommonModule,
    PublicRoutingModule,
    FormsModule ,
    ReactiveFormsModule,
    SharedModule,
    HttpClientModule,
    AccordionModule,
    TabsModule,
    ModalModule,
    NgxPaginationModule,
    CarouselModule,
    NgxSliderModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot() 
  ],
  providers: [BsModalService],
})
export class PublicModule { }
