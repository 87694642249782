<div class="main-container">
  <section class="text-center imagebg space--lg" data-overlay="3">
    <div class="background-image-holder" style="background: url(&quot;assets/img/premium.jpg&quot;); opacity: 1;">
      <img alt="background" src="assets/img/premium.jpg" />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-lg-6">
          
          <p class="lead">
            “Crazy bunch of people, demystifying education engagement & management”
          </p>
        </div>
      </div>
      <!--end of row-->
    </div>
    <!--end of container-->
  </section>
  <section class="text-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-8">
          <h2>Built on passion and ingenuity</h2>
          <p class="lead">
            Founded by Ivy League Alumnus and two Eduprenurs in 2013, MeetUniversity.com is the passion and technology driven platform for Study Abroad Counseling, University/Course Search and Application Process. The trio identified the demand in the Indian market for an easy, unbiased and personalized university/college search and application consulting portal mentored by Study Abroad Experts.
          </p>
          <p class="lead">Our core Team Members, advisors and associates have studied and worked in countries like UK, USA, Europe, Australia – we have been there and done it ourselves. We understand the expectations and requirements of the Indian students; and similarly we have got in-depth knowledge about the study abroad process.</p>

        </div>
      </div>
      <!--end of row-->
    </div>
    <!--end of container-->
  </section>
 
  <section class="text-center bg--secondary">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-8">
          <h2>What drives us</h2>
          <p class="lead">
            It’s all online – time and cost-effective. Our Mentors & Advisors – have been to top and prestigious institutes like University of Illinois at Urbana-Champaign, University of Oxford, Imperial College London, University of New South Wales, Utrecht University etc.
          </p>
          <p class="lead">We help you to get admitted at not just a foreign university, but at your dream university. We know what it takes to crack the tests (SAT / GRE / GMAT / IELTS / TOEFL) and beat the competition. We offer personalized mentoring and customized admission assistance according to your needs, including premium services.</p>
        <p class="lead">Get connected with Universities, Current Students and Alumni directly for first-hand information, reviews, experience and success stories. Get in-depth information through our blogs, videos and webinars.</p>
        </div>
      </div>
      <!--end of row-->
    </div>
    <!--end of container-->
  </section>
  <section class="bg--secondary">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="feature">
            <h4>Student Discovery</h4>
            <p>
              For Universities: Student discovery is not a problem anymore with MeetUniversity.Com. Get ideal / shortlisted right student profiles. We are the largest university personalization portal of India, no one knows it better how to reach out to the right student audience than us.
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="feature">
            <h4>Student Counseling</h4>
            <p>
              We help students to connect directly with list of top universities in Australia, USA, UK, Canada, Singapore and Dubai. Fair, independent, unbiased advise to make your college selection process a joy ride instead of a confusing & frustrating process. Multiple modes of connect with students, in sync with your recruitment strategy. We work with multiple universities world over. Get in touch with us to understand how we can get you the ideal student matches.
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="feature">
            <h4>MeetUniversity.com for Students</h4>
            <p>
              MeetUniversity.com came together because we saw nothing changing and students still confused. The best way to get advice was hard to find. So we decided to take things in our own hands, offer free expert guidance, scholarships & exam preparation for IELTS, GRE, GMAT and many more - backed with verified & relevant data to help you to meet universities for study abroad.
            </p>
          </div>
        </div>
      </div>
      <!--end of row-->
    </div>
    <!--end of container-->
  </section>
  <section class="text-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6">
          <div class="cta">
            <h2>We're always looking for talent</h2>
            <p class="lead">
              Got what it takes to work with us? Great! Send us a link to your
              resumé or portfolio to become part of our talent pool.
            </p>
            <a class="btn btn--primary type--uppercase" href="#" routerLink='/contact' routerLinkActive="active">
              <span class="btn__text"> See Job Openings </span>
            </a>
          </div>
        </div>
      </div>
      <!--end of row-->
    </div>
    <!--end of container-->
  </section>
</div>
