<section>
  <div class="container">
    <div class="row justify-content-between">
      <div class="col-md-12">
        <div class="heading-block">
          <h3>Terms of Service</h3>
        </div>
      </div>
      <button class="accordion">GENERAL</button>
      <div class="panel">
        <p class="lead">
          <strong>“www.meetuniversity.com” </strong>(hereinafter, the “Website”) is owned and operated by
						Beyond Horizons Educonnect Private Limited, a private limited company incorporated under the
						Companies Act, 1956, having its registered address at 1/22 ,Asif Ali Road New Delhi 110002,
						India and corporate address at B-4, First Floor, B Block, Sector 63, Noida, Uttar Pradesh 201301, Uttar
						Pradesh, India (hereinafter referred to as the “Company”)
						<br>
						You are advised to read and understand these Terms carefully as moving past the home page, or
						using any of the services shall be taken to mean that You have read and agreed to all of the
						policies, which constitute a legally binding agreement between you and the website. These terms
						are to be read along with the Privacy Policy and any other policies on the website.
						<br>In terms of Information Technology statutes, this document is an electronic record, under
						the laws of India. This document is published in accordance with the provisions of Rule 3 (1) of
						the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the
						rules and regulations, privacy policy and Terms of Use for access or usage of the Website.
						<br>For the purpose of these Terms of Use, along with any amendments to the same, and wherever
						the context so requires "You" or "User" shall mean any natural or legal person who has agreed to
						become a user of the Website by accessing the Website. The Website also provides certain
						services without registration/acceptance, and such provision of services does not absolve You of
						this contractual relationship.
						<br>The term "We", "Us", "Our" shall mean the Company. “Agreement” shall mean and refer to this
						Terms of Service, including any amendments that may be incorporated into it. “Services” shall
						mean and refer to the services offered by the Company to the Users, including but not limited to
						web-based Study Abroad Counselling, University/course Search and application Process. “Third
						Party” shall mean and refer to any individual(s), company or entity apart from the User and the
						Company.
						The headings of each section in this Agreement are only for the purpose of organising the
						various provisions under this Agreement in an orderly manner. These headings shall not be used
						by either party to interpret the provisions contained under them in any manner. Further, the
						headings have no legal or contractual value.
						<br>By using the Website, You accept and agree to be bound by this Agreement, the Privacy
						Policy, as well as rules, guidelines, policies, terms, and conditions applicable to any service
						that is provided by this Website, that shall be deemed to be incorporated into this Terms of Use
						and shall be considered as part and parcel of this Terms of Use. Your use of Our Website is
						evidence that You have read and agreed to be contractually bound by these Terms of Service and
						our Privacy Policy. Please read both carefully. The use of this Website by You is governed by
						this policy and any policy so mentioned by terms of reference. If You do not agree with any of
						these terms, please discontinue using the Website.
						<br>We hold the sole right to modify the Terms of Service without prior permission from You or
						providing notice to You. The relationship creates on You a duty to periodically check the Terms
						of Service and stay updated on its requirements. If You continue to use the Website or avail any
						of its services without registration following such change, this is deemed as consent by You to
						the so amended policies. Your continued use of the Website is conditioned upon Your compliance
						with the Terms of Service, including but not limited to compliance with the Terms of Service
						even after alterations, if any.
					</p>

      </div>
      <button class="accordion">SERVICES OVERVIEW</button>
      <div class="panel">
        <p class="lead">
          The Website is a web-based platform providing, including but not limited to study abroad
					counselling, university/course search and application process. It is an online medium, through which
					students can apply to the universities of their choice. the Website helps the students in cracking
					the international tests like SAT / GRE / GMAT / IELTS / TOEFL which is required to join universities
					abroad.
					<br>The Website also provides a personalised mentoring service and customised admission assistance
					according to the needs of the students and other premium services. The Students get a chance to
					interact with the universities, current students and alumni of such universities and get their
					feedback and reviews through the Website.
					<br>The User can search from a broad list of University and short list universities based on the
					User’s interest. The User can create a profile using the online tools and applications available on
					the Website. If the User requires any help in applying to any university, he could either fill the
					online query form or call on the customer care number 011 4107 8540 after which he would be guided
					in his application process and get an admission at the university of his choice.
					<br>The Website provides premium service packages based on the requirements of the students which
					involving SOP writing and premium counselling services for MBA, MS & PhD courses offered in
					universities abroad.
        </p>
      </div>
      <button class="accordion">MEMBERSHIP</button>
      <div class="panel">
        <p class="lead">
          Registration on the Website is not mandatory. A non registered User can browse through the content
					of the Website. A registered User can avail the premium services of the Website, or connect with the
					counsellors and experts of the Universities. Only a registered User of the Website can apply for
					admission into any of the listed Universities. Basic information like name, user name, password,
					mobile number, address, citizenship, educational qualification would be collected from the User at
					the time of registration. The Email ID provided during registration can be of any Email service
					provider including but not limited to Gmail, and Yahoo mail. You must keep your account and
					registration details current and correct.
        </p>
      </div>
      <button class="accordion">DISCLAIMER REGARDING SERVICES</button>
      <div class="panel">
        <p class="lead">
          The Website is just an intermediary, bridging the gap between student desiring to study abroad and
					universities in different countries. The Website does not provide any educational courses of its own
					or recommends any university to the students. The Website does not represent any university or
					educational institute, it merely provides information about such universities.
					<br>

					The Website does not guaranty admission to any of the universities or educational institutes, it
					merely helps students in applying to these universities. You understand and agree that the admission
					to any course or program You apply for in any university, shall be based on the terms and condition
					of that particular university and the Website has no authority to intervene in such matters.
					<br>
					You understand that once you make any payment towards fee or for application process through the
					Website, there would be no refund of the same. All costs including but not limited to for any
					courier charges, document processing fee, Visa application Fee or any other charges incurred by the
					Website shall be borne by the User/Student.
        </p>
      </div>
      
      <button class="accordion"> TERM/TERMINATION</button>
      <div class="panel">
      <p class="lead">
        These Terms shall continue to form a valid and binding contract between the Parties, and shall
        continue to be in full force and effect until the User continues to access and use the Website.
      </p>
      </div>
      <button class="accordion">SECURITY</button>
      <div class="panel">
      <p class="lead">
        Transactions on the Website are secure and protected. Any information entered by the User when
					transacting on the Website is encrypted to protect the User against unintentional disclosure to
					third parties. The User’s credit and debit card information is not received, stored by or retained
					by the Company / Website in any manner.
      </p>
     
      </div>
      <button class="accordion"> COMMUNICATIONS AND ADVERTISEMENTS</button>
      <div class="panel">

      <p class="lead">
        By using this Website, it is deemed that You have consented to receiving telephonic calls, SMSs
        and/or emails from Us at any time we deem fit. We shall reach out to communicate with You and
        enhance Your experience while using the Website. From time to time, the Company may display server
        advertisements within the Website and also during the use of the Website. These advertisements shall
        also inform You about the new products and services released by us. These communications include,
        but are not limited to contacting You through information received from Third Parties. Such
        communications by Us is for purposes that inter alia include clarification calls, marketing calls
        and promotional calls. These communications shall be sent to You on the telephone number and/or
        email id provided by You for the use of this Website which are subject to our Privacy Policy. In
        case You wish to stop receiving notifications from Us with regard to marketing and promotional calls
        with regard to any communication received from Us, You are required to send an email to
        <b>Connect@meetuniversity.com</b>.
        You may also be contacted by Service Providers with whom We have entered into a contract in
        furtherance of our rights, duties and obligations under this document and other policies followed by
        Us and with whom You have become friends with from the website. Such contact shall be made only in
        pursuance of such objectives, and no other calls shall be made.
        <br>
        In addition, You may also be contacted by Third Parties who may have access to the information
        disclosed by You or to whom We may have disclosed Your information for purposes such as, but not
        limited to, statistical compilations. The sharing of the information provided by You shall be
        governed by our Privacy Policy.
    </p>
      </div>
      <button class="accordion">USER’S OBLIGATIONS</button>
      <div class="panel">
        <p class="lead">
          You are a restricted user of this Website.
        </p>
        <ul>
          <li class="lead">a. You are bound not to cut, copy, distribute, modify, recreate, reverse engineer,
            distribute, disseminate post, publish or create derivative works from, transfer, or sell any
            information or software obtained from the Website. With our prior permission limited use may
            be allowed. For the removal of doubt, it is clarified that unlimited or wholesale
            reproduction, copying of the content for commercial or non-commercial purposes and
            unwarranted modification of data and information within the content of the Website is not
            permitted.
          </li>
          <li class="lead">
            b. You agree not to access (or attempt to access) the Website or Services by any means other
            than through the interface that is provided by the Website. The use of deep-link, robot,
            spider or other automatic device, program, algorithm or methodology, or any similar or
            equivalent manual process, to access, acquire, copy or monitor any portion of the Website or
            Content, or in any way reproduce or circumvent the navigational structure or presentation of
            the Website, materials or any Content, to obtain or attempt to obtain any materials,
            documents or information through any means not specifically made available through the
            Website. You acknowledge and agree that by accessing or using the Website or Services, You
            may be exposed to content from other users that You may consider offensive, indecent or
            otherwise objectionable. We disclaim all liabilities arising in relation to such offensive
            content on the Website. Further, You may report such offensive content.

          </li>
          <li class="lead">
            c. In places where this Website allows you to post or upload data/information, you undertake
            to ensure that such material is not offensive and in accordance with applicable laws.
            Further, you undertake not to:
          <li class="lead">
            i. Abuse, harass, threaten, defame, disillusion, erode, abrogate, demean or otherwise
            violate the legal rights of others.

          </li>
          <li class="lead">
            ii. Engage in any activity that interferes with or disrupts access to the Website or the
            Services (or the servers and networks which are connected to the Website).

          </li>
          <li class="lead">
            iii. Impersonate any person or entity, or falsely state or otherwise misrepresent your
            affiliation with a person or entity.

          </li>
          <li class="lead">
            iv. Publish, post, disseminate, any information which is grossly harmful, harassing,
            blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another's
            privacy, hateful, or racially, ethnically objectionable, disparaging, relating or
            encouraging money laundering or gambling, or otherwise unlawful in any manner whatever or
            unlawfully threatening or unlawfully harassing including but not limited to "indecent
            representation of women" under the relevant laws of Dubai, United Arab Emirates.

          </li>

          <li class="lead">
            v. Post any file that infringes the copyright, patent or trademark of other legal entities.

          </li>
          <li class="lead">
            vi. Upload or distribute files that contain viruses, corrupted files, or any other similar
            software or programs that may damage the operation of the Website or another's computer.

          </li>
          <li class="lead">
            vii. Download any file posted by another user that you know, or reasonably should know,
            cannot be legally distributed in such manner.

          </li>

          <li class="lead">
            viii. Probe, scan or test the vulnerability of the Website or any network connected to the
            Website, nor breach the security or authentication measures on the Website or any network
            connected to the Website. You may not reverse look-up, trace or seek to trace any
            information on any other user, of or visitor to, the Website, or any other customer of the
            website, including any website Account not owned by You, to its source, or exploit the
            Website or Service or information made available or offered by or through the Website, in
            any way whether or not the purpose is to reveal any information, including but not limited
            to personal identification information, other than Your own information, as provided for by
            the Website.

          </li>
          <li class="lead">
            ix. Disrupt or interfere with the security of, or otherwise cause harm to, the Website,
            system resources, accounts, passwords, servers or networks connected to or accessible
            through the Website or any affiliated or linked sites.

          </li>
          <li class="lead">
            x. Collect or store data about other users in connection with the prohibited conduct and
            activities set forth in this Section.

          </li>
          <li class="lead">
            xi. Use the Website or any material or Content for any purpose that is unlawful or
            prohibited by these Terms of Service, or to solicit the performance of any illegal activity
            or other activity which infringes the rights of this Website or other third parties.

          </li>
          <li class="lead">
            xii. Violate any code of conduct or other guidelines, which may be applicable for or to any
            particular Service.

          </li>
          <li class="lead">
            xiii. Violate any applicable laws or regulations for the time being in force within or
            outside INDIA.

          </li>

          <li class="lead">
            xiv. Violate the Terms of Service including but not limited to any applicable Additional
            Terms of the Website contained herein or elsewhere.

          </li>
          <li class="lead">
            xv. Violate any code of conduct or other guidelines, which may be applicable for or to any
            particular Service.

          </li>
          <li class="lead">
            xvi. Threaten the unity, integrity, defense, security or sovereignty of INDIA or any other
            country, friendly relations with foreign states, or public order or cause incitement to the
            commission of any cognizable offence or prevent investigation of any offence or is insulting
            any other nation.

          </li>
          <li class="lead">
            xvii. Publish, post, disseminate information that is false, inaccurate or misleading violate
            any applicable laws or regulations for the time being in force in or outside INDIA.

          </li>
          <li class="lead">
            xviii. Directly or indirectly, offer, attempt to offer, trade or attempt to trade in any
            item, the dealing of which is prohibited or restricted in any manner under the provisions of
            any applicable law, rule, regulation or guideline for the time being in force.

          </li>
          <li class="lead">
            xix. Create liability for us or cause us to lose (in whole or in part) the services of our
            internet service provider ("ISPs") or other suppliers.

          </li>
            
        </ul>
        <P class="lead">You shall not engage in advertising to, or in solicitation of, other Users of the Website to buy
          or sell any products or services, including, but not limited to, products or services related to
          that being displayed on the Website or related to us. You may not transmit any chain letters or
          unsolicited commercial or junk email to other Users via the Website. It shall be a violation of
          these Terms of Service to use any information obtained from the Website in order to harass,
          abuse, or harm another person, or in order to contact, advertise to, solicit, or sell to another
          person other than Us without Our prior explicit consent. We can (and You hereby expressly
          authorize Us to disclose any information about You to law enforcement or other government
          officials, as we, in our sole discretion, believe it necessary or appropriate in connection with
          the investigation and/or resolution of possible crimes, especially those that may involve
          personal injury. In order to protect Our Users from such advertising or solicitation, we reserve
          the right to restrict the number of messages or emails which a user may send to other Users in
          any 24-hour period which We deem appropriate in our sole discretion. You understand that We have
          the right at all times to disclose any information (including the identity of the persons
          providing information or materials on the Website) as necessary to satisfy any law, regulation
          or valid governmental request. This may include, without limitation, disclosure of the
          information in connection with investigation of alleged illegal activity or solicitation of
          illegal activity or in response to a lawful court order or subpoena.
          <br>We have no obligation to monitor the materials posted on the Website. We shall have the
          right to remove or edit any content that in its sole discretion violates, or is alleged to
          violate, any applicable law or either the spirit or letter of these Terms of Service.
          Notwithstanding this right, YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU
          POST ON THE WEBSITE AND IN YOUR PRIVATE MESSAGES. In no event shall we assume or have any
          responsibility or liability for any Content posted or for any claims, damages or losses
          resulting from the use of Content and/or appearance of Content on the Website. You hereby
          represent and warrant that you have all necessary rights in and to all content which You provide
          and all information it contains and that such Content shall not infringe any proprietary or
          other rights of third parties or contain any libelous, tortuous, or otherwise unlawful
          information.</P>
      </div>
      <button class="accordion"> DISCLAIMERS REGARDING SERVICES</button>
      <div class="panel">
        <p class="lead">
          The Company accepts no liability for any errors or omissions, whether on behalf of itself or third
					parties. You acknowledge that the Company has no relationship with you other than to providing you
					with this service. At no time shall the Company have any right, title or interest to any bookings
					done by you. The Company does not have any control over the quality, failure to provide or any other
					aspect whatsoever of the booked service and is not responsible for damages or delays.
					<br>

					The Website or the Company shall not be held liable for any delay/default/failure to provide service
					/ improper or inefficient service provided by the hospitals, clinics or treatment centres. The
					Hospitals, clinics and the Treatment Institutions shall be solely responsible for any or all of the
					treatment provided by them.
					<br>
					The User is required to provide his entire medical background to the Hospitals, Clinics and/or the
					Treatment Institution. The Hospitals, Clinics or Treatment Centres shall not be responsible for any
					allergic reactions due to any undisclosed history of the User or the patient. The Website claims no
					responsibility if the treatment cost, or the number of days of the treatment extends from the
					stipulated time period.
					<br>

					The Website is just an intermediary and cannot be held responsible for any bookings made for travel
					or lodging services provided by Third Party. Any complaints of the travel or lodging services are to
					be made directly to the Third Party providing such services.
        </p>
      </div>
      <button class="accordion">REVIEWS, FEEDBACK AND SUBMISSIONS</button>
      <div class="panel">
        <p class="lead">
          All reviews, comments, feedback, suggestions, ideas, and other submissions disclosed, submitted or
					offered on the Site or otherwise disclosed, submitted or offered in connection with use of the Site
					(collectively, the Comments) shall be and remain the property of the Company. Such disclosure,
					submission or offer of any Comments shall constitute an assignment to the Company of all worldwide
					rights, titles and interests in all copyrights and other intellectual properties in the Comments.
					Thus, the Company shall exclusively own all such rights, titles and interests in the Comments and
					shall not be limited in any way in its use, commercial or otherwise.
        </p>
      </div>
      <button class="accordion"> INTELLECTUAL PROPERTY RIGHTS</button>
      <div class="panel">
        <p class="lead">
          All information, content, services and software displayed on, transmitted through, or used in
					connection with the Website, (hereinafter referred to as the "Content"), as well as its selection
					and arrangement, is owned by Us. You may use the Content only through the Website, and solely for
					Your personal, non-commercial use.
					<br>
					You may not, republish any portion of the Content on any Internet, Intranet or extranet site or
					incorporate the Content in any database, compilation, archive or cache. You may not distribute any
					Content to others, whether or not for payment or other consideration, and You may not modify copy,
					frame, cache, reproduce, sell, publish, transmit, display or otherwise use any portion of the
					Content. You may not scrape or otherwise copy our Content without permission. You agree not to
					decompile, reverse engineer or disassemble any software or other products or processes accessible
					through the Website, not to insert any code or product or manipulate the content of the Website in
					any way that affects the user's experience, and not to use any data mining, data gathering or
					extraction method.
        </p>
      </div>
      <button class="accordion">   GEOGRAPHICAL EXTENT</button>
      <div class="panel">
        <p class="lead">
          The Website can be used in all countries worldwide. We make no representation that the content
					available through our Website is appropriate or available for all countries. If You access or use
					the Website in any countries where the Website is prohibited, You are solely responsible for
					compliance with necessary laws and regulations for use of the Website.
        </p>
      </div>
      <button class="accordion">DISCLAIMERS OF WARRANTIES AND LIABILITIES</button>
      <div class="panel">
        <p class="lead">
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW:
					<br>
					THE WEBSITE, SERVICES AND OTHER MATERIALS ARE PROVIDED BY THIS WEBSITE IS ON AN "AS IS" BASIS
					WITHOUT WARRANTY OF ANY KIND, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING THE IMPLIED
					WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. WITHOUT
					LIMITING THE FOREGOING, WEBSITE MAKES NO WARRANTY THAT
        </p>
      </div>
      <button class="accordion">   CONTACT US</button>
      <div class="panel">
        <p class="lead">
          Stack follows the BEM naming convention that focusses on logical
          code readability that is reflected in both the HTML and CSS.
          Interactive elements such as accordions and tabs follow the same
          markup patterns making rapid development easier for developers
          and beginners alike.
        </p>
        <p class="lead">
          Add to this the thoughtfully presented documentation, featuring
          code highlighting, snippets, class customizer explanation and
          you've got yourself one powerful value package.
        </p>
      </div>
      

      <button class="accordion"> INDEMNIFICATION AND LIMITATION OF LIABILITY</button>
      <div class="panel">
        <p class="lead">
          YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS THIS WEBSITE/COMPANY INCLUDING BUT NOT LIMITED TO
					ITS AFFILIATE VENDORS, AGENTS AND EMPLOYEES FROM AND AGAINST ANY AND ALL LOSSES, LIABILITIES,
					CLAIMS, DAMAGES, DEMANDS, COSTS AND EXPENSES (INCLUDING LEGAL FEES AND DISBURSEMENTS IN CONNECTION
					THEREWITH AND INTEREST CHARGEABLE THEREON) ASSERTED AGAINST OR INCURRED BY US THAT ARISE OUT OF,
					RESULT FROM, OR MAY BE PAYABLE BY VIRTUE OF, ANY BREACH OR NON-PERFORMANCE OF ANY REPRESENTATION,
					WARRANTY, COVENANT OR AGREEMENT MADE OR OBLIGATION TO BE PERFORMED BY YOU PURSUANT TO THESE TERMS OF
					SERVICE. FURTHER, YOU AGREE TO HOLD US HARMLESS AGAINST ANY CLAIMS MADE BY ANY THIRD PARTY DUE TO,
					OR ARISING OUT OF, OR IN CONNECTION WITH, YOUR USE OF THE WEBSITE, ANY CLAIM THAT YOUR MATERIAL
					CAUSED DAMAGE TO A THIRD PARTY, YOUR VIOLATION OF THE TERMS OF SERVICE, OR YOUR VIOLATION OF ANY
					RIGHTS OF ANOTHER, INCLUDING ANY INTELLECTUAL PROPERTY RIGHTS.
        </p>
      </div>


      <button class="accordion">DISPUTES ARISING OUT OF THIS AGREEMENT</button>
      <div class="panel">
        <p class="lead">
          All disputes involving but not limited to rights conferred, compensation, refunds, and other claims
					will be resolved through a two-step Alternate Dispute Resolution mechanism.
					<br>
					<b>Stage 1: Mediation.</b> In case of a dispute, the matter will first be attempted to be resolved
					by a sole mediator who is a neutral third party and will be selected at the mutual acceptance of a
					proposed mediator by both parties. Both parties may raise a name for the sole mediator and in the
					case both parties accept the proposed name, the said person shall be appointed as sole mediator. In
					case the parties are not able to reach a consensus within two proposed mediators, the sole
					proprietorship reserves the right to decide who the final mediator is. The decision of the mediator
					is not binding on both parties.
					<br>
					<b>Stage 2: Arbitration.</b> If the dispute is not settled by mediation, it shall be referred to and
					finally resolved by arbitration, which shall be governed by the law, rules and regulations of Delhi,
					India. The Arbitral award is binding on both parties. The Arbitration Board shall comprise three
					members – one appointed by each party and the third member to be nominated by the two appointed
					members by mutual consent. Arbitration shall be held at Delhi. The proceedings of arbitration shall
					be in the English language. The arbitrator’s award shall be final and binding on the Parties.
					<br>
					The exclusive jurisdiction and venue for actions and disputes mentioned above shall be the courts
					located in Delhi, India and You hereby submit to the personal jurisdiction of such courts.
        </p>
      </div>

      <button class="accordion">PRIVACY</button>
      <div class="panel">
        <p class="lead">
          We encourage You to read the Privacy Policy and to use the information it contains to make informed
					decisions regarding Your personal information. Please also note that certain information,
					statements, data and content (such as but not limited to videos) which You provide on the Website
					are likely to reveal Your gender, ethnic origin, nationality, age, and/or other personal information
					about You. You acknowledge and agree that Your submission of such information is voluntary on Your
					part. Further, You acknowledge, consent and agree that we may access, preserve, and disclose
					information You provide to Us at any stage during Your use of the Website. Disclosures of
					information to Third Parties are further addressed in Our Privacy Policy.
        </p>
      </div>

      <button class="accordion">NOTICES</button>
      <div class="panel">
        <p class="lead">
          Any and all communication relating to any dispute or grievance experienced by the User may be
					communicated to the Company by the User reducing the same to writing, and sending the same to the
					registered office of the Company by Registered Post, or the email address 
        </p>
      </div>

      <button class="accordion">MISCELLANEOUS PROVISIONS</button>
      <div class="panel">
        <p class="lead">
          <strong>Entire Agreement: </strong>This Agreement is the complete and exclusive statement of the
					agreements between You and Us with respect to the subject matter hereof and supersedes all other
					communications or representations or agreements (whether oral, written or otherwise) relating
					thereto.
					<br>
					<strong> Waiver: </strong>The failure of either party at any time to require performance of any
					provision of this Agreement in no manner shall affect such party's right at a later time to enforce
					the same. No waiver by either party of any breach of this Agreement, whether by conduct or
					otherwise, in any one or more instances, shall be deemed to be, or construed as, a further or
					continuing waiver of any other such breach, or a waiver of any other breach of this Agreement.
					<br>
					<strong> Liability:</strong> The Mobile App and the Company shall not be liable in any way for User
					Content, including, but not limited to, any errors or omissions contained therein, or any loss or
					damage of any kind incurred as a result of the use of any User Content posted, emailed, transmitted
					or otherwise made available via the Service or broadcast elsewhere.

					<br>
					<strong> Severability:</strong> If any provision of this Agreement shall to any extent be held
					invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining
					provisions of this Agreement shall in no way be affected or impaired thereby and each such provision
					of this Agreement shall be valid and enforceable to the fullest extent permitted by law. In such a
					case, this Agreement shall be reformed to the minimum extent necessary to correct any invalidity,
					illegality or unenforceability, while preserving to the maximum extent the rights and commercial
					expectations of the parties hereto, as expressed herein.
        </p>
      </div>

      <button class="accordion">CONTACT US</button>
      <div class="panel">
        <p class="lead">
          If you have any questions about this Agreement, the practices of the Website, or your experience
					with the Service, you can e-mail us at <b>connect@meetuniversity.com</b>.
        </p>
      </div>

    </div>
    <!--end of row-->
  </div>
</section>
