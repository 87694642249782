
<section class="imagebg space--lg" data-overlay="3">
    <div class="background-image-holder" style="background: url(&quot;assets/img/topbanner.jpeg&quot;); opacity: 1;">
        <img alt="background" src="assets/img/topbanner.jpeg">
    </div>
    <div class="container bannermiddle">
        <div class="row">
            <div class="col-md-9">
                <div class="row">
                    <div class="col-sm-2">
                        <img height="auto" width="auto" alt="image" loading="lazy" src="assets/img/logosubject.jpg" class="profilepic">
                    </div>
                    <div class="col-sm-10">
                        <h3>
                              {{UniversityDetail.univ_name}} 
                            <span>  
                                - UK  
                            </span>
                        </h3>
                        <div class="counts text-white"><i class="fa fa-map-marker"></i>
                            {{UniversityDetail.address}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3"> 
                <div class="row mt-4">
                    <div class="col-md-4">
                <img height="auto" width="auto" alt="image" loading="lazy" src="assets/img/icon-askjuno.png" >
            </div>
                <div class="col-md-4">
                <a class="btn type--uppercase orangebtn" href="/apply-to-abroad-university">
                    <span class="btn__text">
                        Apply
                    </span>
                </a>
            </div>
            </div>
        </div>
        </div>
    </div>
    <!--end of container-->
</section>



<section class="space--xs"> 
    <div class="container">
        <div class="row">
                <div class="col-md-12">
                    <div class="container">
                        <div class="row justify-content-between">
                            <div class="col-md-3">
                                <div class="text">
                                    <p class="textmanage">Year Of Estabishment</p>
                                    <h5 *ngIf="UniversityDetail.yearOfEst">
                                        {{UniversityDetail.yearOfEst}}
                                    </h5>
                                    <h5  *ngIf="!UniversityDetail.yearOfEst">
                                        N/A
                                    </h5>
                                </div>
                                <div class="text">
                                    <p class="textmanage">Staff</p>
                                    <h5  *ngIf="UniversityDetail.staff">
                                        {{UniversityDetail.staff}}
                                    </h5>
                                    <h5  *ngIf="!UniversityDetail.staff">
                                        N/A
                                    </h5>
                                </div>
                                <div class="text">
                                    <p class="textmanage">Acceptance Crireria</p>
                                    <h5  *ngIf="UniversityDetail.acceptanceCriteria">
                                          {{UniversityDetail.acceptanceCriteria}}
                                    </h5>
                                    <h5  *ngIf="!UniversityDetail.acceptanceCriteria">
                                    N/A
                                  </h5>
                                </div>
                                <div class="text">
                                    <p class="textmanage">Accomodation</p>
                                    <h5 *ngIf="UniversityDetail.accomodation">
                                    {{UniversityDetail.accomodation}}        
                                    </h5>
                                    <h5 *ngIf="!UniversityDetail.accomodation">
                                    N/A   
                                        </h5>
                                </div> 
                            </div>
                            <div class="col-md-3">
                                <div class="text">
                                    <p class="textmanage"> College Address</p>
                                    <h5  *ngIf="UniversityDetail.address">
                                    {{UniversityDetail.address}}
                                        
                                    </h5>
                                    <h5  *ngIf="!UniversityDetail.address">
                                    N/A
                                            
                                        </h5>
                                </div>
                                <div class="text">
                                    <p class="textmanage"> College website</p>
                                    <h5 *ngIf="UniversityDetail.website">
                                 <a  href="{{UniversityDetail.website}}">  {{UniversityDetail.website}}</a>
                                    </h5>
                                    <h5  *ngIf="!UniversityDetail.website">
                                     N/A
                                           </h5>
                                </div>
                                <div class="text">
                                    <p class="textmanage">College Contact</p>
                                    <h5 *ngIf="UniversityDetail.contacts">
                                         {{UniversityDetail.contacts}}
                                    </h5>
                                    <h5 *ngIf="!UniversityDetail.contacts">
                                        N/A
                                   </h5>
                                </div>
                            </div>
                            <div class="col-md-6 uni-info-list">
                                <div class="row">
                                    <div class="col-sm-3 uni-info-item">
                                        <h5>Type</h5>
                                        <img height="auto" width="auto" alt="image" loading="lazy" class="" src="assets/img/college-type.png">
                                        <p>
                                            <strong>
                                                {{UniversityDetail.type}}
                                            </strong>
                                        </p>
                                        <p>
                                            <strong>
                                                {{UniversityDetail.type}}
                                            </strong>
                                        </p>
                                    </div>
                                    <div class="col-sm-3 uni-info-item">
                                        <h5>Scholarship</h5>
                                        <img height="auto" width="auto" alt="image" loading="lazy" class="" src="assets/img/college-scholorship.png">
                                        <p>
                                            <strong>
                                                {{UniversityDetail.scholership}}
                                            </strong>
                                        </p>
                                    </div>
                                    <div class="col-sm-3 uni-info-item">
                                        <h5>No.of students</h5>
                                        <img height="auto" width="auto" alt="image" loading="lazy" class="" src="assets/img/college-students.png">
                                        <p>
                                            <strong>
                                                {{UniversityDetail.students}}  (Approx)
                                            </strong>
                                        </p>
                                    </div>
                                    <div class="col-sm-3 uni-info-item">
                                        <h5>Intake</h5>
                                        <img height="auto" width="auto" alt="image" loading="lazy" class="" src="assets/img/college-intake.png">
                                        <p>
                                            <strong>
                                                 {{UniversityDetail.intakes}}
                                            </strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
       </div>
</section>

<section class="overviw">
<div class="container">
<div class="row">
<div class="col-md-10 col-sm-12">
<div class="description">
<h2 class="boldtext">OVERVIEW</h2>
<p>The Cardiff University is located in Cardiff,  Wales, UK. The university officially became the Cardiff Metropolitan University in 2011.<br>
The university is a home to over 12,000 students. According to the Times Good University Guide 2013, the university is ranked as the top new university in Wales. The university provides degree courses in a variety of areas. The university has a number of research and enterprise centres like :
</p>
<li class="litext">.The Food Industry Centre</li>
<li class="litext">The Welsh Centre for Tourism Research</li>
<li class="litext"> The National Centre for Product Design and Development Research.</li> <br>
<h3 class="boldtext">CAMPUS</h3>
<p>The university has three campuses- Llandaff on  Western Avenue, Cyncoed and Howard Gardens in the city centre.</p> <br>
<h3 class="boldtext">COURSES</h3>
<p>The university provides a variety of undergraduate and postgraduate programmes. The university has 5 academic schools, namely :</p>
<li class="litext">Cardiff School of Art &amp; Design</li>
<li class="litext">Cardiff School of Education</li>
<li class="litext">Cardiff School of Health Science</li>
<li class="litext">Cardiff School of Management</li>
<li class="litext">Cardiff School of Sport</li>

<h3 class="boldtext">NOTED ALUMNI</h3>
<li class="litext">Roy Noble  Radio and TV Broadcaster</li>
<li class="litext">Neil Jackson  Actor and Writer</li>
<li class="litext">Alun Michael  Politician</li>
<li class="litext">Nicky Piper - Boxer</li>
<h3 class="boldtext">SCHOLARSHIPS</h3>
<p>The university provides various scholarship to both local and international students like:</p>
<li class="litext">Alumni Scholarship</li>
<li class="litext">MBA Scholarship</li>
<li class="litext">Vice chancellor Scholarship
        </li></div>
<!---------------------------Courses Offered---------------------------------------->

<section class="space--xs bordernone scroll2_1">
<h3 class="boldtext mb-4">Courses Offered</h3>
<div class="row">   
<div class="col-md-6" data-card="card-0" *ngFor="let item of UniversityCourse">
<div class=" boxed boxed--border courseoffered">
<div class="col-sm-12">

<h4 class="word-slice"><b>{{item.name}}</b></h4>

<p class="bluecolor">
<span *ngIf="item.level=='1'">Masters</span>
<span *ngIf="item.level=='2'">Bachelors</span> |
{{item.meta_data[0].duration }}
</p>                                               
<div class="row">
<div class="col-md-3 textsmall">toefl:<br></div> 
<div class="col-md-3 textsmall">ielts:<br></div> 
<div class="col-md-6 textsmall">Intake:<br>{{item.meta_data[0].intake}}</div>
</div>
<div class="row">
<div class="col-md-12 mt-3 text-right">
    <a class="btn orangebtn btn--primary" href="#">
        <span class="btn__text">APPLY</span>
    </a>
</div>
</div>                                              
</div>
</div>
</div>
<!-- <div class="col-md-6" data-card="card-0">
    <div class=" boxed boxed--border courseoffered">
    <div class="col-sm-12">
    <h4 class="word-slice"><b>kdsfajh</b></h4>
    
    <p class="bluecolor">
    Masters |
    Full Time (1 year)
    </p>                                               
    <div class="row">
    <div class="col-md-3 textsmall">toefl:<br></div> 
    <div class="col-md-3 textsmall">ielts:<br></div> 
    <div class="col-md-6 textsmall">Intake:<br>September</div>
    </div>
    <div class="row">
    <div class="col-md-12 mt-3 text-right">
        <a class="btn orangebtn btn--primary" href="#">
            <span class="btn__text">APPLY</span>
        </a>
    </div>
    </div>                                              
    </div>
    </div>
    </div>
    <div class="col-md-6" data-card="card-0">
        <div class=" boxed boxed--border courseoffered">
        <div class="col-sm-12">
        <h4 class="word-slice"><b>Art and Design MA</b></h4>
        
        <p class="bluecolor">
        Masters |
        Full Time (1 year)
        </p>                                               
        <div class="row">
        <div class="col-md-3 textsmall">toefl:<br></div> 
        <div class="col-md-3 textsmall">ielts:<br></div> 
        <div class="col-md-6 textsmall">Intake:<br>September</div>
        </div>
        <div class="row">
        <div class="col-md-12 mt-3 text-right">
            <a class="btn orangebtn btn--primary" href="#">
                <span class="btn__text">APPLY</span>
            </a>
        </div>
        </div>                                              
        </div>
        </div>
        </div>

        <div class="col-md-6" data-card="card-0">
            <div class=" boxed boxed--border courseoffered">
            <div class="col-sm-12">
            <h4 class="word-slice"><b>Art and Design MA</b></h4>
            
            <p class="bluecolor">
            Masters |
            Full Time (1 year)
            </p>                                               
            <div class="row">
            <div class="col-md-3 textsmall">toefl:<br></div> 
            <div class="col-md-3 textsmall">ielts:<br></div> 
            <div class="col-md-6 textsmall">Intake:<br>September</div>
            </div>
            <div class="row">
            <div class="col-md-12 mt-3 text-right">
                <a class="btn orangebtn btn--primary" href="#">
                    <span class="btn__text">APPLY</span>
                </a>
            </div>
            </div>                                              
            </div>
            </div>
            </div> -->
    </div>
    <div class="text-center">
    <a class="btn btn--primary text-center" href=""><span class="btn__text">View all courses</span></a>
</div>
</section>


 <!-------------------------News Content pieces-----------------------------> 
 <section class="space--xs bordernone scroll2_1">
    <h3 class="boldtext  mb-4">News Content pieces</h3>     
        <div class="row">
                        <div class="col-lg-4 col-md-6 text-center">
                            <div class="project-thumb">
                                <a href="#">
                                    <img alt="Image" class="border--round" src="assets/img/Top-10-Scholarships-to-Study-in-UK.jpg">
                                </a>
                                <h4>Unvest Capital</h4>
                                <span>Digital &amp; Interactive Design</span>
                            </div>
                        </div>
                        <!--end item-->
                        <div class="col-lg-4 col-md-6 text-center filter-print">
                            <div class="project-thumb">
                                <a href="#">
                                    <img alt="Image" class="border--round" src="assets/img/Top-10-Scholarships-to-Study-in-UK.jpg">
                                </a>
                                <h4>Get Lost in Thailand</h4>
                                <span>Print Marketing</span>
                            </div>
                        </div>
                        <!--end item-->
                        <div class="col-lg-4 col-md-6 text-center filter-branding">
                            <div class="project-thumb">
                                <a href="#">
                                    <img alt="Image" class="border--round" src="assets/img/Top-10-Scholarships-to-Study-in-UK.jpg">
                                </a>
                                <h4>M&amp;D Stairs Company</h4>
                                <span>Branding &amp; Identity</span>
                            </div>
                        </div>
                        <!--end item-->
                        <div class="col-lg-4 col-md-6 text-center filter-branding" >
                            <div class="project-thumb">
                                <a href="#">
                                    <img alt="Image" class="border--round" src="assets/img/Top-10-Scholarships-to-Study-in-UK.jpg">
                                </a>
                                <h4>Blossom Naturals</h4>
                                <span>Branding &amp; Identity</span>
                            </div>
                        </div>
                        <!--end item-->
                        <div class="col-lg-4 col-md-6 text-center filter-print">
                            <div class="project-thumb">
                                <a href="#">
                                    <img alt="Image" class="border--round" src="assets/img/Top-10-Scholarships-to-Study-in-UK.jpg">
                                </a>
                                <h4>Nike Active</h4>
                                <span>Print Marketing</span>
                            </div>
                        </div>
                        <!--end item-->
                        <div class="col-lg-4 col-md-6 text-center filter-digital">
                            <div class="project-thumb">
                                <a href="#">
                                    <img alt="Image" class="border--round" src="assets/img/Top-10-Scholarships-to-Study-in-UK.jpg">
                                </a>
                                <h4>Nuaca</h4>
                                <span>Digital &amp; Interactive Design</span>
                            </div>
                        </div>
                        <!--end item-->
    
        </div>  
 </section>   
    </div>
     <div class="col-md-2 col-sm-12">
        <div class="nopadding sidebar">
            <ul>
               <li><a href="#">Overview</a></li>
               <li><a href="#" >Courses offered</a></li>
               <li><a href="#">News content pieces</a></li>
            </ul>
            <hr class="col-md-2">
            <div class="nopadding uni-info-item">
                <a rel="noopener" href="https://www.meetuniversity.com/expert-list" target="_blank">
                <img height="auto" width="auto" alt="image" loading="lazy" class="" src="assets/img/ask-an-expert.jpg">
                </a>
            </div>
        </div>
     </div>
    </div>
</div>

</section>