<!-- <app-admin-sidebar></app-admin-sidebar> -->
<app-admin-header></app-admin-header>

<div class="main-container">
  <section class="bg--secondary space--sm">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <app-admin-nav></app-admin-nav>
        </div>
        <div class="col-lg-9">
          <div class="boxed boxed--lg boxed--border">
          <router-outlet></router-outlet>
        </div>
        </div>
      </div>
      <!--end of row-->
    </div>
    <!--end of container-->
  </section>
  <app-admin-footer></app-admin-footer>
</div>
