import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { AuthGuard } from './core/guards/auth.guard';
import { AskExpertComponent } from './public/components/ask-expert/ask-expert.component';
import { AskJunoComponent } from './public/components/ask-juno/ask-juno.component';
import { CommonApplicationFormComponent } from './public/components/common-application-form/common-application-form.component';
import { NotFoundComponent } from './public/components/not-found/not-found.component';
import { PreCounselComponent } from './public/components/pre-counsel/pre-counsel.component';
import { PublicComponent } from './public/public.component';


const routes: Routes = [
  {
    path: '',
    component: PublicComponent,
    loadChildren: () =>
      import('../app/public/public.module').then((m) => m.PublicModule),
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/admin/admin.module').then((m) => m.AdminModule),
  },
  { path: 'pre-counsel/:token', component: PreCounselComponent },
  { path: 'common-application-form/:token', component: CommonApplicationFormComponent },
  { path: 'ask-expert', component: AskExpertComponent },
  { path: 'ask-juno', component: AskJunoComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes , { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
