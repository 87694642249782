import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BookingService } from '../../services/booking.service';
import { Booking } from '../../interfaces/booking';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {
  discussDetails!: FormGroup;
  slotDetails!: FormGroup;
  personalDetails!: FormGroup;
  discuss_step = false;
  slot_step = false;
  personal_step = false;
  step = 2;
  formBuilder: any;
  bookingName: any;
  duration: any = 30;
  time = new Date();
  bookingArray = {} as Booking;
  @Input('expert_id') public expert_id: any;
  bsInlineValue = new Date();
  showSecondSection: boolean = false;
  showFirstSection: boolean = true;
  bookingDate: Date;
  public discussArray = [
    { name: 'Course' },
    { name: 'Country' },
    { name: 'University' },
  ]
  constructor(private modalService: BsModalService, public modalRef: BsModalRef,
    private fb: FormBuilder, private bookingService: BookingService,private httpClient: HttpClient,private notifyService: NotificationService) { }

  ngOnInit(): void {
    this.slotDetails = this.fb.group({
      date: [''],
      time: ['']
    });
    this.personalDetails = this.fb.group({
      name: [''],
      email: [''],
      phone: [''],
    })
    this.discussDetails = this.fb.group({
      discussBooking: ['']
    })
    console.log(this.bookingDate)
  }
  get discuss() { return this.discussDetails.controls; }
  get slot() { return this.slotDetails.controls; }
  get personal() { return this.personalDetails.controls; }

  next() {
    console.log(this.slotDetails.value.discussBooking)
    if (this.step == 1) {
      console.log('this.step', this.step)
      this.discuss_step = true;
      if (this.discussDetails.invalid) { return }
      this.step++;
      console.log('this.step 1', this.step)
    } else if (this.step == 2) {
      this.slot_step = true;
      if (this.slotDetails.invalid) { return }
      this.step++;
    }
  }
  getBookingDiscuss(name: any) {
    this.bookingName = name;
  }
  previous() {
    this.step--
    if (this.step == 1) {
      this.personal_step = false;
    }
    if (this.step == 2) {
      this.slot_step = false;
    }
  }
  onSubmit() {

    try {
      if (this.slotDetails.valid) {
        // this.bookingArray.expert_id = this.expert_id;
        // this.bookingArray.discuss = this.bookingName;
        // this.bookingArray.date =this.NewDate;
        // this.bookingArray.name = this.personalDetails.value.name;
        // this.bookingArray.email_address = this.personalDetails.value.email;
        // this.bookingArray.phone_no = this.personalDetails.value.phone;
        // this.bookingArray.start_time = this.selectTime;
        // this.bookingArray.end_time = new Date(this.slotDetails.value.date).setMinutes(this.time.getMinutes() + 30);
        // this.bookingArray.duration = this.duration;
        // this.bookingArray.login_type = '2';
        // console.log(this.bookingArray);
        let formData1 = new FormData();
        formData1.append("date",this.slotDetails.value.date);
        formData1.append("discuss", this.bookingName);
        formData1.append("duration", this.duration);
        formData1.append("email_address", this.personalDetails.value.email);
        formData1.append("end_time", "30");
        formData1.append("expert_id",this.expert_id);
        formData1.append("login_type", "2");
        formData1.append("name", this.personalDetails.value.name);
        formData1.append("phone_no", this.personalDetails.value.phone);
        formData1.append("start_time", this.slotDetails.value.time);
        // this.bookingService.postBookingData(this.bookingArray).subscribe(async (json: any) => {
        //   this.personalDetails.reset();
        //   console.log('form data',json);
        // });
        this.httpClient.post<any>("https://app.meetuniversity.com/APIs/pushStudentData.php/index", formData1).subscribe(
          (res) => {
            if(res){
              this.notifyService.showSuccess('Details saved successfully.');
              //window.location.reload();
            }
          },
          (err) => {
            this.notifyService.showSuccess('Details saved successfully.');
            //window.location.reload();
          }
        );
      }
    } catch (e) {
      console.info(e);
    }
  }

  submit() {
    if (this.step == 3) {
      this.personal_step = true;
      if (this.personalDetails.invalid) { return }
    }
  }
  showHideSection(item:any) {
    this.slotDetails.patchValue({ time : item })
    this.showFirstSection = false;
    this.showSecondSection = true;
  }

  onDataFinalValueChange(date:any){
  console.log(date.getDate());
  console.log(date.getDay())
  }
  onValueChangeWeek(date:any){
    this.slotDetails.patchValue({ date : date })
  }
}


