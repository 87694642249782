import { environment } from 'src/environments/environment';
export var apiUrl = {
  get_expert_list: `${environment.LMS}Api_new/get_expert_list/format/json?client_id=mu_internal&key=0a19a3edd791626cca1fe1a33f2f2dba`,
  get_events: `${environment.V1}search/events?q=general&content=4&size=6`,
  get_disciplines: `${environment.LMS}Api_new/get_disciplines/format/json?client_id=mu_internal&key=0a19a3edd791626cca1fe1a33f2f2dba`,
  get_disciplines_inner: `${environment.LMS}Api_new/get_discipline_by_name/format/json?client_id=mu_internal&key=0a19a3edd791626cca1fe1a33f2f2dba`,
  get_course_detail: `${environment.V1}data/university/courses`,
  get_course_table: `${environment.V1}search/university`,
  get_staticPage: `${environment.V1}data/static?key=staticPage`,
  get_student_counselor_count: `${environment.LMS}Api_new/student_counselor_count/format/json?client_id=mu_internal&key=0a19a3edd791626cca1fe1a33f2f2dba`,
  get_otp_url: `${environment.LMS}Api_new/juno_register_user/format/json?client_id=mu_internal&key=0a19a3edd791626cca1fe1a33f2f2dba`,
  get_verify_url: `${environment.LMS}Api_new/verify_user_web/format/json?client_id=mu_internal&key=0a19a3edd791626cca1fe1a33f2f2dba`,
  profile_link_send_otp: `${environment.LMS}Api_new/profile_link_send_otp/format/json?client_id=mu_internal&key=0a19a3edd791626cca1fe1a33f2f2dba`,
  profile_link_verify_otp: `${environment.LMS}Api_new/profile_link_verify_otp/format/json?client_id=mu_internal&key=0a19a3edd791626cca1fe1a33f2f2dba`,
  profile_link_update_lms_profile: `${environment.LMS}Api_new/profile_link_update_lms_profile/format/json?client_id=mu_internal&key=0a19a3edd791626cca1fe1a33f2f2dba`,
  lms_lead_formfill_updated: `${environment.LMS}Api_new/lms_lead_formfill_updated/format/json?client_id=mu_internal&key=0a19a3edd791626cca1fe1a33f2f2dba`,
  //Admin URL
  get_profile: `${environment.LMS}Api_new/getProfilelms/format/json?client_id=mu_internal&key=0a19a3edd791626cca1fe1a33f2f2dba`,
  post_preference_data: `${environment.LMS}Api_new/new_application_lms/format/json?client_id=mu_internal&key=0a19a3edd791626cca1fe1a33f2f2dba`,
  search_tags: `${environment.V1}search/tags`,
  book_appointment: `${environment.LMS}APIs/pushStudentData.php/index`,
  validate_data: `${environment.LMS}Api_new/validate_data_study?client_id=mu_internal&key=0a19a3edd791626cca1fe1a33f2f2dba`,
  phone_change: `${environment.LMS}Api_new/change_phone_request/format/json?client_id=mu_internal&key=0a19a3edd791626cca1fe1a33f2f2dba`,
  //core: `${environment.V1}search/core`,
  exotel_call:`${environment.LMS}exotel_call/format/json?client_id=mu_internal&key=0a19a3edd791626cca1fe1a33f2f2dba`,
  getShortlistUniversities:`${environment.LMS}Api_new/getShortlisting/format/json?client_id=mu_internal&key=0a19a3edd791626cca1fe1a33f2f2dba`,
  doc_update:`${environment.LMS}Api_new/update_doc_by_lead_id_updated/format/json?client_id=mu_internal&key=0a19a3edd791626cca1fe1a33f2f2dba`,
	doc_delete:`${environment.LMS}Api_new/delete_doc/format/json?client_id=mu_internal&key=0a19a3edd791626cca1fe1a33f2f2dba`,
  //country page url
  businessPage:`${environment.V1}data/business?key=businessPage`,
  metaPage:`${environment.V1}data/business/meta`,
  //search
  search_new: `${environment.V1}search/new`,
  //university and university detail page
  university_list: `${environment.V1}data/business/meta`,
  university_courses: `${environment.V1}search/university`,
  new_university_extradata: `${environment.LMS}Api_new/new_university_extradata/format/json?client_id=mu_internal&key=0a19a3edd791626cca1fe1a33f2f2dba`,
  save_enquiry:`${environment.LMS}Api_new/save_enquiry/format/json?client_id=mu_internal&key=0a19a3edd791626cca1fe1a33f2f2dba`,
  get:`${environment.LMS}/Api_new/new_timeline/format/json?client_id=mu_internal&key=0a19a3edd791626cca1fe1a33f2f2dba`,
  get_timeline:`${environment.LMS}Api_new/new_timeline/format/json?client_id=mu_internal&key=0a19a3edd791626cca1fe1a33f2f2dba`,
};
