<section>
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-md-12">
          <div class="heading-block">
            <h2 class="mb-4">PRIVACY POLICY</h2>
            <p class="lead">
                We, uprise education Educonnect Private Limited (hereinafter referred to as the “Company”) a private limited company incorporated under the Companies Act, 1956, having its registered office at 1/22 ,Asif Ali Road New Delhi 110002,India and corporate office at B-4, First Floor, B Block, Sector 63, Noida, Uttar Pradesh 201301, Uttar Pradesh, India, represented by its members, where such expression shall, unless repugnant to the context thereof, be deemed to include its respective legal heirs, representatives, administrators, permitted successors and assigns. The creator of this Privacy Policy ensures our steady commitment to Your privacy with regard to the protection of your invaluable information. This privacy policy contains information about an online website called www.meetuniversity.com (hereinafter referred to as the “Website”). In order to provide You with Our uninterrupted use of services, We may collect and, in some circumstances, disclose information about you. To ensure better protection of Your privacy, We provide this notice explaining Our information collection and disclosure policies, and the choices You make about the way Your information is collected and used.
              </p>
              <p class="lead">
                ANY CAPITALIZED WORDS USED HENCEFORTH SHALL HAVE THE MEANING ACCORDED TO THEM UNDER THIS AGREEMENT. FURTHER, ALL HEADING USED HEREIN ARE ONLY FOR THE PURPOSE OF ARRANGING THE VARIOUS PROVISIONS OF THE AGREEMENT IN ANY MANNER. NEITHER THE USER NOR THE CREATERS OF THIS PRIVACY POLICY MAY USE THE HEADING TO INTERPRET THE PROVISIONS CONTAINED WITHIN IT IN ANY MANNER.
              </p>
          </div>
        </div>

        <button class="accordion"> DEFINITIONS</button>
        <div class="panel">
            <ul>
                <li class="lead">(a) “We”, “Our”, and “Us” shall mean and refer to the creators of this privacy policy.
                </li >
                <li class="lead">(b) “You”, “Your”, “Yourself” and “User” shall mean and refer to natural and legal
                    individuals who use the Website.</li>
                <li class="lead">(c) “Personal Information” shall mean and refer to any personally identifiable
                    information that We may collect from You. For removal of any doubts, please refer to
                    Clause 2.</li>
                <li class="lead"> (d) “Third Parties” refer to any website, company or individual apart from the User and
                    the creator of thisWebsite.</li>
            </ul>
        </div>

        <button class="accordion"> INFORMATION COLLECTED</button>
        <div class="panel">
            <p class="lead"> We are committed to respecting Your online privacy. We further recognize Your need for
                appropriate protection and management of any Personal Information You share with us.<br> We may
                collect the following information:</p>
            <ul>
                <li class="lead">(a) Personal data of the User such as, but not limited to, Name, Address, User Name,
                    Password, Email ID, Mobile Number, Etc.
                </li >
                <li class="lead">(b) Tracking Information such as, but not limited to the IP address of your device and
                    Device ID when connected to the Internet.
                </li >
            </ul>
        
        </div>

        <button class="accordion">EXTERNAL LINKS ON THE WEBSITE</button>
        <div class="panel">
          <p class="lead">
            The Website may include advertisements hyperlinks to other websites, content or resources. We have
            no control over such external links present in the Website, which are provided by persons or
            companies other than Us.
            <br>You acknowledge and agree that We are not responsible for any collection or disclosure of your
            Personal Information by any external websites, companies or persons, nor do We endorse any
            advertising, products or other material on or available from such external websites, websites or
            resources.
            <br>You further acknowledge and agree that We are not liable for any loss or damage which may be
            incurred by You as a result of the collection and/or disclosure of Your personal information by
            external websites, sites or resources, or as a result of any reliance placed by You on the
            completeness, accuracy or existence of any advertising, products or other materials on, or available
            from such Websites, websites or resources.<br> This external Website, websites and resource
            providers may have their own privacy policies governing the collection, storage, retention and
            disclosure of Your Personal Information that You may be subject to. We recommend that You enter the
            external Website and review their privacy policy.
            <br>We allow third parties/individuals to display advertisements when you use the Website.
           </p>
        </div>

        <button class="accordion">OUR USE OF YOUR INFORMATION</button>
        <div class="panel">
          <p class="lead">
            The information provided by You at the time of registration shall be used to contact You when
            necessary. For more details about the nature of such communications, please refer to our Terms of
            Service. Further, Your personal data and Sensitive Personal data may be collected and stored by Us
            for internal record.
            We use Your tracking information such as IP addresses, and or Device ID to help identify you and to
            gather broad demographic information.
            In case We are acquired by or merged with another company, We shall transfer information disclosed
            by You and information about You to the company we are acquired by or merged with. In the event of a
            merger or acquisition, We shall notify You by email/by putting a prominent notice on the Website
            before Your Personal Information is transferred and becomes subject to a different privacy policy.
           </p>
        </div>

        <button class="accordion">Cookies</button>
        <div class="panel">
            <ul>
                <li class="lead">
                    a. We use data collection devices such as "cookies" on certain pages of our Websites.
                    "Cookies" are small files sited on your hard drive that assist us in providing customized
                    services. We also offer certain features that are only available through the use of a
                    "cookie". Cookies can also help us provide information, which is targeted to your interests.
                    <br>Cookies may be used to identify logged in or registered users.</li>
                <li class="lead">b. Third party vendors including www.google.com (“Google”) may use cookies to serve ads
                    based on your visits to this Website. You may visit the website of the third party and
                    choose to opt out of the use of cookies for interest-based advertising, if the third party
                    offers such an option. You may choose to opt-out of the DoubleClick cookie that Google and
                    its partners use for interest-based advertising by visiting Ads Settings. (Alternatively,
                    you can direct users to opt out of a third-party vendor's use of cookies for interest based
                    advertising by visiting about ads.info.)
                </li>
                <li class="lead">c. The website also has enabled the Google Analytics Advertising, which allows Google to
                    collect data about users on our website, in addition to Google advertising cookies and
                    anonymous identifiers. You may choose to opt out of this by downloading and installing the
                    Google Analytics opt-out add-on here https://tools.google.com/dlpage/gaoptout/.
                </li>
            </ul>
        </div>

        <button class="accordion">ACCESSING, REVIEWING AND CHANGING YOUR PROFILE</button>
        <div class="panel">
           <p class="lead">Your information is regarded as confidential and therefore shall not be divulged to any third party,
            unless if legally required to do so to the appropriate authorities, or if necessary to ensure Users
            may fully avail of the services of the Website.
            We shall not sell, share, or rent your personal information to any marketing agencies or any other
            such companies that indulge in unsolicited communications. Any communication by Us to You shall be
            undertaken in accordance with Our Terms of Service and Privacy Policy.</p>
        </div>
        <button class="accordion">DISCLOSURE OF YOUR INFORMATION TO THIRD PARTIES</button>
        <div class="panel">
           <p class="lead">Due to the existing regulatory environment, We cannot ensure that all of your Personal Information
            shall never be disclosed in ways other than those described in this Privacy Policy. Although we use
            industry standard practices to protect your privacy, we do not promise, and you should not expect,
            that your personally identifiable information or private communications would always remain
            private.</p>
            <ul>
                <li class="lead">

                    7.1 External Service Providers: There may be a number of services offered by external
                    service providers that help You use our Website. If You choose to use these optional
                    services, and in the course of doing so, disclose information to the external service
                    providers, and/ or grant them permission to collect information about You, then their use of
                    Your information is governed by their privacy policy.
                </li>
                <li class="lead">
                    7.2 Other Corporate Entities: We share much of our data, including Your Personal
                    Information, with Our parent and/ or subsidiaries that are committed to serving Your needs
                    through use of Our Website and related services, throughout the world. Such data shall be
                    shared for the sole purpose of enhancing Your experience of using the Website. To the extent
                    that these entities have access to your information, they shall treat it at least as
                    protectively as they treat information they obtain from their other members. It is possible
                    that We and/or its subsidiaries, or any combination of such, could merge with or be acquired
                    by another business entity. Should such a combination occur, you should expect that we would
                    share some or all of your information in order to continue to provide the service. You shall
                    receive notice of such event (to the extent it occurs).
                </li>
                <li class="lead">
                    7.3 Law and Order: We cooperate with law enforcement inquiries, as well as other third
                    parties to enforce laws, such as: intellectual property rights, fraud and other rights. We
                    can, and You so authorise Us, disclose Your Personal Information to law enforcement and
                    other government officials as We, in Our sole discretion, believe necessary or appropriate,
                    in connection with an investigation of fraud, intellectual property infringements, or other
                    activity that is illegal or may expose Us/ Us or You to any legal liability. </li>
                <li class="lead">
                    Any information that you make publically available on the site may be potentially viewed by
                    any party, and by posting such material it is deemed that you consent to share such
                    information with such parties.
                </li>
            </ul>
        </div>
        <button class="accordion">ACCESSING, REVIEWING AND CHANGING YOUR PROFILE</button>
        <div class="panel">
           <p class="lead">Following registration, You can review and change the information You submitted at the stage of
            registration, except Your display name/ user name. An option for facilitating such change shall be
            present on the home screen of the Website and such change can be facilitated by emailing us at
            <strong>Connect@meetuniversity.com</strong>. If You change any information, We may keep track of
            Your old information.
            If you believe that any information we are holding on you is incorrect or incomplete, or to remove
            Your profile so that others cannot view it, please write to or email us as soon as possible, at
            <strong>Connect@meetuniversity.com</strong>. We shall promptly correct any information found to be
            incorrect.
            We shall retain in Our files, information You have requested to remove for certain circumstances,
            such as to resolve disputes, troubleshoot problems and enforce Our terms and conditions. Further,
            such prior information is never completely removed from Our databases due to technical and legal
            constraints, including stored 'back up' systems. Therefore, You should not expect that all of Your
            personally identifiable information shall be completely removed from our databases in response to
            Your requests.</p>
        </div>
        <button class="accordion">SECURITY</button>
        <div class="panel">
           <p class="lead">	We treat data as an asset that must be protected against loss and unauthorised access. We employ
            many different security techniques to protect such data from unauthorised access by members inside
            and outside the Company. We follow generally accepted industry standards to protect the Personal
            Information submitted to Us and information that we have accessed. However, “perfect security” does
            not exist on the Internet. You therefore agree that any security breaches beyond the control of Our
            standard security procedures are at Your sole risk and discretion.</p>
        </div>
        <button class="accordion">INDEMNITY</button>
        <div class="panel">
           <p class="lead">You agree and undertake to indemnify us in any suit or dispute by any Third Party arising out of
            disclosure of Personal Information by You to Third Parties either through Our Website or otherwise
            and Your use and access of websites and resources of Third Parties. We assume no liability for any
            actions of Third Parties with regard to Your Personal Information, which You may have disclosed to
            such Third Parties.</p>
        </div>
        <button class="accordion">SEVERABILITY</button>
        <div class="panel">
           <p class="lead">Each paragraph of this privacy policy shall be and remain separate from and independent of and
            severable from all and any other paragraphs herein except where otherwise expressly indicated or
            indicated by the context of the agreement. The decision or declaration that one or more of the
            paragraphs are null and void shall have no effect on the remaining paragraphs of this privacy
            policy.</p>
        </div>
        <button class="accordion">AMENDMENT</button>
        <div class="panel">
           <p class="lead">Our Privacy Policy may change from time to time. Hence we shall post any privacy policy changes on
            the homepage of the Website notify You by email.</p>
        </div>
      <!--end of row-->
      </div>
    </div>
  </section>
  