<section class=" bg--secondary">
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-sm">
                <div class="testimonial_card">
                    <div (click)="openModal('../../../../assets/video/Sagar.mp4')" class="testimonial_preview">
                        <div class="pulsating">
                            <img class="play-btn" src="../../../../assets/img/play-btn.svg" />
                        </div>
                        <div class="overlay-dark"></div>
                        <img class="testimonial_pic" src="../../../../assets/img/Sagar.jpg" />
                    </div>
                    <div class="boxed">
                        <p>
                            “Meet University provided me with exceptional guidance and assistance throughout the entire process - from preparing for the IELTS examination to clearing it and obtaining my study visa. I am extremely satisfied with their services and would like to extend my heartfelt gratitude to the entire team for their unwavering support and guidance”.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm">
                <div class="testimonial_card">
                    <div (click)="openModal('../../../../assets/video/Pradam.mp4')" class="testimonial_preview">
                        <div class="pulsating">
                            <img class="play-btn" src="../../../../assets/img/play-btn.svg" />
                        </div>
                        <div class="overlay-dark"></div>
                        <img class="testimonial_pic" src="../../../../assets/img/Pradam.jpg" />
                    </div>
                    <div class="boxed">
                        <p>
                            “I submitted my applications to my dream universities through Meet University, and I am grateful to have received offer letters from most of them. Meet University exhibited exceptional dedication in assisting me with preparing my Statement of Purpose (SOP) and other essential documents to help me actualize my aspirations”.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm">
                <div class="testimonial_card">
                    <div (click)="openModal('../../../../assets/video/Aditya.mp4')" class="testimonial_preview">
                        <div class="pulsating">
                            <img class="play-btn" src="../../../../assets/img/play-btn.svg" />
                        </div>
                        <div class="overlay-dark"></div>
                        <img class="testimonial_pic" src="../../../../assets/img/Aditya.jpg" />
                    </div>
                    <div class="boxed">
                        <p>
                            “I opted for Meet University as their profile appeared to be the most dependable. They invested significant time in aiding me. In order to alleviate my family's concerns, my counselor devoted countless hours speaking with me via phone and video chat. Thanks to Meet University's assistance, I was able to enroll at my dream school, University College London. I highly recommend utilizing their services; they will not let you down”.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm">
                <div class="testimonial_card">
                    <div (click)="openModal('../../../../assets/video/Saudamini.mp4')" class="testimonial_preview">
                        <div class="pulsating">
                            <img class="play-btn" src="../../../../assets/img/play-btn.svg" />
                        </div>
                        <div class="overlay-dark"></div>
                        <img class="testimonial_pic" src="../../../../assets/img/saudamini.jpg" />
                    </div>
                    <div class="boxed">
                        <p>
                            “I extend my heartfelt gratitude to the entire team of Meet University for their unwavering support and guidance that  made my academic journey a resounding success”.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
