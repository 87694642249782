import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {
  @Input('source_url') public source_url: any;

  constructor() { }

  ngOnInit(): void {
  }

}
