import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import {apiUrl} from "../../../config/config"

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  constructor(private apiService: ApiService) { }

  getStudentCounselerCount(){
    return this.apiService.get(apiUrl.get_student_counselor_count);
  }
}
