import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { apiUrl } from '../../../config/config';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  StaticPagetUrl = apiUrl.get_staticPage;

  constructor(private ApiService: ApiService) {}

  getStaticPage(slug: String) {
    return this.ApiService.get(`${this.StaticPagetUrl}&slug=${slug}`);
  }
}
