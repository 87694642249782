import { Component } from '@angular/core';
import { MessageService } from './core/services/message.service';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private messageService: MessageService, private router: Router) {
    this.messageService.sendMessage({text:'send'});

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) { 
        this.messageService.sendMessage({text:'send'});

       }
    });
  }
  clearMessages(): void {
    // clear messages
    this.messageService.clearMessages();
}
  title = 'mu-frontend';
}
