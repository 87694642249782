import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FlickrService {
  private apiKey = 'f37606bed508e1de60dc45879bf3539e';
  urls: string[] = []!;

  constructor(private http: HttpClient) { }

  // getImages(country: string): Observable<string[]> {
  //   const apiUrl = `https://api.flickr.com/services/rest/?method=flickr.photos.search&api_key=${this.apiKey}&tags=universities,education,${country}&tag_mode=all&per_page=6&format=json&nojsoncallback=1`;
  //   return this.http.get(apiUrl).pipe(
  //     map((response: any) => {
  //       let photoArray: string[] = [];
  //       response.photos.photo.forEach((photo: any) => {
  //         let photoUrl = 'https://farm' + photo.farm + '.staticflickr.com/' + photo.server + '/' + photo.id + '_' + photo.secret + '.jpg';
  //         photoArray.push(photoUrl);
  //       });
  //       return photoArray;
  //     })
  //   );
  // }

  getImages(country: string) {
    if (country==="usa") {
      this.urls = [
        "https://i.guim.co.uk/img/media/77916b7cef46b737f9a8f66b38b9cf70e5d4def8/0_242_5247_3148/master/5247.jpg?width=1200&height=900&quality=85&auto=format&fit=crop&s=a147a02fe4942a7ce3461e694208579b",
        "https://i2-prod.cambridge-news.co.uk/news/cambridge-news/article24183175.ece/ALTERNATES/s1200d/0_JS238376993.jpg",
        "https://cdn.britannica.com/03/117103-050-F4C2FC83/view-University-of-Oxford-England-Oxfordshire.jpg",
        "https://grimshaw.global/assets/lib/2016/09/16/00173_N9_a3.jpg"
      ];
      return this.urls;
    }
    else if (country==="uk") {
      this.urls = [
        "https://www.timeshighereducation.com/student/sites/default/files/cambridge_university_punting_0.jpg",
        "https://www.expatica.com/app/uploads/sites/10/2014/05/study-in-uk-1920x1080.jpg",
        "https://cdn.uniacco.com/blog/wp-content/uploads/2021/01/07091936/oxford-2456589_1920-min.jpg",
      ];
      return this.urls;
    }
    else if (country==="new-zealand") {
      this.urls = [
        "https://platinumstandardeducation.com/wp-content/uploads/2020/12/lincoln1.jpg",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaXNbm2NyerJvyLpjWDGwtDv9FYQRp6ODs4A&usqp=CAU",
        "https://unistudynews.com/wp-content/uploads/2021/07/cheapest-universities-in-new-zealand-for-international-students.jpg",
      ];
      return this.urls;
    }
    else if (country==="ireland") {
      this.urls = [
        "https://www.vieceducation.com/GalleryImage/1Study_In_Ireland.jpg",
        "https://cdn.britannica.com/16/103016-050-EF523DCF/University-of-Dublin-Ireland.jpg",
        "hhttps://edvoy-strapi-assets.s3.ap-south-1.amazonaws.com/SRI_7030_Hari_Article_Banner_1_Ireland_University_Banner_8_d7c0b54460.jpg",
      ];
      return this.urls; 
    }
    else if (country==="canada") {
      this.urls = [
        "https://www.timeshighereducation.com/student/sites/default/files/styles/default/public/university_of_toronto_1_1.jpg?itok=9fNp3VSb",
        "https://www.timeshighereducation.com/sites/default/files/university_of_toronto_2.jpg",
        "https://www.canamgroup.com/assets/universitygallery/224-62644-11.jpg",
      ];
      return this.urls; 
    }
    else if (country==="australia") {
      this.urls = [
        "https://www.timeshighereducation.com/student/sites/default/files/sydney_0.jpg",
        "https://www.sydney.edu.au/content/dam/corporate/images/faculty-of-arts-and-social-sciences/news-and-events/2021/usyd-quadrangle-banner.jpg",
        "https://images.indianexpress.com/2023/04/University-of-Wollongong.jpg",
      ];
      return this.urls; 
    }
    else if (country==="singapore") {
      this.urls = [
        "https://image.cnbcfm.com/api/v1/image/102743255-nus_u_town_small.jpg?v=1433832337",
        "https://www.coindesk.com/resizer/62JEyj7wAycHuzRq6VN_UjfzfFY=/arc-photo-coindesk/arc2-prod/public/KLE3WZGOWBFOBEESZSWTZZ7ULA.jpg",
        "https://apru.org/wp-content/uploads/2021/12/about-utown-scaled.jpeg",
      ];
      return this.urls; 
    }
    else if (country==="france") {
      this.urls = [
        "https://www.campusfrance.org/sites/default/files/Fotolia_5218588_Subscription_Monthly_M.jpg",
        "https://upload.wikimedia.org/wikipedia/commons/0/04/Neustadt_Strasbourg_palais_universitaire_%2829346162217%29.jpg",
        "https://www.studying-in-france.org/wp-content/uploads/2021/11/popular-degrees-in-France.jpeg",
      ];
      return this.urls; 
    }
    else {return []}
  }
}