import { Component, OnInit, TemplateRef } from '@angular/core';
import { DisciplineInnerService } from '../../services/discipline-inner.service';
import { ActivatedRoute, Params } from '@angular/router';
import { DisciplineInner, FeatureCourses } from '../../interfaces/discipline-inner';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { PopupVideoComponent } from './popup-video/popup-video.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-disciplines-inner',
  templateUrl: './disciplines-inner.component.html',
  styleUrls: ['./disciplines-inner.component.scss']
})
export class DisciplinesInnerComponent implements OnInit {
  public modalRef?: BsModalRef;
  public DisciplineInner = {} as DisciplineInner;
  public FeatureCourses = [] as FeatureCourses[];
  public imgUrl = environment.imgUrl;
  indusryType:string;
  constructor(private disciplineInnerService: DisciplineInnerService, private route: ActivatedRoute, private modalService: BsModalService) {
  }

  ngOnInit(): void {
    this.getDisciplineInner();
  }

  getDisciplineInner() {
    this.route.params.subscribe((params: Params) => {
      this.indusryType=atob(params.name)
      if (params.name) {
        this.disciplineInnerService.getDisciplineInner(atob(params.name)).subscribe((Res: any) => {
          this.DisciplineInner = Res;
          this.FeatureCourses = JSON.parse(this.DisciplineInner.courses);
        });
      }
    });
  }

  openModal(video: any) {
    if (video) {
      const config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: false,
        initialState: {
          url: video
        }
      }
      this.modalRef = this.modalService.show(PopupVideoComponent, config);
    }
  }

}
