<div class="boxed boxed--lg boxed--border">
    <div class="text-block text-center">
        <!-- <img alt="avatar" src="assets/img/avatar-round-5.png" class="image--sm" /> -->
        <p class="font-weight-bold mb-0 pb-0">University Application</p>
        <p>Multiple Universities One application</p>
        <span class="label">Manage</span>
    </div>
    <hr>
    <div class="text-block">
        <ul class="menu-vertical">
            <li class="nav">
                <a routerLink='preferences' href="#">
                    Preferences
                </a>
            </li>
            <li class="nav">
                <a routerLink='profile' href="#">
                    Profile
                </a>
            </li>
            <li class="nav">
                <a routerLink='education-detail'>
                    Education Details
                </a>
            </li>
            <li class="nav">
                <a routerLink='upload-doc'>
                    Doc Upload
                </a>
            </li>
            <li class="nav">
                <a routerLink='shortlisted-university'>
                    Shortlisted Universities
                </a>
            </li>


        </ul>
    </div>
</div>