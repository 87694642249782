import { Component, OnInit } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { upcomingEventList } from '../../interfaces/upcomingEventList';
@Component({
  selector: 'app-upcoming-events',
  templateUrl: './upcoming-events.component.html',
  styleUrls: ['./upcoming-events.component.scss'],
})
export class UpcomingEventsComponent implements OnInit {
  upcomingEventList: upcomingEventList[] = [];
  constructor(private eventService: EventsService) {}

  ngOnInit(): void {
    this.getEvents();
  }
  getEvents() {
    this.eventService.getEvent().subscribe((Res:any) => {
      this.upcomingEventList = Res.events;
    });
  }
}
