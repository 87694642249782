import { Component, OnInit,Input, ComponentFactoryResolver } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-popup-video',
  templateUrl: './popup-video.component.html',
  styleUrls: ['./popup-video.component.scss']
})
export class PopupVideoComponent implements OnInit {
  @Input('dataFromParent') public url:any;
  constructor(private modalService: BsModalService,public modalRef: BsModalRef,private sanitizer: DomSanitizer) {
    
  }

  ngOnInit(): void {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

}
