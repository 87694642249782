<div class="main-container">
    <section>

        <div class="container">

            <div class="col-sm-8 col-md-12  text-center" style="margin-bottom:50px">
                <h1>There are a lot of ways to stay in touch with us</h1>

            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="feature feature-5 boxed boxed--lg boxed--border">
                        <i class="icon icon-Pantone icon--lg"></i>
                        <div class="feature__body">
                            <h5>MeetUniversity.Com</h5>
                            <p>
                                B-4, First Floor, B Block, Sector 63, Noida, Uttar Pradesh 201301
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="feature feature-5 boxed boxed--lg boxed--border">
                        <i class="icon icon-Fingerprint icon--lg"></i>
                        <div class="feature__body">
                            <h5>Registered Office</h5>
                            <p>
                                Beyond Horizons Educonnect Pvt. Ltd.
                                1/22 ,Asif Ali Road New Delhi 110002,
                                India
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="unpad">
        <iframe title="vid4"
            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJHdQS_jPiDDkR9p5yPHuJjTM&amp;key=AIzaSyAmtWSjMYoCeDwqIvu-srv30BtkSWpNeq8"></iframe>
    </section>
    <section class="switchable ">
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-md-5">
                    <p class="lead">
                        Email:
                        <a href="mailto:connect@meetuniversity.com">connect@meetuniversity.com</a>
                        <br /> Phone: +91 8047 09 29 70
                    </p>
                    <p class="lead">
                        Give us a call or drop by anytime, we endeavour to answer all enquiries within 24 hours on
                        business days.
                    </p>
                    <p class="lead">
                        We are open from 9am &mdash; 5pm week days.
                    </p>
                </div>
                <div class="col-md-6 col-12">
                    <form class="row mx-0" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                        <div class="col-md-6 col-12">
                            <label>Your Name:</label>
                            <input type="text" name="name" class="validate-required" formControlName="name" />
                            <div *ngIf="submitted && contactFormControl.name.errors" class="invalid-feedback">
                                <div *ngIf="contactFormControl.name.errors.required">Name is required.</div>
                              </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <label>Email Address:</label>
                            <input type="email" name="email" class="validate-required validate-email" formControlName="email" />
                            <div *ngIf="submitted && contactFormControl.email.errors" class="invalid-feedback">
                                <div *ngIf="contactFormControl.email.errors.required">Email is required.</div>
                              </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <label>Your Phone:</label>
                            <input name="phone" required="" type="text" aria-required="true" autocomplete="off"
                            formControlName="phone" />
                            <div *ngIf="submitted && contactFormControl.phone.errors" class="invalid-feedback">
                                <div *ngIf="contactFormControl.phone.errors.required">Phone is required.</div>
                              </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <label>Type:</label>
                            <div class="form-group">
                                <select required="" name="query" aria-required="true" aria-invalid="false"
                                    class="valid" formControlName="query">
                                    <option value="contact">Select</option>
                                    <option value="contact">Contact Us</option>
                                    <option value="jobs">Jobs</option>
                                    <option value="experts">Expert Help</option>
                                </select>
                                <div *ngIf="submitted && contactFormControl.query.errors" class="invalid-feedback">
                                    <div *ngIf="contactFormControl.query.errors.required">Type is required.</div>
                                  </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-12">
                            <label>Message:</label>
                            <textarea rows="4" name="message" class="validate-required"  formControlName="message"></textarea>
                            <div *ngIf="submitted && contactFormControl.message.errors" class="invalid-feedback">
                                <div *ngIf="contactFormControl.message.errors.required">Message is required.</div>
                              </div>
                        </div>
                        <div class="col-md-5 col-lg-4 col-6">
                            <button type="submit" class="btn btn--primary type--uppercase">Send Enquiry</button>
                        </div>
                    </form>
                </div>
            </div>
            <!--end of row-->
        </div>
        <!--end of container-->
    </section>
</div>