import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { UniversitylistService } from '../../services/universitylist.service'
import { UniversityCourse, UniversityDetail } from '../../interfaces/universitylist'
import { MessageService } from 'src/app/core/services/message.service';
@Component({
  selector: 'app-university',
  templateUrl: './university.component.html',
  styleUrls: ['./university.component.scss']
})
export class UniversityComponent implements OnInit {
  data: Object;

  public UniversityCourse: UniversityCourse[] = [];
  public UniversityDetail = {} as UniversityDetail;

  constructor(private messageService: MessageService, private router: Router, private route: ActivatedRoute, private universitylistService: UniversitylistService) { }

  ngOnInit(): void {
    console.log(this.route.snapshot.params.univname);
    this.route.params.subscribe((params: Params) => {
      this.messageService.sendMessage({ country: params.country.toLowerCase() })
    })
    this.getUniversityCourses()
    this.getUniversityInfo()
  }
  getUniversityCourses() {

    this.data = {
      uid: this.route.snapshot.params.univname,
      size: 6
    }
    this.universitylistService
      .getUniversityCourses(this.data)
      .subscribe(async (data: any) => {

        this.UniversityCourse = await data;
        // this.univertyList=data
      });
  }
  getUniversityInfo() {
    this.data = {
      university_name: this.route.snapshot.params.univname,
    }
    this.universitylistService
      .getUniversityExtraData(this.data)
      .subscribe(async (data: any) => {
        this.UniversityDetail
        this.UniversityDetail = data.response
        // this.univertyList=data
      });
  }
  
  ngOnDestroy() {
    localStorage.removeItem('faqCountry');
  }
}
