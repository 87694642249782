import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services/api.service';
import { apiUrl } from '../../../config/config';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  constructor(private ApiService: ApiService) {} 
  CourseDetailUrl = apiUrl.get_course_detail;
  CourseTableUrl = apiUrl.get_course_table;

  getCourseDetailUrl() {
    return this.ApiService.get(
      this.CourseDetailUrl + '?slug=cardiff-metropolitan-university'
    );
  }

  getCourseTable() {
    return this.ApiService.get(
      this.CourseTableUrl + '?uid=cardiff-metropolitan-university'
    );
  }
}
