import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { COUNTRY } from 'src/app/core/constants/country.constants';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
  FormArray,
} from '@angular/forms';
import {GuideService} from '../../services/guide.service'
@Component({
  selector: 'app-guide-pages',
  templateUrl: './guide-pages.component.html',
  styleUrls: ['./guide-pages.component.scss'],
})
export class GuidePagesComponent implements OnInit {
  countryName: string;
  submitted: boolean = false;
  Form: FormGroup;
  public checks: Array<any> = [
    { description: 'UK', value: 'UK' },
    { description: 'USA', value: 'USA' },
    { description: 'Aus', value: 'Australia' },
    { description: 'NZ', value: 'New Zealand' },
    { description: 'Other', value: 'any' },
  ];

  constructor(private guideService:GuideService,private fb: FormBuilder, private route: ActivatedRoute, private router: Router) {
    this.Form = this.fb.group({
      full_name: ['', [Validators.required]],
      email_address: ['', [Validators.required]],
      phone_number: ['', [Validators.required]],
      last_course: ['', [Validators.required]],
      last_score: ['', [Validators.required]],
      course: ['', [Validators.required]],
      checkedLoan: ['', [Validators.required]],
      city: ['', [Validators.required]],
      country: new FormArray([]),
    });
  }
  get f() {
    return this.Form.controls;
  }
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (params.countryid) {
        let checkExistingCountry = COUNTRY.STUDENT_GUIDE_COUNTRY.filter(
          (x) => x == params.countryid
        );

        if (checkExistingCountry.length == 0) {
          this.router.navigate(['/notfound']);
        }
        this.countryName = params.countryid;
        localStorage.setItem('faqCountry', params.countryid);
      }
    });
  }

  onSubmit() {
    try {
      console.log(this.Form.value);
      this.submitted = true;
      if (this.Form.status == 'INVALID') {
        this.submitted = true;
      }
      if (this.Form.status == 'VALID') {
        console.log(this.Form)
        this.guideService.postFormData(this.Form.value).subscribe(
          async (json: any) => {
            console.log(json)
          })
      }
    } catch (e) {
      console.info(e);
    }
  }
  onCheckChange(event: any) {
    const formArray: FormArray = this.Form.get('country') as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    } else {
      /* unselected */
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
  }
}
