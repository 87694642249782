import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services/api.service';
import { apiUrl } from '../../../config/config';

@Injectable({
  providedIn: 'root',
})
export class TimelineService {
  constructor(private ApiService: ApiService) {}
  GetCountryUrl = apiUrl.get_timeline;

  getCountry(country:string) {
    return this.ApiService.get(this.GetCountryUrl+'&type='+country);
  }
  getIntake(json:any) {
    return this.ApiService.get(this.GetCountryUrl+'&country='+json.country+'&type='+json.type);
  }
}
