import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
  FormArray,
} from '@angular/forms';
import { AskExpertService } from '../../services/ask-expert.service';
import { AskExpertOTP, AskExpertVerify } from '../../interfaces/ask-expert';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-ask-expert',
  templateUrl: './ask-expert.component.html',
  styleUrls: ['./ask-expert.component.scss'],
})
export class AskExpertComponent implements OnInit {
  OtpArray = {} as AskExpertOTP;
  OtpVerifyArray = {} as AskExpertVerify;
  askExpert!: FormGroup;
  OTPVerifyForm!: FormGroup;
  country: FormArray;
  submitted: boolean = false;
  applied: boolean = false;
  views: Number = 1;
  verify: any;
  otpSendStatus: string;

  // For example, an array of choices
  public checks: Array<any> = [
    { description: 'UK', value: 'UK' },
    { description: 'USA', value: 'USA' },
    { description: 'Aus', value: 'Australia' },
    { description: 'NZ', value: 'New Zealand' },
    { description: 'Other', value: 'any' },
  ];

  constructor(
    private fb: FormBuilder,
    private askExpertService: AskExpertService,
    private notifyService: NotificationService
  ) {
    this.formModule();
  }

  ngOnInit(): void {}

  async formModule() {
    try {
      this.askExpert = await this.fb.group({
        full_name: ['', [Validators.required]],
        email_address: ['', [Validators.required, Validators.email]],
        phone_number: ['', [Validators.required]],
        last_course: ['', [Validators.required]],
        last_score: ['', [Validators.required]],
        course: ['', [Validators.required]],
        city: ['', [Validators.required]],
        src: [''],
        sub: ['guide'],
        checkedLoan: ['', [Validators.required]],
        country: ['uk'], //new FormArray([], [Validators.required]),
      });

      this.OTPVerifyForm = await this.fb.group({
        code: ['', [Validators.required]],
      });
    } catch (e) {
      console.info(e);
    }
  }

  get p() {
    return this.askExpert.controls;
  }

  get o() {
    return this.OTPVerifyForm.controls;
  }

  onSubmit() {
    try {
      this.submitted = true;
      if (this.askExpert.status == 'INVALID') {
        this.submitted = true;
      }
      if (this.askExpert.status == 'VALID') {
        this.OtpArray.name = this.askExpert.value.full_name;
        this.OtpArray.phone = this.askExpert.value.phone_number;
        this.OtpArray.email = this.askExpert.value.email_address;

        this.sendOtp();
      }
    } catch (e) {
      console.info(e);
    }
  }

  sendOtp() {
    this.askExpertService
      .getOtp(this.OtpArray)
      .subscribe(async (response: any) => {
        console.log(response)
        if (response) {
          this.otpSendStatus = `Hi ${
            this.OtpArray.name
          } ! We have sent you OTP on ${this.OtpArray.phone}`;
          this.notifyService.showSuccess(this.otpSendStatus);
          this.views = 2;
        }
      });
  }

  async verifyOTP() {
    try {
      this.applied = true;
      console.log(this.OTPVerifyForm.value);
      if (this.OTPVerifyForm.status == 'INVALID') {
        this.applied = true;
      }
      if (this.OTPVerifyForm.status == 'VALID') {
        this.OtpVerifyArray.name = this.askExpert.value.full_name;
        this.OtpVerifyArray.phone = this.askExpert.value.phone_number;
        this.OtpVerifyArray.email = this.askExpert.value.email_address;
        this.OtpVerifyArray.code = this.OTPVerifyForm.value.code;
        this.askExpertService
          .getVerifyOtp(this.OtpVerifyArray)
          .subscribe(async (json: any) => {
            this.verify = await json;
            if (this.verify.status == false) {
              this.notifyService.showError(json.message);
              return;
            }
            this.notifyService.showSuccess(json.message);
            this.askExpertService.postFormData(this.askExpert.value).subscribe(
              async (json: any) => {
                if (json) {
                  this.notifyService.showSuccess('Thanks for contacting MeetUniversity! Our Counsellor will connect with you soon!');
                  this.views = 3;
                }
              },
              (error) => {
                this.notifyService.showError(error);
              }
            );
          });
      }
    } catch (e) {
      console.log(e);
    }
  }

  onCheckChange(event: any) {
    const formArray: FormArray = this.askExpert.get('country') as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    } else {
      /* unselected */
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
  }
}
