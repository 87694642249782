import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { VideosComponent } from 'src/app/public/components/videos/videos.component';

@Component({
  selector: 'app-public-testimonial',
  templateUrl: './public-testimonial.component.html',
  styleUrls: ['./public-testimonial.component.scss']
})
export class PublicTestimonialComponent implements OnInit {
  public modalRef?: BsModalRef;
  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {
  }

  openModal(url:any) {
    const config: ModalOptions = {
      backdrop: true,
      class: 'modal-dialog-centered',
      ignoreBackdropClick: false,
      initialState: {
        source_url: url,
      }
    }
    this.modalRef = this.modalService.show(VideosComponent, config);
}

}
