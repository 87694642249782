import { Component, OnInit } from '@angular/core';
import { DisciplinesService } from '../../../public/services/disciplines.service';
import { DisciplineList } from '../../../public/interfaces/discipline-list';
import { ActivatedRoute, Router  } from '@angular/router';

@Component({
  selector: 'app-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.scss']
})
export class PublicHeaderComponent implements OnInit {
  DisciplineList: DisciplineList[] = [];
  constructor(private disciplinesService: DisciplinesService,private router: Router) { }

  ngOnInit(): void {
    this.getDisciplines()
  }

  getDisciplines() {
    this.disciplinesService.getDiscipline().subscribe((Res:any) => {
      this.DisciplineList = Res;
    });
  }

  reachedInnerPage(name:any){
    try{
      let encodedName = btoa(name);
      this.router.navigate(['disciplines/',encodedName]);
    }catch(e){
      console.info(e);
    }
  }

}
