import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { apiUrl } from 'src/config/config';

@Injectable({
  providedIn: 'root'
})
export class CountryPageService {
  getCountryDetailsUrl = apiUrl.businessPage;
  constructor(private ApiService: ApiService) { }

  getCountryDetails(countryId : string){
    return this.ApiService.get(`${this.getCountryDetailsUrl}&slug=study-in-${countryId}`);
  }
}
