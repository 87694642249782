<section class="pt-0 pb-0">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-md-offset-3">
                <div class="row">
                    <div class="col-md-4 line">
                        <h3 class="font-weight-bold pb-2  mb-2 pt-5">30 Minute Meeting</h3>
                        <p class="mb-1">
                            <span class="font-manage"><i class="icon icon-Clock icon--lg"></i></span> <span
                                class="font-weight-bold">30
                                min</span>
                        </p>
                        <form [formGroup]="discussDetails" id="msform">
                            <p class="mb-1">
                                <span class="font-manage"><i class="icon icon-Magnifi-Glass icon--lg"></i></span> <span
                                    class="font-weight-bold">What do you want to discuss about?</span>
                            </p>
                            <div class="row  ml-3">
                                <div class="col-sm-3 " *ngFor="let item of discussArray">
                                    <div class="input-radio">
                                        <span class="input__label"> {{item.name}}</span>
                                        <input type="radio" formControlName="discussBooking" [(ngModel)]="bookingName"
                                            value="{{item.name}}" id="{{item.name}}" required />
                                        <label for="{{item.name}}"></label>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <p *ngIf="showSecondSection">
                            <span class="font-manage"><i class="icon icon-Calendar-4 icon--lg"></i></span><span
                                class="font-weight-bold">
                               {{slotDetails.value.time}} {{slotDetails.value.date | date: 'fullDate'}}</span>
                        </p>
                    </div>
                    <div class="col-md-8">
                        <div class="row pt-5" *ngIf="showFirstSection">
                            <div class="col-md-7">
                                <h4 class="font-weight-bold pb-2  mb-2">Select a Date & Time</h4>
                                <bs-datepicker-inline
                                (bsValueChange)="onValueChangeWeek($event)"  [bsValue]="bsInlineValue"></bs-datepicker-inline>
                                <p class="mt-2">
                                </p>
                            </div>
                            <div class="col-md-5">
                                <div class="d-md-inline-block pb-5 pt-3">
                                    <button (click)="showHideSection('9:00am')" type="button"
                                        class="btn btn--dark w-100 ml-0 mt-2"  >9:00 am</button>
                                    <button (click)="showHideSection('9:30am')" type="button"
                                        class="btn btn--dark w-100 ml-0 mt-2" >9:30 am</button>
                                    <button (click)="showHideSection('10:00am')" type="button"
                                        class="btn btn--dark w-100 ml-0 mt-2" >10:00 am</button>
                                    <button (click)="showHideSection('10:30am')" type="button"
                                        class="btn btn--dark w-100 ml-0 mt-2" >10:30 am</button>
                                    <button (click)="showHideSection('11:00am')" type="button"
                                        class="btn btn--dark w-100 ml-0 mt-2" >11:00 am</button>
                                    <button (click)="showHideSection('11:30am')" type="button"
                                        class="btn btn--dark w-100 ml-0 mt-2" >11:30 am</button>
                                    <button (click)="showHideSection('12:00pm')" type="button"
                                        class="btn btn--dark w-100 ml-0 mt-2" >12:00 pm</button>
                                    <button (click)="showHideSection('12:30pm')" type="button"
                                        class="btn btn--dark w-100 ml-0 mt-2" >12:30 pm</button>
                                    <button (click)="showHideSection('1:00pm')" type="button"
                                        class="btn btn--dark w-100 ml-0 mt-2" >1:00 pm</button>
                                    <button (click)="showHideSection('1:30pm')" type="button"
                                        class="btn btn--dark w-100 ml-0 mt-2" >1:30 pm</button>
                                    <button (click)="showHideSection('2:00pm')" type="button"
                                        class="btn btn--dark w-100 ml-0 mt-2" >2:00 pm</button>
                                    <button (click)="showHideSection('2:30pm')" type="button"
                                        class="btn btn--dark w-100 ml-0 mt-2" >2:30 pm</button>
                                    <button (click)="showHideSection('3:00pm')" type="button"
                                        class="btn btn--dark w-100 ml-0 mt-2" >3:00 pm</button>
                                    <button (click)="showHideSection('3:30pm')" type="button"
                                        class="btn btn--dark w-100 ml-0 mt-2" >3:30 pm</button>
                                    <button (click)="showHideSection('4:00pm')" type="button"
                                        class="btn btn--dark w-100 ml-0 mt-2">4:00 pm</button>

                                    <button (click)="showHideSection('4:30pm')" type="button"
                                        class="btn btn--dark w-100 ml-0 mt-2">4:30 pm</button>
                                </div>
                            </div>
                        </div>
                        <section class="row  ml-5" *ngIf="showSecondSection">
                            <form [formGroup]="personalDetails" (ngSubmit)="onSubmit()" id="msform">
                                <h3 class="font-weight-bold">Enter Details</h3>

                                <input type="text" formControlName="name" placeholder="Name" required />
                                <div *ngIf="personal_step && personalDetails.controls.name.errors">
                                    <div *ngIf="personal.name.errors?.required">Name is required</div>
                                </div>
                                <input type="text" formControlName="email" placeholder="Email" required />
                                <div *ngIf="personal_step && personalDetails.controls.email.errors">
                                    <div *ngIf="personal.email.errors?.required">Email is required</div>
                                </div>
                                <input type="text" formControlName="phone" placeholder="Phone No." required />
                                <div *ngIf="personal_step && personalDetails.controls.phone.errors">
                                    <div *ngIf="personal.phone.errors?.required">Phone No. is required</div>
                                </div>
                                <input type="submit" name="submit" class="w-100 submit action-button" value="Submit" />
                            </form>
                        </section>


                    </div>

                </div>


            </div>
        </div>
    </div>

</section>